import React from 'react'
import '../index.less'

interface ChunkProps {
  children: JSX.Element
  angleWidth?: string | number
  angleHeight?: string | number
}

function Chunk(props: ChunkProps) {

  return (
    <div className='tx-chunk'>
      <div className='top-left-angle' style={{width: props.angleWidth || 15, height: props.angleHeight || 15}}></div>
      <div className='top-right-angle' style={{width: props.angleWidth || 15, height: props.angleHeight || 15}}></div>
      <div className='bottom-left-angle' style={{width: props.angleWidth || 15, height: props.angleHeight || 15}}></div>
      <div className='bottom-right-angle' style={{width: props.angleWidth || 15, height: props.angleHeight || 15}}></div>
      {
        props.children
      }
    </div>
  )
}

export default Chunk
