import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import classnames from 'classnames'
import Flv from 'flv.js'
import {baseProps} from './interface'



/**
 * 对原生video标签的封装，暴露play pause clean三个方法和video标签对象到外部
 * @param props {baseProps}
 * @param ref
 * @constructor
 */
const Video = (props: baseProps, ref: any) => {
  const videoElement: any = useRef(null)
  const flvPlayer: any = useRef(null)

  const [url, setUrl] = useState('')
  const [currentTime, setCurrentTime] = useState(0)

  useEffect(() => {
    if (props.url) {
      initPlayer(props.url)
    } else if (flvPlayer.current) {
      destroy()
    }
  }, [props.url])

  useEffect(() => {
    if (props.onPlayTimeChange) {
      props.onPlayTimeChange(currentTime)
    }
  }, [currentTime])

  useEffect(() => {
    voiceChange(props.voice)
  }, [props.voice])

  useImperativeHandle(ref, () => {
    return {
      play: play,
      pause: pause,
      clean: cleanChunnk,
      dom: videoElement.current
    }
  })

  /**
   * 初始化播放器
   * 根据props.type判断采用flv.js还是用原生video标签播放
   * @param selfurl
   */
  const initPlayer = async (selfurl: string) => {
    destroyEvent()
    videoElement.current.oncanplay = play
    videoElement.current.onended = ended
    videoElement.current.addEventListener('timeupdate', events)
    if (props.type === 'flv') {
      flvPlayer.current = await createFlvPlayer()


    } else {
      let _urlarr = url ? url.split('t=') : ''
      let _selfurlarr = selfurl ? selfurl.split('t=') : ''
      if (_urlarr && _urlarr[0] === _selfurlarr[0]) {
        videoElement.current.currentTime = 0
        videoElement.current.play()
      } else {
        setUrl(selfurl || '')
      }
    }

  }

  const play = () => {
    if (props.onStart) {
      props.onStart()
    }
    palyEvent()
  }

  const ended = () => {
    if (props.onEnded) {
      props.onEnded()
    }
  }

  const pause = () => {
    if (videoElement.current) {
      videoElement.current.pause()
    }
  }

  const destroy = () => {
    // if (props.onDestroy) {
    //     props.onDestroy()
    // }
    destroyEvent()
  }

  const cleanChunnk = () => {
    if (flvPlayer.current) {
      let end = flvPlayer.current.buffered.end(0)
      flvPlayer.current.currentTime = end - 0.2
    }
  }

  const voiceChange = (data: any) => {
    if (flvPlayer.current) {
      flvPlayer.current.volume = Number(data) / 100
    }
  }

  const palyEvent = () => {
    if (videoElement.current) {
      videoElement.current.play()
    }
  }

  const destroyEvent = () => {
    if (flvPlayer.current) {
      flvPlayer.current.pause()
      flvPlayer.current.unload()
      flvPlayer.current.detachMediaElement()
      flvPlayer.current.destroy()
      flvPlayer.current = null
      videoElement.current.oncanplay = null
      videoElement.current.removeEventListener('timeupdate', events)
    }
  }

  /**
   * 创建flvPlayer对象
   *
   * 更新： 由于这个方法在唯一被调用的地方调用前，判断过props.type===‘flv'之后才调用
   * 所以这里移除掉对type的判断，简化代码
   */
  const createFlvPlayer = async (): Promise<Flv.Player | null> => {
    const {url, hasAudio, hasVideo, isLive, isSplit} = props
    if (url) {
      const selfUrl = isSplit ? url.split('?')[0] : url
      const flvPlayer: Flv.Player =  Flv.createPlayer({
        type: 'flv',
        url: selfUrl,
        hasAudio: hasAudio || false,
        hasVideo: hasVideo !== false,
        isLive: isLive !== false,
        withCredentials: false
      }, {
        /** 默认缓存180kb 开始播放视频 */
        stashInitialSize: 180,
      })
      if (props.onInstanceCreated) {
        props.onInstanceCreated(flvPlayer)
      }
    //   if(props?.isDelay){
    // //增加延迟
    // let sleep = (delaytime = 3000) => {
    //   return new Promise((resolve) => setTimeout(resolve, delaytime));
    // };
    // await sleep();
    //   }
      flvPlayer.attachMediaElement(videoElement.current)
      flvPlayer.load()

      return flvPlayer
    }
    return null
  }

  // 视频播放监听事件
  const events = () => {
    if (videoElement.current) {
      // 获取整数
      setCurrentTime(~~videoElement.current.currentTime)
    }
  }

  return (
    <video
      ref={videoElement}
      className={(classnames('flv-video', props.className))}
      src={url}
    >
    </video>
  )
}

export default forwardRef(Video)
