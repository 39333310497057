import React, { useEffect, useState } from 'react'
import Chunk from '../components/chunk'
import Title from '../components/title';
import { useIntl } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper';
import useSetState from 'util/useSetState';
import 'swiper/swiper.less'
import 'swiper/components/controller/controller.less'
import { webAPIResponse } from 'server/web/index.globals';
import { useWindowSize } from 'react-use';

interface RealTimeDeliveryProps {
  data: webAPIResponse.findSiteTaskRealBy[]
}

interface MyState {
  loop: boolean
}

SwiperCore.use([Autoplay])


function RealTimeDelivery(props: RealTimeDeliveryProps) {

  const { formatMessage: f } = useIntl()

  const { width, height } = useWindowSize()
  const [slidesPerView, setSlidesPerView] = useState<number>(8)
  const [state, setState] = useSetState<MyState>({
    loop: false,
  })

  useEffect(() => {
    handle(props.data)
  }, [props.data])


  useEffect(() => {
    console.log(width);
    if (width <= 1366) {
      setSlidesPerView(5)
    } else if (width <= 1600 && width > 1366) {
      setSlidesPerView(6)
    } else if (width > 1600) {
      setSlidesPerView(8)
    }
  }, [width])

  const handle = (data: Array<any>) => {
    if (data.length > 5) {
      setState({
        loop: true
      })
    } else {
      setState({
        loop: false
      })
    }
  }

  return (
    <div className='tx-large-vehicle-total tx-large-vehicle-supply'>
      <Chunk>
        <div className='large-vehicle-total supply'>
          <Title value={ f({ id: 'tx000143', description: '实时供货数据' }) } />
          <div className='supply-table-header supply-title'>
            <div className='address'>
              { f({ id: 'tx000189', description: '工地' }) }
            </div>
            <div className='amount'>
              { f({ id: 'tx000190', description: '运输量' }) }
            </div>
            <div className='trains'>
              { f({ id: 'tx000155', description: '运输车次' }) }
            </div>
          </div>
          <div className='supply-swiper'>
            <Swiper
              speed={ 1000 }
              autoplay={ {
                delay: 1000,
                waitForTransition: false
              } }
              slidesPerView={ slidesPerView }
              loop={ state.loop }
              loopAdditionalSlides={ 0 }
              direction='vertical'
              style={ {
                width: '100%'
              } }
            >
              {
                props.data.map(item => {
                  return <SwiperSlide key={ item.siteId }>
                    <div className='supply-table-header supply-item'>
                      <div className='ellipsis address'>{ item.name || '-' }</div>
                      <div className='amount'>{ item.completeVolume }</div>
                      <div className='trains'>{ item.vehicleCount }</div>
                    </div>
                  </SwiperSlide>
                })
              }
            </Swiper>
          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default RealTimeDelivery
