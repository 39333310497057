/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-06 10:55:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-08 09:25:34
 */
import { observable, action } from 'mobx';

interface EventsType {
  /**
   * @prop {string} key table事件
   */
  [key: string]: any
}

class TableStore {
  @observable events: EventsType = {}

  @action
  updateTableEvents = <T>(key: string, fn: T) => {
    this.events[key] = fn
  }

  /**
   * @description 总的事件触发器，比较麻烦，一般不用
   * @param key 事件名称，规则如下
   * @return {Function} 事件函数
   * @description 请求表单数据：`${tableName}_QUERY`
   */
  getTableEvents = (key: string): Function => {
    if (this.events[key] && typeof this.events[key] === 'function') {
      return this.events[key]
    }
    return () => {
      throw new Error('无效事件，请检查该事件是否存在')
    }
  }

  /**
   * @description 请求事件触发器
   * @param tableName 全局唯一
   * @param payload 用于覆盖表单的参数，非必传
   * @description 保留原本表单参数重新请求，如果传了payload，则使用payload覆盖表单参数重新请求
   */
  queryTable = (tableName: string, payload?: any) => {
    const key = `${tableName}_QUERY`;
    if (this.events[key] && typeof this.events[key] === 'function') {
      this.events[key]('query', payload)
    } else {
      throw new Error('无效事件，请检查该事件是否存在')
    }
  }

  /**
   * @description 刷新事件触发器
   * @param tableName 全局唯一
   * @description 重置表单参数重新请求
   */
  refreshTable = (tableName: string) => {
    const key = `${tableName}_QUERY`
    if (this.events[key] && typeof this.events[key] === 'function') {
      this.events[key]('refresh')
    } else {
      throw new Error('无效事件，请检查该事件是否存在')
    }
  }
}

const tableStore: TableStore = new TableStore()

export { TableStore }
export default tableStore