import React, { memo, useState } from 'react'
import { Checkbox, Space, Button, Col } from 'antd'
import { useIntl } from 'react-intl'
import { ColumnsType } from '../interface'
import { Action } from '../interface'

const { Group } = Checkbox

interface ColumnSettingProps {
  columns?: ColumnsType
  columnSetting: any[]
  dispatch: React.Dispatch<Action>
  setColumnSettingVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const ColumnSetting = (props: ColumnSettingProps) => {
  const { columns, columnSetting, dispatch, setColumnSettingVisible } = props
  const { formatMessage: f } = useIntl()
  const [columnSettingValue, setColumnSettingValue] = useState(columnSetting)
  return (
    <>
      <Group
        className="tx-column-setting-content"
        onChange={setColumnSettingValue}
        value={columnSettingValue}
      >
        {columns
          ? columns?.map((item: any) => {
              const { title, dataIndex, intlTitle } = item
              return (
                <Col key={dataIndex}>
                  <Checkbox value={dataIndex}>
                    {intlTitle
                      ? Array.isArray(intlTitle)
                        ? intlTitle.map(item => f({ id: item })).join('') || dataIndex
                        : f({ id: intlTitle })
                      : title || dataIndex || undefined}
                  </Checkbox>
                </Col>
              )
            })
          : null}
      </Group>
      <div className="tx-column-setting-footer">
        <Space>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => {
              setColumnSettingValue(
                columns
                  ?.filter(
                    ({ dataIndex }: any) => !columnSettingValue.find(item => dataIndex === item),
                  )
                  .map((item: any) => item.dataIndex) || [],
              )
            }}
          >
            反选
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              dispatch({ type: 'columnSetting', data: { columnSetting: columnSettingValue } })
              setColumnSettingVisible(false)
            }}
          >
            确定
          </Button>
        </Space>
      </div>
    </>
  )
}

export default memo(ColumnSetting)
