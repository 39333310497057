/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-08-05 09:41:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 16:37:17
 */
import Flv from 'flv.js'

/** 媒体类型 */
type mediaType = 'mp4' | 'flv'

export const videoTypes = {
  /** 直播 */
  broadcast: 'broadcast',
  /** 回放 */
  playback: 'playback'
} as const

export enum VideoToolType {
  /** 播放 */
  'play' = 'play',
  /** 暂停 */
  'pause' = 'pause',
  /** 停止 */
  'stop' = 'stop',
  /** 销毁 */
  'destroy' = 'destroy',
  /* 无操作 */
  'none' = 'none'
}

type VideoType = typeof videoTypes

export interface FLVProps {
  /** 是否包含音频，默认false */
  hasAudio?: boolean
  /** 是否包含视频，默认true */
  hasVideo?: boolean
}

export interface VehicleProps {
  /** SIM 卡号 */
  sim?: string,
  /** 通道号 */
  passageway?: number | string,
  /** 车牌号 */
  vehicleNo?: string
}

export interface baseProps extends FLVProps {
  /** 视频资源地址 */
  url?: string
  className?: string
  /** 视频类型 默认flv */
  type?: 'mp4' | 'flv' | string
  /** 是否直播资源, 默认true */
  isLive?: boolean
  /** 音量大小 */
  voice?: number
  /** 播放速率 */
  rate?: number
  /** 是否split直播地址，默认true */
  isSplit?: boolean
  /** video操作类型 */
  videoTool?: string
  /** 开始播放回调事件 */
  onStart?: () => void
  /** 视频播放完成事件 */
  onEnded?: () => void
  /** 暂停回调事件 */
  onPause?: () => void
  /** 销毁回调事件 */
  onDestroy?: () => void
  /** 视频加载中 */
  onWaiting?: () => void
  /** 播放时间改变事件 */
  onPlayTimeChange?: (currentTime: number) => void
  /**
   * 视频实例化回调，只在当type是flv的时候，flv.load()后调用。
   * @param flvPlayer flvjs初始化并load之后的实例
   */
  onInstanceCreated?: (flvPlayer: Flv.Player) => void

  videoAttribute?: object

  isDelay?:boolean
}

export interface videoProps {
  /**
   * SIM 卡号
   */
  sim?: string,
  /**
   * 通道号
   */
  passageway?: number | string,
  /**
   * 通道号国际化
   */
  passagewayIntl?: string,
  /**
   * 视频路径
   */
  url?: string
  /**
   * 车牌号
   */
  vehicleNo?: string
  /**
   * 播放类型
   * broadcast 直播
   * playback 回放
   */
  type?: VideoType[keyof VideoType]
  /**
   * 媒体类型
   */
  mediaType?: mediaType
  /**
   * 视频回放时间
   */
  playbackTime?: number
  /**
   * 播放回调事件
   */
  onStart?: Function
  /**
   * 停止回调事件
   */
  onStop?: Function
  /**
   * 暂停回调事件
   */
  onPause?: Function
  /**
   * 销毁回调事件
   */
  onDestroy?: Function
  /**
   * 音量改变事件
   */
  voiceChange?: Function
  /**
   *
   */
  className?: string
  /**
   * 是否有音频，默认没有音频
   */
  hasAudio?: boolean
  /**
   * 是否有视频，默认有
   */
  hasVideo?: boolean
  /** 屏幕比 3:4 16:9*/
  videoScale?: number
  /** 是否显示操作栏 */
  showTool?: boolean
  /** 是否截取url */
  isSplit?: boolean
  /** 只播放音频 */
  onlyPlayAudio?:boolean 
  
  /**
   * 选择回放时间回调事件
   */
  playbackTimeChange?: (value: number) => void
  /**
   * 快进事件
   */
  onFastForward?: (value: number) => void
  /**
   * 快退事件
   */
  onGoBack?: (value: number) => void
  /**
   * 超时提示
   */
  timeoutMessage?: string
  /** 播放超时是否显示提示，默认true */
  isTimeoutTip?: boolean

  /** 播发按钮 */
  onPlay?: (obj?: {
    passageway?: string | number
    vehicleNo?: string
    simNo?: string
  }) => void
  /** 销毁按钮 */
  destroyClick?: (url?: string) => void
  /** 播放完成事件 */
  onEnded?: () => void
  /** 是否显示播放按钮 */
  showPlay?: boolean
  /** 是否显示暂停 */
  showStop?: boolean
  /** 是否显示拍照 */
  showCapture?: boolean

    /** 是否显示音量控制 */
    showVoice?: boolean
  videoAttribute?: object,
  // 第三方视频的摄像头名字，当有传这个prop的时候，不根据通道显示视频标题
  cameraName?: string
  state?: any
  setVideoList?: any
  // 延迟播放
  isDelay?:boolean
  //视频索引==用于音量控制
  keyNo?:number
  //当前控制的视频索引==用于音量控制
  activeIndex?:number
}

export interface VideoState {
  /** 遮罩控制状态 true显示 false隐藏 */
  maskStatus: boolean
  /** 加载动画状态控制 */
  loading: boolean
  /** 视频类型 */
  videoType: VideoType[keyof VideoType]
  /** 音量大小 */
  voice: number
  /** 当前进度 */
  currentProgress: number
  /** 当前视频播放时间 */
  playTime: string
  /** 当前视频的实际播放时间  */
  videoCurrentTime: number
  /** 播放速率 */
  speed: 1 | 2 | 4 | 8 | 16
  /** 播发速率类型 true 快进 false快退*/
  speedStatus: boolean
  /** 播放状态； 0: 停止播放/未播放  1: 播放 2:暂停*/
  playStatus: number
  timer: any
}

interface toolTitleIntl {
  channels: string
  allPlay: string
  allStop: string
  fullscreen: string
  exitFullscreen: string
  resetAll: string
}

export enum videoType {
  txVideo,
  thirdPartyVideo
}

export interface videoItem {
  passageway: number
  url?: string
  sim: string
  vehicleNo: string
  sessionId: string
  /**
   * 视频类型  0 原来的天巡视频  1 后来接入的第三方视频
   * 区别在于后来接入的第三方视频只有个播放地址，没有通道车牌号之类的信息也不能被控制
   */
  type?: videoType
  cameraId?: string
  cameraName?: string
}

export interface playObj {
  passageway: number
  vehicleNo: string
  simNo: string
  type?: number
}

export interface BroadcastProps {
  /** 媒体类型 */
  mediaType?: mediaType
  videoList: Array<videoItem>
  /** 是否显示直播上方操作栏 */
  showHeaderTool?: boolean
  /** 是否显示视频操作栏 */
  showVideoTool?: boolean
  /** 显示几路视频 */
  passageway?: number
  passagewayIntl?: string
  className?: string
  /** 自动关闭视频时间 */
  autoCloseTime?: number
  /** 心跳
   * @param sessionIds
   */
  onBateTime?: (sessionIds: string[]) => void
  /** 停止所有视频
   * @param videoList
   */
  stopAllVideo?: (videoList: Array<videoItem | null>) => void
  /** 播放所有视频 */
  playAllVideo?: (videoList: Array<videoItem | null>) => void
  /** 单个视频播放点击事件，
   * @param passageway 通道号
   * @param simNo sim卡号
   */
  playClick?: (obj: playObj) => void
  /** 单个视频销毁点击事件
   * @param passwageway 通道号
   * @param sessionId
   */
  destroyClick?: (passwageway: string | number, sessionId: string, simNo: string) => void
  /** 通道改变事件
   * @param value 通道数量
   */
  onPassagewayChange?: (value: number) => void
  /**
   * videoList改变事件
   * @param videoList
   */
  videoListChange?: (videoList: Array<videoItem | null>) => void
  /** 播放完成事件 */
  onEnded?: (passageway: number) => void
  /** 通过随机数来关闭所有的视频 */
  closeVideoNumber?: number
  hasVideo?: boolean
  hasAudio?: boolean
  isTimeoutTip?: boolean
  toolTitleIntls?: toolTitleIntl
}
