import React, { useState, useEffect } from 'react'
import { Checkbox, Button, Input, message } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useIntl } from 'react-intl'
import Modal from './commonText'
import { webAPIResponse, webAPIRequest } from 'server/web/index.globals'
import { sendText, getCommandStatus } from 'server/web/sendCommand'
import { addIssueContent, getIssueContent } from 'server/web/dispatch'
import isOrderAgain from 'util/isOrderAgain'
import useBasicCodes from 'util/useBasicCodes'
import { basicCodesToOption } from 'util/comm'

interface SendTextType {
  record?: webAPIResponse.AlarmPage
  result?: webAPIResponse.AlarmInfo
}

const { TextArea } = Input

const SendText = (props: SendTextType) => {
  const { record, result } = props
  const [{ data: basicCodes }] = useBasicCodes(['Description'])
  const [visible, setVisible] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState('')
  const [checkedOption, setCheckedOption] = useState<CheckboxValueType[]>()
  const [sendLoading, setSendLoading] = useState(false)
  const [statusName, setStatusName] = useState('')
  const [commonTextLoading, setCommonTextLoading] = useState(false)
  const [addCommonLoading, setAddCommonLoading] = useState(false)
  const [commonText, setCommonText] = useState<webAPIResponse.IssueContent[]>([])
  const [clear, setClear] = useState<Function>()
  /** 当前组件是否活跃 */
  const [isAlive, setIsAlive] = useState({ value: true })
  const { formatMessage: f } = useIntl()

  useEffect(() => () => clear && clear(), [clear])

  useEffect(
    () => () =>
      setIsAlive(data => {
        data.value = false
        return data
      }),
    [],
  )

  const handleOk = (value: string) => {
    setTextAreaValue(value)
    setVisible(false)
  }

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setTextAreaValue(value)
  }

  const onCheckboxChange = (checkedValue: CheckboxValueType[]) => {
    setCheckedOption(checkedValue)
  }

  const getStatus = (commandId: string) => {
    /** 是否清空定时器(命令执行成功或者页面关闭) */
    let flag = false
    /** 请求次数 */
    let number = 0
    /** 轮询规则，次数：时间 */
    const rules: { [key: number]: number } = {
      0: 1000,
      5: 2000,
      10: 3000,
    }
    let timer: NodeJS.Timeout | undefined = undefined
    let cTimer: NodeJS.Timeout | undefined = undefined

    const clearTimer = () => {
      setStatusName('')
      setSendLoading(false)
      flag = true
      timer && clearInterval(timer)
      cTimer && clearTimeout(cTimer)
      setClear(undefined)
    }

    const query = async () => {
      number++
      if (typeof rules[number] === 'number') {
        timer && clearInterval(timer)
        timer = setInterval(query, rules[number])
      }
      const res = await getCommandStatus({ commandId })
      if (res) {
        // 如果定时器已清空，则return，什么也不做
        if (flag) return
        const { status, statusName } = res.data
        setStatusName(statusName)
        if (!isOrderAgain(status)) {
          clearTimer()
          setStatusName(statusName)
        }
      }
    }

    query()
    timer = setInterval(query, 1000)
    cTimer = setTimeout(() => {
      clearTimer()
      setStatusName(f({ id: 'tx08_1', description: '请求超时' }))
    }, 30000)

    return clearTimer
  }

  /**
   * 发送
   */
  const send = async () => {
    if (!checkedOption || checkedOption.length === 0) {
      message.warning(
        f({ id: 'tx000211', description: '文本信息不能为空' }, { value: f({ id: 'tx020017' }) }),
      )
      return
    }
    if (!textAreaValue) {
      message.warning(
        f({ id: 'tx000211', description: '下发内容不能为空' }, { value: f({ id: 'tx020016' }) }),
      )
      return
    }
    const payload: webAPIRequest.SendText = {
      alarmIds: result?.id || record?.id,
      vehicleId: result?.vehicleId || record?.vehicleId,
      textContent: textAreaValue,
      displayOptions: checkedOption,
    }
    setSendLoading(true)
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    const res = await sendText(payload)
    if (res && isAlive.value) {
      const commandId = res.data
      const cl = getStatus(commandId)
      setClear(() => cl)
    } else {
      setSendLoading(false)
      setStatusName('')
    }
  }

  /**
   * 加为常用
   */
  const addCommon = async () => {
    if (!textAreaValue) {
      message.warning(
        f({ id: 'tx000211', description: '下发内容不能为空' }, { value: f({ id: 'tx020016' }) }),
      )
      return
    }
    setAddCommonLoading(true)
    try {
      const res = await addIssueContent({ textContent: textAreaValue })
      if (res) {
        if (res.code === 0) {
          message.success(f({ id: 'tx000024', description: '操作成功' }))
          queryIssueContent()
        }
      }
    } finally {
      setAddCommonLoading(false)
    }
  }

  const queryIssueContent = async () => {
    setCommonTextLoading(true)
    try {
      const res = await getIssueContent()
      if (res) {
        setCommonText(res.data)
      }
    } finally {
      setCommonTextLoading(false)
    }
  }

  return (
    <>
      <div className="title">{f({ id: 'tx020017', description: '文本信息' })}</div>
      <Checkbox.Group
        className="checkbox"
        options={basicCodes?.Description?.map(basicCodesToOption)}
        onChange={onCheckboxChange}
      />
      <div className="title">
        <span>{f({ id: 'tx020016', description: '下发内容' })}</span>
        <Button size="small" type="primary" className="commonText" onClick={() => setVisible(true)}>
          {f({ id: 'tx020018', description: '常用文本' })}
        </Button>
      </div>
      <TextArea className="textArea" rows={4} value={textAreaValue} onChange={onTextAreaChange} />

      <Button size="small" type="primary" loading={sendLoading} onClick={send}>
        {f({ id: 'tx000012', description: '发送' })}
      </Button>
      {statusName && <span style={{ marginLeft: '0.12rem' }}>{statusName}</span>}
      <Button
        size="small"
        type="primary"
        ghost
        style={{ marginLeft: '0.12rem' }}
        onClick={addCommon}
        loading={addCommonLoading}
      >
        {f({ id: 'tx020019', description: '加为常用' })}
      </Button>

      <Modal
        title={f({ id: 'tx020018', description: '常用文本' })}
        record={record}
        result={result}
        visible={visible}
        loading={commonTextLoading}
        dataSource={commonText}
        query={queryIssueContent}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

export default SendText
