/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-04 10:40:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-05 17:22:51
 */
import { observable, action } from 'mobx';
import { setLocal } from 'util/comm'

export const localKey = {
  userInfo: 'tx_userInfo',
  token: 'tx_token'
}

/**
 * userInfo的type，目前未定，后期完善
 */
export interface UserInfoType {
  readonly [key: string]: any
  token?: string
  /** 本企业id */
  orgId?: string
  /** 本用户id */
  id?: string
}

class UserStore {
  @observable userInfo?: UserInfoType = undefined
  /** 是否为商砼平台 */
  @observable isConcrete: boolean = false

  @action
  updateUserInfo = (value: UserInfoType) => {
    this.userInfo = value
    this.isConcrete = value.platformId === '00-01-01' ? false : true
    setLocal(localKey.userInfo, value)
    setLocal(localKey.token, value.token)
  }

  // @action
  // updateUserStore = <T>(key: string, value: T) => {
  //   (this as any)[key] = value
  // }

}

const userStore: UserStore = new UserStore()

export { UserStore }
export default userStore