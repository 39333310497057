import React from 'react'
import { Tabs } from 'antd'
import { TabsProps as AntdTabsProps } from 'antd/es/tabs'
import classnames from 'classnames'
import './index.less'

interface TabsProps extends AntdTabsProps {

}

const tabBarStyle: React.CSSProperties = {
  height: '.4rem',
  margin: 0
}

const { TabPane } = Tabs

function Index(props: TabsProps) {
  const { className, ...extra } = props

  return (
    <Tabs
      className={ classnames('tx-tabs', 'disable-select', className) }
      tabBarGutter={ 0 }
      tabBarStyle={ tabBarStyle }
      animated={ true }
      { ...extra }
    >

    </Tabs>
  )
}

export {
  TabPane
}

export default Index
