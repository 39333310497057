/*
 * @Description: 报警证据相关
 * @Author: shenkaiyao
 * @Date: 2020-08-25 14:13:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 15:47:08
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse, PageProps } from './index.globals'

const MODULE = '/accessory'

interface CommandId {
  commandId: string
}

/** 获取报警证据查询页面列表数据 */
export const getAccessoryPage = (payload: webAPIRequest.AccessoryPage) => axios.post<TableResponseType<webAPIResponse.AccessoryPage>>(`${api}${MODULE}/findProofPageBy`, payload)

/** 实时位置-媒体文件 */
export const getMediaPage = (payload: PageProps) => axios.post<TableResponseType<webAPIResponse.mediaPage>>(`${api}${MODULE}/findMediaPageBy`, payload)

/** 报警处理-下发拍照指令-获取拍照照片 */
export const getImgByCommandId = (payload: CommandId) => axios.post<string>(`${api}${MODULE}/getMediaitemByCommandId`, payload)

/** 查询中心-报警证据查询-导出 */
export const exportProofAllBy = (payload: any) => axios.post<boolean>(`${api}${MODULE}/exportProofAllBy`, payload)