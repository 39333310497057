/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2021-01-06 11:24:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-06 16:10:58
 */
import { createContext } from 'react'
import { Map } from 'ol'

export interface MapContextProps {
  map: Map | null
  type: string
}

const MapContext = createContext<MapContextProps | null>(null)

export default MapContext