/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2021-01-22 17:45:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 17:13:08
 */
import { observable, action } from 'mobx';
import { getCompany } from 'server/web/companypra';
import { webAPIResponse } from 'server/web/index.globals';
import { querySetPlatform } from 'server/web/platform';
import { getLocalJson } from 'util/comm'
import positionStory from './monitor/position';
import monitorTreeStore from './monitor/tree';

interface ParamsProps extends Partial<webAPIResponse.platform>, Partial<Pick<webAPIResponse.AddPlatform, 'refreshInterval'>> {

}

class PlatformParams {
  @observable params: ParamsProps = {}

  @action
  queryParams = async () => {
    const userInfo: any = getLocalJson('tx_userInfo')
    // 获取企业参数
    if (userInfo) {
      const result = await getCompany({ companyId: userInfo.orgId })
      if (result && result.data) {
        this.updateParams(result.data)
      }
    }
    // 获取平台参数
    const result = await querySetPlatform()
    if (result && result.data) {
      this.updateParams({
        refreshInterval: result.data.refreshInterval
      })
      positionStory.updateRealTimePollTime(Number(result.data.refreshInterval || 0) || 20)
      monitorTreeStore.updatePollTime(Number(result.data.refreshInterval || 0) || 20)
    }
  }

  @action
  updateParams(params: ParamsProps) {
    this.params = { ...this.params, ...params }
  }
}

const platformParamsStore: PlatformParams = new PlatformParams()

export { PlatformParams }
export default platformParamsStore