/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 16:46:55
 */
import React, { useEffect, useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { getMonitorTrajectoryAlarmPage } from 'server/web/alarm'
import { positionStory, tableStore, trajectoryStory } from 'store'
import { observer, useObserver } from 'mobx-react';
import { webAPIResponse } from 'server/web/index.globals'
import { PathToolType } from 'components/ol/pathSimplifier'
import { lnglatAmap, lnglatBmap } from 'util/lnglat'

interface PoliceDataTableProps { }

function PoliceDataTable(_props: PoliceDataTableProps) {
  const tableName = 'trajectotyPoliceDataTable'
  const [lngValue, setLngValue] = useState<any>()
  const [loadingState, setLoadingState] = useState<boolean>(false)

  const [columns] = useState<ColumnsType<webAPIResponse.monitorTrajectoryAlarmPage>>([
    {
      intlTitle: 'tx020002',
      dataIndex: 'alarmTypeName',
      description: '报警类型',
      width: 120
    },
    {
      intlTitle: 'tx020001',
      dataIndex: 'alarmSourceName',
      description: '报警来源',
      width: 120
    },
    {
      intlTitle: 'tx020003',
      dataIndex: 'alarmTime',
      description: '报警时间',
      width: 120
    },
    // {
    //   intlTitle: 'tx020004',
    //   dataIndex: 'location',
    //   description: '报警地点',
    //   width: 160,
    //   render: (_value, record) => {
    //     return <Lnglats lnglat={[Number(record?.longitude), Number(record?.latitude)]} />
    //   }
    // },
    {
      dataIndex: 'lnglatLocation',
      intlTitle: 'tx020004',
      description: '报警地点',
      width: 180,
      ellipsis: true
    },
    {
      intlTitle: 'tx010007',
      dataIndex: 'longitude',
      description: '经度',
      width: 120
    },
    {
      intlTitle: 'tx010008',
      dataIndex: 'latitude',
      description: '纬度',
      width: 120
    },
    // {
    //   intlTitle: 'tx030002',
    //   dataIndex: 'processedName',
    //   description: '处理状态'
    // }
  ])

  useEffect(() => {
    let isCancelled = false;
    tableStore.queryTable(tableName)
    return () => {
      isCancelled = true;
    }
  }, [positionStory.mapType])

  return useObserver(() =>
    <Table
      size="small"
      tableName={tableName}
      columns={columns}
      buttonGroup={false}
      initLoading={false}
      loading={loadingState}
      axios={{
        query: getMonitorTrajectoryAlarmPage,
        queryParam:{...trajectoryStory.policeParams} ,
        handleQueryRes: (data) => {
          trajectoryStory.updateMarker(data.records)
          return data
        },
        queryCallback: async (data: any) => {
          const { records } = data
          if (records && records.length > 0) {
            setLoadingState(true)
            const lngRecords = await Promise.all(records.map(async (item: any) => {
              return {
                ...item,
                lnglatLocation: positionStory.mapType == 'amap' ?
                  await lnglatAmap(item?.longitude, item?.latitude)
                  : await lnglatBmap(item?.longitude, item?.latitude)
              }
            }))
            setLngValue(lngRecords)
          }else{
            setLngValue([])
          }
          setLoadingState(false)
        }
      }}
      dataSource={lngValue}
      onChange={pagination => {
        // 在播放过程中，翻页关闭弹窗
        if (pagination && pagination.current &&
          (trajectoryStory.toolCode === PathToolType.start
            || trajectoryStory.toolCode === PathToolType.recovery)) {
          trajectoryStory.updateInfoWindowVisible(false)
        }
      }}
      onRow={(record: any) => {
        return {
          onClick: () => {
            trajectoryStory.updateInfoWindowType(2)
            trajectoryStory.updateInfoWindow({
              ...trajectoryStory.vehicleInfo,
              ...record
            })
            trajectoryStory.queryIdx([record.longitude, record.latitude])

            trajectoryStory.updateInfoWindowVisible(true)
          }
        }
      }}
    />
  )
}

export default observer(PoliceDataTable)
