import React, { memo, useState } from 'react'
import { Button, Popover } from 'antd'
import { useIntl } from 'react-intl'
import { ColumnsType, ButtonGroupType } from '../interface'
import ColumnSetting from './columnSetting'
import { Action } from '../interface'

interface ButtonGroupProps {
  buttonValues: ButtonGroupType
  query: Function
  reset: Function
  exports: Function
  queryLoading: boolean
  exportLoading: boolean
  columns?: ColumnsType
  columnSetting: any[]
  dispatch: React.Dispatch<Action>
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    buttonValues: { showQuery, showReset, showExport, showColumnSetting, customize },
    query,
    reset,
    exports,
    queryLoading,
    exportLoading,
    columns,
    columnSetting,
    dispatch,
  } = props

  const { formatMessage: f } = useIntl()
  const [columnSettingVisible, setColumnSettingVisible] = useState(false)

  return (
    <div className="buttonGroup">
      {customize?.beforeQuery}
      {showQuery && (
        <Button
          onClick={() => {
            query('query')
            dispatch({ type: 'current', data: { current: 1 } })
          }}
          type="primary"
          loading={queryLoading}
        >
          {f({ id: 'tx000006', description: '查询' })}
        </Button>
      )}
      {customize?.afterQuery}
      {showReset && (
        <Button onClick={() => reset()} type="primary" ghost>
          {f({ id: 'tx000008', description: '重置' })}
        </Button>
      )}
      {customize?.afterReset}
      {showExport && (
        <Button onClick={() => exports()} loading={exportLoading}>
          {f({ id: 'tx000009', description: '导出' })}
        </Button>
      )}
      {customize?.afterExport}
      {showColumnSetting && (
        <Popover
          content={
            <ColumnSetting
              columns={columns}
              columnSetting={columnSetting}
              dispatch={dispatch}
              setColumnSettingVisible={setColumnSettingVisible}
            />
          }
          title={f({ id: 'tx000011', description: '列设置' })}
          trigger="click"
          placement="bottomRight"
          visible={columnSettingVisible}
          overlayClassName="tx-column-setting"
          onVisibleChange={setColumnSettingVisible}
        >
          <Button>{f({ id: 'tx000011', description: '列设置' })}</Button>
        </Popover>
      )}
      {customize?.afterColumnSetting}
    </div>
  )
}

export default memo(ButtonGroup)
