/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-27 13:54:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-27 14:05:17
 */
import api from './index'
import axios from '../axios'
import { webAPIResponse } from './index.globals'

const MODULE = '/api/concrete'

/** 获取运单列表数据 */
export const getWaybillByIds = (params: { vehicleIds: string }) => axios.post<webAPIResponse.WaybillData[]>(`${api}${MODULE}/getByVehicleIds`, params)
