/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-30 11:29:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 14:48:19
 */
import TileGrid from "ol/tilegrid/TileGrid";
import { addProjection, addCoordinateTransforms } from "ol/proj";
import Projection from 'ol/proj/Projection'
import projzh from "projzh";
import XYZ from "ol/source/XYZ";
import Tile from "ol/layer/Tile";

export const bd09Extent: [number, number, number, number] = [-20037726.37, -12474104.17, 20037726.37, 12474104.17];
var baiduMercator = new Projection({
  code: "baidu",
  extent: bd09Extent,
  units: "m"
});
addProjection(baiduMercator);
addCoordinateTransforms("EPSG:4326", baiduMercator, projzh.ll2bmerc, projzh.bmerc2ll);
addCoordinateTransforms("EPSG:3857", baiduMercator, projzh.smerc2bmerc, projzh.bmerc2smerc);

var bmercResolutions = new Array(19);
for (var i = 0; i < 19; ++i) {
  bmercResolutions[i] = Math.pow(2, 18 - i);
}

var urls = [0, 1, 2, 3].map(function (sub) {
  return (
    "http://maponline" +
    sub +
    ".bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=2&udt=20201022"
  );
});

export default new Tile({
  source: new XYZ({
    // TODO EPSG:3857
    projection: "baidu",
    maxZoom: 18,

    tileUrlFunction: function (tileCoord) {

      var x: any = tileCoord[1];
      var y: any = -tileCoord[2] - 1;
      var z: any = tileCoord[0];
      var hash = (x << z) + y;
      var index = hash % urls.length;
      index = index < 0 ? index + urls.length : index;
      var zz: any = z
      if (x < 0) {
        x = "M" + -x;
      }
      if (y < 0) {
        y = "M" + -y;
      }

      return urls[index].replace("{x}", x).replace("{y}", y).replace("{z}", zz);
    },
    tileGrid: new TileGrid({
      resolutions: bmercResolutions,
      origin: [0, 0]
    })
  })
});

export const BaiduXYH = new XYZ({
  // TODO EPSG:3857
  projection: "baidu",
  maxZoom: 18,

  tileUrlFunction: function (tileCoord) {

    var x: any = tileCoord[1];
    var y: any = -tileCoord[2] - 1;
    var z: any = tileCoord[0];
    var hash = (x << z) + y;
    var index = hash % urls.length;
    index = index < 0 ? index + urls.length : index;
    var zz: any = z
    if (x < 0) {
      x = "M" + -x;
    }
    if (y < 0) {
      y = "M" + -y;
    }

    return urls[index].replace("{x}", x).replace("{y}", y).replace("{z}", zz);
  },
  tileGrid: new TileGrid({
    resolutions: bmercResolutions,
    origin: [0, 0]
  })
})