/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-12 18:45:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-12 18:48:08
 */
import { observable, action } from 'mobx';

class LargeScreen {
  @observable vehicleIds: Array<string> = []

  @action
  updateVehicleIds = (vehicleIds: Array<string>) => {
    this.vehicleIds = vehicleIds
  }
}

const largeScreen: LargeScreen = new LargeScreen()

export { largeScreen }
export default largeScreen