import React, { PureComponent } from 'react'
import Draw, { createBox, DrawEvent } from 'ol/interaction/Draw';
import MapContext, { MapContextProps } from '../map-context';
import GeometryType from 'ol/geom/GeometryType';
import { Circle, LineString, Point, Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import DragZoom from 'ol/interaction/DragZoom'
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import { always } from 'ol/events/condition';
import { getArea, getLength } from 'ol/sphere';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import { unByKey } from 'ol/Observable';
import OverlayPositioning from 'ol/OverlayPositioning';
import './index.less'
import { toMapLayerLngLat } from '../util/comm';

export type MapToolType = GeometryType | 'close' | 'none' | 'outin' | 'out' | 'rectangle' | 'ranging' | null

export interface MapToolProps {
  /**
   * close 关闭不清除
   * clean 清除不关闭
   * none 清除且关闭
   */
  toolType: string
  /** 切换清除 */
  isChangeClean?: boolean
  /** 绘画完成后清除 */
  isClean?: boolean
  isOne?: boolean
  onDrawend?: (path: number[][], radius: number) => void
  lang?: string
}

const defaultImg = 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'

export default class MapTool extends PureComponent<MapToolProps> {

  static contextType = MapContext
  context: MapContextProps
  draw: Draw
  dragZoom: DragZoom
  /** 测距实体 */
  ranging: Draw

  /** 测量提示 */
  measureTooltipElement?: HTMLDivElement
  /** 显示测量覆盖物 */
  measureTooltip: Overlay
  /** 记录绘制了多少个测距 */
  sketchIndex: number = 1
  sketch?: Feature
  source: VectorSource
  isOne?: boolean

  componentDidMount() {
    const { map } = this.context
    this.source = new VectorSource({ wrapX: false });
    var vector = new VectorLayer({
      source: this.source,
    });

    if (map) {
      map.addLayer(vector)

    }
    this.addInteraction()
  }

  componentDidUpdate(prevProps: MapToolProps) {
    if (this.props.toolType !== prevProps.toolType) {
      if (this.props.toolType !== 'ranging') {
        this.addInteraction()
      } else {

        this.addRangingInteraction()
      }

    }
  }

  addInteraction(): void {
    this.removeRangingZoom()
    const { toolType, isChangeClean, isOne } = this.props
    if (toolType) {
      const { map } = this.context
      let _toolType = toolType
      let extra = {}
      console.log(toolType, isChangeClean);
      this.isOne = isOne
      if (isChangeClean && this.draw) {
        this.clean()
      }

      if (toolType === 'Circle') {
        extra = {
          freehand: true
        }
      }

      if (toolType === 'rectangle') {
        _toolType = 'Circle'
        extra = {
          geometryFunction: createBox()
        }
      }

      if (toolType === 'none') {
        this.removeAllPoint()
      }

      this.removeDraw()
      this.removeDragZoom()

      if (toolType === 'outin' || toolType === 'out') {
        this.createDragZoom(toolType === 'outin' ? false : true)
        this.updateCursorStyle("crosshair")
        return
      }

      if (toolType === 'close' || toolType === 'none') {
        this.updateCursorStyle()
        return
      }

      this.updateCursorStyle("crosshair")

      this.draw = new Draw({
        source: this.source,
        type: _toolType as GeometryType,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#317cec',
            width: 2,
          }),
          image: undefined,
        }),
        ...extra
      })
      this.draw.on('drawend', this.onDrawend.bind(this))
      this.draw.on('drawstart', this.onDrawStart.bind(this))
      if (map) {
        map.addInteraction(this.draw)
      }
    }
  }
  /** 初始化测距参数 */
  RangingInit() {
    this.measureTooltipElement = undefined
    this.sketch = undefined

  }
  /** 创建测距实例 */
  addRangingInteraction() {
    this.updateCursorStyle("crosshair")
    this.removeDraw()
    this.removeDragZoom()
    const { map } = this.context
    this.ranging = new Draw({
      source: this.source,
      type: GeometryType.LINE_STRING,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#15A3FA',
          lineDash: [10, 10],
          width: 2,
        }),
        image: undefined,
      })
    })

    this.createMeasureTooltip();

    let listener: any;

    this.ranging.on('drawstart', (evt: any) => {
      // set sketch
      const sketch = evt.feature;
      const _index = this.sketchIndex
      this.sketch = sketch
      this.sketch?.setId(_index)
      let tooltipCoord = evt.coordinate;

      listener = sketch.getGeometry().on('change', (evt: any) => {
        let geom = evt.target;
        let output: any;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }

        this.measureTooltipElement!.innerHTML = output;

        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.ranging.on('drawend', () => {
      const index = this.measureTooltipElement!.className.split('-').pop()
      this.measureTooltipElement!.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip.setOffset([0, -7]);
      // unset sketch
      this.sketch = undefined;

      // unset tooltip so that a new one can be created
      const closeDiv = document.createElement('div')
      closeDiv.innerHTML = '<img class="delimg" src="https://webapi.amap.com/images/destroy.png">'
      closeDiv.className = 'ol-tooltip-close-btn'
      closeDiv.onclick = () => {
        const _feature = this.source.getFeatureById(Number(index))
        this.source.removeFeature(_feature)
        map?.removeOverlay(map?.getOverlayById(Number(index)))
      }
      this.measureTooltipElement!.appendChild(closeDiv)
      this.measureTooltipElement = undefined;
      this.createMeasureTooltip();
      unByKey(listener);
    });

    if (map) {
      map.addInteraction(this.ranging)
    }

  }

  updateCursorStyle(style: string = 'default') {
    const { map } = this.context
    if (map) {
      const MapDiv = map.getTargetElement()
      MapDiv.style.cursor = style
    }
  }

  createMeasureTooltip() {
    this.sketchIndex += 1
    const { map } = this.context
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode!.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure ol-ranging-' + this.sketchIndex;
    this.measureTooltip = new Overlay({
      id: this.sketchIndex,
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER,
    });

    map?.addOverlay(this.measureTooltip);
  }

  /**
   * 计算距离
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  formatLength(line: LineString): string {
    var length = getLength(line);
    var output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
  };

  /**
   * 计算面积
   * @param {Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  formatArea(polygon: Polygon): string {
    var area = getArea(polygon);
    var output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }
    return output;
  };


  createDragZoom(type: boolean) {
    const { map } = this.context
    this.dragZoom = new DragZoom({ out: type, condition: always })
    map?.addInteraction(this.dragZoom);
  }

  removeDraw() {
    const { map } = this.context
    if (this.draw && map) {
      this.updateCursorStyle()
      map.removeInteraction(this.draw)
    }
  }

  removeDragZoom() {
    const { map } = this.context
    if (this.dragZoom) {
      map?.removeInteraction(this.dragZoom)
    }
  }

  removeRangingZoom() {
    const { map } = this.context
    if (this.ranging) {
      map?.removeInteraction(this.ranging)
    }
  }

  removePoint() {
    if (this.source.getFeatures().length) {
      this.source.removeFeature(this.source.getFeatures()[0])
    }
  }

  removeAllPoint() {
    const allFeatures = this.source.getFeatures()
    if (allFeatures) {
      for (const item of allFeatures) {
        this.source.removeFeature(item)
      }
    }
  }

  clean() {
    const { map } = this.context
    if (this.draw && map) {

      this.removePoint()
      map.removeInteraction(this.draw)
    }
  }

  onDrawStart() {
    if (this.isOne) {
      this.removePoint()
    }
  }

  onDrawend(e: DrawEvent) {
    const { type } = this.context
    const { toolType, isClean, onDrawend } = this.props
    const feature = e.feature
    const geometry = feature.getGeometry()

    if (toolType === GeometryType.POINT) {
      feature.setStyle(new Style({
        image: new Icon({
          src: defaultImg,
          anchor: [0.5, .8],
          scale: .6
        })
      }))
    }

    if (onDrawend && feature) {
      let path: any = []
      let radius = 0
      if (geometry) {
        console.log(geometry);
        if (geometry instanceof LineString) {
          path = geometry.getCoordinates().map((item: number[]) => {
            return toMapLayerLngLat(item, type)
          })
        } else if (geometry instanceof Circle) {
          path = toMapLayerLngLat(geometry.getCenter(), type)
          radius = geometry.getRadius()
        } else if (geometry instanceof Polygon) {
          path = geometry.getCoordinates()[0].map((item: number[]) => {
            return toMapLayerLngLat(item, type)
          })
        } else if (geometry instanceof Point) {
          path = toMapLayerLngLat(geometry.getCoordinates(), type)
        }
      }

      onDrawend(
        path,
        radius
      )
    }

    if (isClean && feature) {
      setTimeout(() => {
        this.source.removeFeature(feature)
      }, 100)
    }
  }

  render() {
    return null
  }
}