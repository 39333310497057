import React, { FC, useState, useEffect } from 'react'
import TiemLineItem, { TiemLineItemProps } from './tiemLineItem'
import { isEqual } from 'lodash-es'

interface TimeLineItemsProps {
  data: TiemLineItemProps[] | undefined,
  onClick: (startTime: number, endTime: number, channelId: number) => void
}
const equal = (prevProps: TimeLineItemsProps, nextProps: TimeLineItemsProps) => {
  return isEqual(prevProps.data, nextProps.data)
}

const TimeLineItems: FC<TimeLineItemsProps> = React.memo((props: TimeLineItemsProps) => {
  const { data } = props
  const [activeCode, setActiveCode] = useState<string>('')

  useEffect(() => {
    setActiveCode('')
  }, [data])

  const onClick = (startTime: number, endTime: number, channelId: number) => {
    setActiveCode(`${channelId}${startTime}`)
    if (props.onClick) {
      props.onClick(startTime, endTime, channelId)
    }
  }
  return (
    <>
      {
        data && data.map((item: TiemLineItemProps) => <TiemLineItem activeCode={ activeCode } onClick={ (startTime, endTime) => { onClick(startTime, endTime, item.channelId) } } key={ item.channelId } channelId={ item.channelId } data={ item.data } />)
      }
    </>
  )
}, equal)

export default TimeLineItems
