import React, { useRef, useEffect, useCallback, memo, useMemo, isValidElement } from 'react'
import BaseTable from './baseTable'
import { TablePaginationConfig } from 'antd/lib/table'
import { FormInstance } from 'antd/lib/form'
import Drawer from 'components/drawer'
import { useIntl } from 'react-intl'
import { TableProps, queryType, ColumnsType, ColumnType, State } from './interface'
import { TableHeader, LeftTree } from './components'
// import testDate3 from './testDate3'
import testDate2 from './testDate2'
import testDate1 from './testDate1'
import { positionStory, tableStore } from 'store'
import { isBoolean, isEqual, isUndefined } from 'lodash-es'
import classnames from 'classnames'
import { fileDownload } from 'util/comm'
import useReducer from 'util/useReducer'
import { ReloadOutlined } from '@ant-design/icons'
import './style.less'

const TX_Table = <T extends {}>(props: TableProps<T>) => {
  const {
    columns,
    tableName,
    leftTree = false,
    searchItems,
    axios,
    buttonGroup,
    extraButtonGroup,
    loading,
    currentPage,
    drawerOption,
    test,
    form,
    pollingTime,
    deleteFormField,
    initLoading = true,
    rowSelection,
    showPaginationRefresh = false,
    onRow,
    initParam,
  } = props

  const initialState = {
    canLoading: initLoading,
    dataSource: [],
    queryLoading: false,
    exportLoading: false,
    tableColumns: [],
    columnSetting: columns?.map((item: any) => item.dataIndex) || [],
    wrapperHeight: 0,
    searchHeight: 0,
    tableHeight: 0,
    current: currentPage || 1,
    pageSize: 15,
    total: 0,
    pageSizeOptions: ['15', '30', '50', '100', '200', '500'],
    virtualScroll: false,
    transform: props.transform || ['plateNos', 'driverNames', 'plateNoStrings'],
    treeSearchValue: '',
    treeParam: {},
    selectedRowKeys: [],
  }

  const [state, dispatch] = useReducer<State>(initialState)

  const {
    canLoading = initialState.canLoading,
    dataSource = initialState.dataSource,
    queryLoading = initialState.queryLoading,
    exportLoading = initialState.exportLoading,
    tableColumns = initialState.tableColumns,
    columnSetting = initialState.columnSetting,
    // wrapperHeight = initialState.wrapperHeight,
    // searchHeight = initialState.searchHeight,
    tableHeight = initialState.tableHeight,
    current = initialState.current,
    pageSize = initialState.pageSize,
    total = initialState.total,
    pageSizeOptions = initialState.pageSizeOptions,
    virtualScroll = initialState.virtualScroll,
    transform = initialState.transform,
    treeParam = initialState.treeParam,
    selectedRowKeys = initialState.selectedRowKeys,
  } = state

  const { formatMessage: f } = useIntl()
  const formRef = useRef<FormInstance>()

  const heightRef = useRef({ wrapperHeight: 0, searchHeight: 0 })
  const { wrapperHeight, searchHeight } = heightRef.current

  /** 监听props.currentPage的改变 */
  useEffect(() => {
    const { currentPage } = props
    currentPage && dispatch({ type: 'current', data: { current: currentPage } })
  }, [props])

  const wrapperRef = (node: any) => {
    if (node && node.offsetHeight !== wrapperHeight) {
      // dispatch({ type: 'wrapperHeight', data: { wrapperHeight: node.offsetHeight } })
      heightRef.current.wrapperHeight = node.offsetHeight
    }
  }

  const searchRef = (node: any) => {
    if (node && node.offsetHeight !== searchHeight) {
      const { marginTop, marginBottom } = getComputedStyle(node)
      const top = parseInt(marginTop)
      const bottom = parseInt(marginBottom)
      // dispatch({ type: 'searchHeight', data: { searchHeight: node.offsetHeight + top + bottom } })
      heightRef.current.searchHeight = node.offsetHeight + top + bottom
    }
  }

  /**
   * 取出form表单的值并统一处理
   */
  const validateFields = useCallback(async () => {
    let formDate: any = {}
    if (searchItems) {
      const _form = form || formRef
      formDate = await _form.current?.validateFields()
      if (formDate) {
        const reg = /，/g
        transform.forEach(key => {
          formDate![key] = formDate![key]?.replace(reg, ',')
        })
        if (deleteFormField) {
          deleteFormField.forEach(key => {
            delete formDate![key]
          })
        }
      }
    }
    if (formDate === undefined) {
      formDate = {}
    }
    formDate.current = current
    formDate.size = pageSize
    return { ...formDate, ...treeParam }
  }, [current, deleteFormField, form, pageSize, searchItems, transform, treeParam])

  const resetFields = useCallback(async () => {
    if (searchItems) {
      const _form = form || formRef
      await _form.current?.resetFields()
    }
    dispatch({ type: 'treeSearchValue', data: { treeSearchValue: '' } })
    dispatch({ type: 'treeParam', data: { treeParam: {} } })
    return undefined
  }, [form, searchItems])

  /**
   * 根据size判断是否开启虚拟滚动
   * @param size 每页条数
   */
  const handleVirtualScroll = (size: number) => {
    const maxSize = 100
    return size >= maxSize;
  }

  /**
   * 自动计算table内容的高度
   */
  useEffect(() => {
    const tableDom = document.getElementById(tableName)
    // table中title行的高度
    let tableTitleHeight = 0
    // 分页配置项的高度
    let paginationHeight = 0
    if (tableDom) {
      tableTitleHeight = tableDom.querySelector('.ant-table-thead')!.clientHeight
      const paginationDom = tableDom.querySelector('.ant-table-pagination')
      if (paginationDom) {
        const { marginTop } = getComputedStyle(paginationDom)
        const top = parseInt(marginTop)
        paginationHeight = paginationDom.clientHeight + top
      }
    }

    // 设置table内容的高度，额外减去2px的border
    dispatch({
      type: 'tableHeight',
      data: { tableHeight: wrapperHeight - searchHeight - paginationHeight - tableTitleHeight - 2 },
    })
  }, [tableName, dataSource, wrapperHeight, searchHeight])

  useEffect(() => {
    /**
     * 处理columns中title的国际化
     * 规则：如果有title则以title为准，否则根据intlTitle生成国际化字段
     */
    const handleColumnsIntl = (columns: ColumnsType | undefined) => {
      return (
        columns?.map((item: ColumnType<unknown>) => {
          const { title, dataIndex, intlTitle } = item
          if (!title) {
            if (intlTitle) {
              if (Array.isArray(intlTitle)) {
                item.title = intlTitle.map(item => f({ id: item })).join('') || dataIndex
              } else {
                item.title = f({ id: intlTitle, defaultMessage: String(dataIndex) })
              }
            } else {
              item.title = dataIndex
            }
          }
          return item
        }) || []
      )
    }

    /**
     * 处理列设置
     */
    const handleColumnSetting = (columns: ColumnsType | undefined) =>
      columns?.filter(
        ({ dataIndex }: ColumnType<unknown>) => !!columnSetting.find(item => item === dataIndex),
      )

    dispatch({
      type: 'tableColumns',
      data: { tableColumns: handleColumnsIntl(handleColumnSetting(columns)) },
    })
  }, [columnSetting, columns, f])

  /**
   * @prop {any | undefined} formDate 表单数据，可不传
   * 统一处理请求query
   */
  const handleQuery = useCallback(
    async (formDate?: any) => {
      if (axios?.query) {
        let payload = axios?.queryParam ? { ...axios.queryParam, ...formDate } : formDate
        payload = axios?.queryExtraParam ? { ...payload, ...axios.queryExtraParam } : payload
        dispatch({ type: 'queryLoading', data: { queryLoading: true } })
        try {
          const res = await axios.query(payload)
          if (res) {
            const data = axios.handleQueryRes ? axios.handleQueryRes(res.data) : res.data
            dispatch({ type: 'total', data: { total: +data.total } })
            axios.queryCallback && axios.queryCallback(data)
            return data.records || []
          }
          dispatch({ type: 'total', data: { total: 0 } })
          return []
        } catch (e) {
          console.log('table query error :>> ', e)
          dispatch({ type: 'total', data: { total: 0 } })
          return []
        } finally {
          dispatch({ type: 'queryLoading', data: { queryLoading: false } })
        }
      } else {
        dispatch({ type: 'total', data: { total: 0 } })
        return []
      }
    },
    [axios],
  )

  /**
   * 查询事件
   */
  const query = useCallback(
    async (type: queryType, payload?: any) => {
      if (test) {
        /**
         * 测试start
         */
        dispatch({ type: 'dataSource', data: { dataSource: testDate1 } })
        /**
         * 测试end
         */
      } else {
        if (axios?.query) {
          let formDate: any
          if (payload) {
            formDate = {
              current,
              size: pageSize,
              ...payload,
            }
          } else {
            if (type === 'refresh') {
              await resetFields()
            }
            formDate = await validateFields()
          }
          const res = await handleQuery(formDate)
          dispatch({ type: 'dataSource', data: { dataSource: res } })
        }
      }
    },
    [axios, current, handleQuery, pageSize, resetFields, test, validateFields],
  )

  /**
   * 重置按钮事件
   */
  const reset = useCallback(async () => {
    if (initLoading) {
      await query('refresh')
    } else {
      await resetFields()
      dispatch({ type: 'total', data: { total: 0 } })
      dispatch({ type: 'dataSource', data: { dataSource: [] } })
    }
    axios?.resetCallback && axios.resetCallback()
  }, [axios, initLoading, query, resetFields])

  /**
   * 导出按钮事件
   */
  const exports = useCallback(async () => {
    if (axios?.export && typeof axios.export === 'function') {
      const formDate = await validateFields()
      dispatch({ type: 'exportLoading', data: { exportLoading: true } })
      const blob: Blob = await axios.export(formDate)
      dispatch({ type: 'exportLoading', data: { exportLoading: false } })
      fileDownload(blob)
    }
  }, [axios, validateFields])

  /**
   * 新增按钮事件
   */
  const add = useCallback(async () => {
    if (extraButtonGroup && !isBoolean(extraButtonGroup) && !isValidElement(extraButtonGroup)) {
      const { onAdd } = extraButtonGroup
      onAdd && onAdd()
    }
  }, [extraButtonGroup])

  /**
   * 批量修改按钮事件
   */
  const editBatch = useCallback(async () => {
    if (extraButtonGroup && !isBoolean(extraButtonGroup) && !isValidElement(extraButtonGroup)) {
      const { onEditBatch } = extraButtonGroup
      onEditBatch && onEditBatch(selectedRowKeys)
    }
  }, [extraButtonGroup, selectedRowKeys])

  /**
   * 模板下载按钮事件
   */
  const downloadTemplate = useCallback(async () => {
    if (extraButtonGroup && !isBoolean(extraButtonGroup) && !isValidElement(extraButtonGroup)) {
      const { templateUrl } = extraButtonGroup
      templateUrl && fileDownload(templateUrl)
    }
  }, [extraButtonGroup])

  /**
   * 初始化请求
   */
  useEffect(() => {
    if (canLoading) {
      if (test) {
        /** 测试start */
        dispatch({ type: 'dataSource', data: { dataSource: testDate2 } })
        /** 测试end */
      } else if(initParam) {
        query('query', initParam)
      } else {
        query('query')
      }
    } else {
      dispatch({ type: 'canLoading', data: { canLoading: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, pageSize, treeParam, test])

  /**
   * 监听轮询
   */
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined
    if (pollingTime && pollingTime > 0) {
      timer = setInterval(() => {
        query('query')
      }, pollingTime * 1000)
    }
    return () => timer && clearInterval(timer)
  }, [pollingTime, query])

  /**
   * 向tableStore中注册事件
   */
  useEffect(() => {
    if (tableName) {
      tableStore.updateTableEvents(`${tableName}_QUERY`, query)
    }
  }, [tableName, query])

  const onPageChange = (page: number, pageSize: number) => {
    dispatch({ type: 'current', data: { current: page } })
    dispatch({ type: 'pageSize', data: { pageSize: pageSize } })
  }

  /**
   * 每页条数变化的回调，用于处理虚拟滚动的开启与否
   * @param current 当前页数
   * @param size 每页条数
   */
  const onShowSizeChange = (current: number, size: number) => {
    dispatch({ type: 'current', data: { current } })
    dispatch({ type: 'pageSize', data: { pageSize: size } })
    dispatch({ type: 'virtualScroll', data: { virtualScroll: handleVirtualScroll(size) } })
  }

  const tablePagination = useMemo<TablePaginationConfig>(
    () => ({
      current,
      pageSize,
      total,
      pageSizeOptions,
      onChange: onPageChange,
      onShowSizeChange,
      showSizeChanger: true,
      position: ['bottomLeft'],
      showTotal: total => {
        return (
          <span>
            {f({ id: 'tx000212', description: '总共 { } 项' }, { value: total })}
            {showPaginationRefresh && (
              <ReloadOutlined
                style={{ marginLeft: 8, cursor: 'pointer' }}
                onClick={() => query('query')}
              />
            )}
          </span>
        )
      },
      ...props.pagination,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, pageSize, pageSizeOptions, props.pagination, total, showPaginationRefresh],
  )

  const finallyDataSource = props.dataSource || dataSource

  return (
    <div className="tx-table-wrapper" id={tableName} ref={wrapperRef}>
      {leftTree && <LeftTree option={leftTree} state={state} dispatch={dispatch} />}
      <div
        className={classnames({
          'tx-table-container': true,
          'table-noPage': props.pagination === false || finallyDataSource.length === 0,
          'table-noData': !finallyDataSource || !finallyDataSource.length,
        })}
      >
        <TableHeader
          tableName={tableName}
          searchItems={searchItems}
          buttonGroup={buttonGroup}
          extraButtonGroup={extraButtonGroup}
          searchRef={searchRef}
          formRef={form || formRef}
          query={query}
          reset={reset}
          exports={exports}
          add={add}
          editBatch={editBatch}
          imports={
            extraButtonGroup && !isBoolean(extraButtonGroup) && !isValidElement(extraButtonGroup)
              ? extraButtonGroup.imports
              : undefined
          }
          downloadTemplate={downloadTemplate}
          queryLoading={queryLoading}
          exportLoading={exportLoading}
          columns={columns}
          columnSetting={columnSetting}
          dispatch={dispatch}
          axios={axios}
        />
        <BaseTable
          bordered
          titleOfSerialNumberCol={f({ id: 'tx000101', description: '序号' })}
          rowKey="id"
          dataSource={finallyDataSource}
          size="small"
          {...props}
          pagination={props.pagination === false ? false : tablePagination}
          loading={queryLoading || loading}
          onRow={(record, index) => ({
            ...(onRow && onRow(record as T, index)),
            onClick: e => {
              if (onRow) {
                const { onClick: propsOnClick } = onRow(record as T, index)
                propsOnClick && propsOnClick(e)
              }
              if (rowSelection && !isBoolean(rowSelection) && rowSelection.type === 'radio') {
                const rowKey = props.rowKey
                  ? typeof props.rowKey === 'string'
                    ? props.rowKey
                    : props.rowKey(record as T)
                  : 'id'
                const key = (record as any)[rowKey]
                dispatch({ type: 'selectedRowKeys', data: { selectedRowKeys: [key] } })
                if (rowSelection) {
                  rowSelection.onChange && rowSelection.onChange([key], [record as T])
                }
              }
            },
          })}
          rowSelection={
            isUndefined(rowSelection) || rowSelection === false
              ? undefined
              : {
                  selectedRowKeys,
                  ...(!isBoolean(rowSelection) && rowSelection),
                  onChange: (selectedRowKeys, selectedRows) => {
                    if (!isBoolean(rowSelection) && rowSelection.onChange) {
                      rowSelection.onChange(selectedRowKeys, selectedRows as T[])
                    }
                    dispatch({ type: 'selectedRowKeys', data: { selectedRowKeys } })
                  },
                }
          }
          columns={tableColumns}
          virtualScroll={
            finallyDataSource.length > 0 ? props.virtualScroll || virtualScroll : undefined
          }
          scroll={
            props.scroll
              ? {
                  x: props.scroll.x,
                  y: finallyDataSource.length > 0 ? props.scroll.y || tableHeight : undefined,
                  scrollToFirstRowOnChange: props.scroll.scrollToFirstRowOnChange,
                }
              : {
                  y: finallyDataSource.length > 0 ? tableHeight : undefined,
                  x: 'max-content',
                }
          }
        />
        {drawerOption && (
          <Drawer
            placement="right"
            getContainer={document.querySelector(`#${tableName}`) as HTMLElement}
            visiableFooter={true}
            {...drawerOption}
          />
        )}
      </div>
    </div>
  )
}

export default memo(TX_Table, isEqual)
