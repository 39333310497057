import React, { PureComponent, ReactNode } from 'react'
import { render as ReactRender } from 'react-dom'
import { Position, IconProps } from '../interface'
import MapContext, { MapContextProps } from '../map-context'
import { toLngLat } from '../util/comm'
import Overlay from 'ol/Overlay'
import OverlayPositioning from 'ol/OverlayPositioning'
import './marker.less'

export interface MarkerProps {
  position: Position
  zIndex?: number
  icon?: IconProps
  /** 旋转角度
   * @default 0
   */
  rotation?: number
  /**
   * 图片地址
   * @default image/red.png
   */
  offset?: number[]
  image?: string
  label?: {
    offset?: number[]
    content: string | ReactNode
    direction?: string
  }
  onClick?: (Marker: Overlay, position: Position) => void
}

interface MarkerState {
  // marker: Feature | null
  marker: Overlay | null
}

const defaultImg = 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'

export default class marker extends PureComponent<MarkerProps, MarkerState> {

  static contextType = MapContext
  context: MapContextProps
  Marker: Overlay
  MarkerDiv: HTMLDivElement
  state: MarkerState = {
    marker: null,
  }

  /** 设置样式 */
  // createStyle() {
  //   const MarkerIcon = getIcon({
  //     anchor: [0.5, 0.5],
  //     crossOrigin: 'anonymous',
  //     src: this.props.image || defaultImg,
  //     imgSize: [10, 20],
  //     rotation: this.props.rotation || 0
  //   })

  //   return getStyle({ image: MarkerIcon })
  // }

  /** 更新marker点 */
  updateMarker() {
    if (this.state.marker) {
      this.state.marker.setPosition(toLngLat(this.props.position, this.context.type))
      this.setChild()
    }
  }


  componentDidMount(): void {

    const { map, type } = this.context

    this.MarkerDiv = document.createElement('div')
    this.MarkerDiv.className = 'ol-tx-marker'
    this.setChild()

    const Marker = new Overlay({
      position: toLngLat(this.props.position, type),
      positioning: OverlayPositioning.CENTER_CENTER,
      element: this.MarkerDiv,
      stopEvent: false,
    });

    map?.addOverlay(Marker)

    this.setState({
      marker: Marker
    })
  }

  componentDidUpdate(): void {
    if (this.state.marker !== null) {
      this.updateMarker()
    }
  }

  componentWillUnmount() {
    if (this.state.marker) {
      this.context.map?.removeOverlay(this.state.marker)
      this.setState({
        marker: null
      })
    }
  }


  setChild() {
    if (this.MarkerDiv) {
      const { icon, label, rotation, offset } = this.props
      const _offset = offset ? offset : icon?.offset
      const _Div = <>
        <div
          style={ {
            left: `${_offset ? _offset[0] + 13 : 13}px`,
            top: `${_offset ? _offset[1] : 0}px`,
          } }
          className='ol-tx-marker-icon'
          onClick={ () => {
            if (this.props.onClick) {
              this.props.onClick(this.state.marker!, this.props.position)
            }
          } }
        >
          <img
            style={ {
              width: `${icon?.size ? icon.size[0] : 13}px`,
              height: `${icon?.size ? icon.size[1] : 26}px`,
              transform: `rotate(${rotation || 0}deg)`
            } }
            src={ this.props.icon ? this.props.icon.src ? this.props.icon.src : defaultImg : defaultImg } />
        </div>
        {
          this.props.label ?
            <div
              style={ {
                left: `${label?.offset ? label.offset[0] : 0}px`,
                top: `${label?.offset ? label.offset[1] : 0}px`,
              } }
              className='ol-tx-marker-label'>
              { this.props.label.content }
            </div>
            : <></>
        }
      </>
      ReactRender(_Div, this.MarkerDiv)
    }
  }

  render(): ReactNode {
    return null
  }
}
