/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-11 17:03:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 20:00:47
 */
import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { Props, Tools } from './interface'
import { isUndefined } from 'lodash-es'

const useHandleColumns = ({
  props: { columns, showSerialNumberCol, titleOfSerialNumberCol, pagination },
}: Tools) =>
  useMemo<Props['columns']>(() => {
    if (isUndefined(columns) || columns.length === 0) return undefined
    /** 所有列的超过宽度将自动省略以及Tooltip提示 */
    const handleColumnsEllipsis = (columns: Props['columns']): Props['columns'] =>
      columns?.map(item => {
        if (item.ellipsis !== false) {
          item.ellipsis = { showTitle: false }
        }
        if (!item.render) {
          item.render = text =>
            text || text === 0 ? (
              <Tooltip title={text} placement="topLeft">
                {text}
              </Tooltip>
            ) : (
              '-'
            )
        }
        return item
      })

    /** 处理table的序号列 */
    const handleSerialNumberCol = (columns: Props['columns']): Props['columns'] => {
      if (columns && showSerialNumberCol) {
        const col: ColumnType<any> = {
          title: titleOfSerialNumberCol,
          dataIndex: '_index',
          className: 'tx-table-serial-number-col',
          width: 50,
          fixed: true,
          render: (_text, _record, index) => {
            const current = pagination ? pagination.current || pagination.defaultCurrent || 1 : 1
            const pageSize = pagination
              ? pagination.pageSize || pagination.defaultPageSize || 10
              : 10
            return (current - 1) * pageSize + index + 1
          },
        }
        columns.unshift(col)
      }
      return columns
    }

    return handleSerialNumberCol(handleColumnsEllipsis(columns))
  }, [columns, pagination, showSerialNumberCol, titleOfSerialNumberCol])

export default useHandleColumns
