import React, { Children, FC } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { useIntl } from 'react-intl'
import { LimitDropdownProps } from './interface'

const { Item } = Menu

const LimitDropdown: FC<LimitDropdownProps> = props => {
  const { limit = 3, children } = props
  const { formatMessage: f } = useIntl()
  const _children = Children.toArray(children)
  const menuArr = _children.length >= limit ? _children.splice(limit) : []

  const menu = (
    <Menu>
      {menuArr.map((item: any) => (
        <Item key={item.key || item}>{item}</Item>
      ))}
    </Menu>
  )

  return (
    <>
      {_children}
      {menuArr.length > 0 && (
        <Dropdown overlay={menu}>
          <Button type="link" size="small">
            {f({ id: 'tx000040', description: '更多' })}
          </Button>
        </Dropdown>
      )}
    </>
  )
}

export default LimitDropdown
