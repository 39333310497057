import React, { useState, useEffect } from 'react'
import { Menu } from 'antd'
import { useBasicCodes } from 'util/hook'

interface menuProps {
  onClick?: Function
  time: number
}

function SettingMenu(props: menuProps) {

  useEffect(() => {
    setSettingMenuSelectKeys([props.time + ''])
  }, [props.time])

  const [{ data: basicCodes }] = useBasicCodes([
    'VideoTimeClose',
  ])

  const { onClick } = props

  const [settingMenuSelectKeys, setSettingMenuSelectKeys] = useState<Array<string>>(['0'])

  const settingMenuChange = (obj: any) => {
    setSettingMenuSelectKeys([obj.key])

    if (onClick) {
      onClick(obj.key)
    }
  }

  return (
    <Menu selectedKeys={ settingMenuSelectKeys } onClick={ settingMenuChange }>
      {
        basicCodes?.VideoTimeClose?.map(item => {
          return <Menu.Item key={ Number(item.key) }>
            <span>{ item.text }</span>
          </Menu.Item>
        })
      }
    </Menu>
  )
}

export default SettingMenu
