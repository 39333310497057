/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-07-20 10:03:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-20 17:17:47
 */
import comm from './comm'

export default {
  ...comm,
}