/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-06 15:31:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 09:27:57
 */
import { useEffect, useCallback } from 'react'
import { useAxios } from 'util/hook'
import { Tools } from './interface'
import { isUndefined, isEqual } from 'lodash-es'

/** 封装组件内部的请求 */
const useHandleAxios = ({
  props: { treeData, query, payload, handleResult, queryTime },
  setState,
}: Tools) => {
  /** 是否能够请求 */
  const isRequestAble = queryTime !== false && isUndefined(treeData) && !!query

  const [{ loading, payload: _payload }, _queryData] = useAxios({
    axios: query,
    payload,
    handleResult,
    onSuccess: (res: any) => setState({ treeData: res }),
    defaultStart: isRequestAble && queryTime === 'useEffect',
  })

  /** 更新loading */
  useEffect(() => setState({ loading }), [loading, setState])

  /** 封装queryData */
  const queryData = useCallback<typeof _queryData>(
    async config => isRequestAble && _queryData(config),
    [_queryData, isRequestAble],
  )

  /**
   * props中的payload变更则重新请求数据
   * @description（深度比较）
   */
  useEffect(() => {
    isRequestAble && !isEqual(payload, _payload) && queryData({ payload })
  }, [_payload, isRequestAble, payload, queryData])

  return queryData
}

export default useHandleAxios
