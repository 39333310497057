import { createFromIconfontCN } from '@ant-design/icons'
import LineIcon from './line'

const IconFont = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_2026389_qyn9g72ow7.js', '//at.alicdn.com/t/font_1515541_q2dhk444jy.js']
})

export {
  LineIcon
}

export default IconFont
