import React, { useState } from 'react'
import { Menu } from 'antd'

interface menuProps {
  onClick?: Function
}

function SettingMenu(props: menuProps) {
  const { onClick } = props

  const [screenMenuSelectKeys, setScreenMenuSelectKeys] = useState<Array<string>>(['1'])

  const screenMenuChange = (obj: any) => {
    setScreenMenuSelectKeys([obj.key])
    if (onClick) {
      onClick(obj.key)
    }
  }

  return (
    <Menu selectedKeys={ screenMenuSelectKeys } onClick={ screenMenuChange }>
      <Menu.Item key='1'>
        <span>满画面显示</span>
      </Menu.Item>
      <Menu.Item key='2'>
        <span>4:3 显示</span>
      </Menu.Item>
      <Menu.Item key='3'>
        <span>16:9 显示</span>
      </Menu.Item>
    </Menu>
  )
}

export default SettingMenu
