import React, { useEffect } from 'react'
import Chunk from './components/chunk'
import Title from './components/title';
import { Echarts } from 'components'
import echarts from 'echarts/lib/echarts'
import useSetState from 'util/useSetState'

interface MyState {
  vehiclexAxis: string[]
  vehicleData: number[]
  vehicleGroupxAxis: string[]
  vehicleGroupData: number[]
}

interface DeductedProps {
  /** 车队排行 */
  vehicleTeamTops?: Array<{
    alarmNum: number
    depName: string
  }>
  /** 车辆排行 */
  vehicleTops?: Array<{
    alarmNum: number
    plateNo: string
    vehicleId: string
  }>
}
const defaultxAxis: Array<string> = ['-', '-', '-', '-', '-']
const defaultData: Array<number> = [0, 0, 0, 0, 0]

function Deducted(props: DeductedProps) {
  useEffect(() => {
    // 获取车牌号
    const vehiclePlateNos = props.vehicleTops ? props.vehicleTops.map(item => item.plateNo) : []
    const vehiclexAxis = vehiclePlateNos.length ? [...vehiclePlateNos, ...defaultxAxis].splice(0, 5) : defaultxAxis
    // 获取报警数据
    const vehicleAlarmNums = props.vehicleTops ? props.vehicleTops.map(item => item.alarmNum) : []
    const vehicleData = vehicleAlarmNums.length ? [...vehicleAlarmNums, ...defaultData].splice(0, 5) : defaultData

    // 获取车牌号
    const GroupNamess = props.vehicleTeamTops ? props.vehicleTeamTops.map(item => item.depName) : []
    const vehicleGroupxAxis = GroupNamess.length ? [...GroupNamess, ...defaultxAxis].splice(0, 5) : defaultxAxis
    // 获取报警数据
    const GroupAlarmNums = props.vehicleTeamTops ? props.vehicleTeamTops.map(item => item.alarmNum) : []
    const vehicleGroupData = GroupAlarmNums.length ? [...GroupAlarmNums, ...defaultData].splice(0, 5) : defaultData

    setState({
      vehiclexAxis,
      vehicleData,
      vehicleGroupxAxis,
      vehicleGroupData,
    })
  }, [props.vehicleTeamTops, props.vehicleTops])

  const vehicleBarColor: Array<string> = ['19,152,245', '13,208,174', '216,226,59', '238,160,98', '247,98,98']



  const handleVehicleBarColor = (barObj: any): any => {
    if (barObj) {

      return new echarts.graphic.LinearGradient(
        0, 0, 0, 1,
        [
          { offset: 0, color: `rgba(${vehicleBarColor[barObj.dataIndex]},1)` },
          { offset: 1, color: `rgba(${vehicleBarColor[barObj.dataIndex]},.1)` }
        ]
      )
    }
  }

  const [state, setState] = useSetState<MyState>({
    vehiclexAxis: defaultxAxis,
    vehicleData: defaultData,
    vehicleGroupxAxis: defaultxAxis,
    vehicleGroupData: defaultData,
  })

  return (
    <div className='tx-large-deduted'>
      <Chunk>
        <div className='large-deduted-content'>
          <Title title='tx000120' />
          <div className='large-deduted-echarts'>
            <div className='large-deduted-echarts-one'>
              <Echarts
                grid={ {
                  left: '3%',
                  right: '2%',
                  bottom: '4%',
                  top: '10%',
                  containLabel: true
                } }
                tooltip={ {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'none'
                  }
                }
                }
                legend={ {
                  right: 20,
                  orient: 'vertical',
                } }
                xAxis={ {
                  axisLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  },
                  axisLabel: {
                    show: false
                  },
                  data: state.vehicleGroupxAxis
                } }
                yAxis={ {
                  axisLine: {
                    show: false
                  },
                  axisTick: {
                    show: false
                  },
                  splitLine: {
                    show: false
                  },
                  axisLabel: {
                    color: '#fff'
                  }
                } }
                series={ {
                  type: 'bar',
                  itemStyle: {
                    color: handleVehicleBarColor,
                    barBorderRadius: 20
                  },
                  barWidth: 12,
                  showBackground: true,
                  backgroundStyle: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        { offset: 1, color: `rgba(16, 16, 63,.1)` },
                        { offset: 0, color: `rgba(30, 31, 92,1)` },
                      ]
                    ),
                    barBorderRadius: 20
                  },
                  data: state.vehicleGroupData,
                } as any }
                style={ { width: '60%' } }
              />
              <div className='deduted-items'>
                {
                  state.vehicleGroupxAxis.length ?
                    state.vehicleGroupxAxis.map((item: string) =>
                      <div className='deduted-item ellipsis-2'>{ item }</div>
                    ) : null
                }
              </div>
            </div>
            <Echarts
              grid={ {
                left: '3%',
                right: '2%',
                top: '5%',
                bottom: '-2%',
                containLabel: true
              } }
              tooltip={ {
                trigger: 'axis',
                axisPointer: {
                  type: 'none'
                }
              }
              }
              xAxis={ {
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  show: false
                },
                axisLabel: {
                  rotate: 25,
                  color: '#fff'
                },
                data: state.vehiclexAxis
              } }
              yAxis={ {
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(23, 23, 82, .8)'
                  }
                },
                axisLabel: {
                  color: '#fff'
                }
              } }
              series={ {
                type: 'bar',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: `rgba(19,152,245,1)` },
                      { offset: 1, color: `rgba(19,152,245,.1)` }
                    ]
                  ),
                  barBorderRadius: [20, 20, 0, 0]
                },
                barWidth: 12,
                data: state.vehicleData,
              } as any }
              style={ { width: '100%', height: '50%' } }
            />

          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default Deducted
