import { StatisticsType, ChartStatisticsType, ChartLoadings } from './interface';
import { getLangMessage } from 'util/comm';
import moment from 'moment';

export const format = 'YYYY-MM-DD'

export const dateObj = {
  days: 1,
  weeks: 7,
  months: 30,
}

const alarmMainList = [
  { id: 'tx1700006', description: '前向碰撞报警' },
  { id: 'tx1700011', description: '驾驶员异常报警' },
  { id: 'tx1700012', description: '行人碰撞报警' },
  { id: 'tx1700010', description: '接打电话报警' },
  { id: 'tx1700008', description: '车道偏离报警' },
  { id: 'tx1700009', description: '超速报警（终端）' },
  { id: 'tx1700007', description: '抽烟报警' },
]

const alarmTypeList = [
  { id: 'tx1700003', description: '危险驾驶指数' },
  { id: 'tx1700004', description: '疲劳驾驶指数' },
  { id: 'tx1700002', description: '平稳驾驶指数' },
  { id: 'tx1700005', description: '非专注驾驶指数' },
  { id: 'tx1700001', description: '操控驾驶指数' },
]

export const statistics: StatisticsType = {
  /** 总时长 */
  durationSum: '-',
  /** 上线车辆 */
  onLineVehicleNum: '-',
  /** 车辆上线率 */
  onLineVehicleNumRatio: '-',
  /** 行驶总里程 */
  steerMileage: '-',
  /** 车辆总数 */
  vehicleNum: '-',
  /** 报警总数 */
  alarmNum: '-',
}

const alarmTrend = []

for (let i = 1; i < 15; i++) {
  const alarmTrendObj = {
    alarmDate: moment().subtract(i, 'days').format(format),
    trendNum: 0
  }
  alarmTrend.unshift(alarmTrendObj)
}

const alarmMainStatis = alarmMainList.map(item => ({
  alarmNum: 0,
  alarmType: '',
  alarmTypeName: getLangMessage(item),
}))

const alarmTypeStatis = {
  alarmNum: 0,
  lists: alarmTypeList.map(item => ({
    alarmNum: 0,
    alarmType: '',
    alarmTypeName: getLangMessage(item)
  }))
}


const vehicleTops = []
const vehicleTeamTops = []

for (let i = 1; i <= 10; i++) {
  const vehicleTopsObj = {
    alarmNum: 0,
    plateNo: i + '  '
  }
  const vehicleTeamTopsObj = {
    alarmNum: 0,
    depName: i + '  '
  }
  vehicleTops.push(vehicleTopsObj)
  vehicleTeamTops.push(vehicleTeamTopsObj)
}

export const chartStatistics: ChartStatisticsType = {
  /** 报警趋势 */
  alarmTrend,
  /** 主要报警统计 */
  alarmMainStatis,
  /** 报警类型分布 */
  alarmTypeStatis,
  /** 车辆排行TOP10 */
  vehicleTops,
  /** 车队排行TOP10 */
  vehicleTeamTops,
}

export const chartLoadings: ChartLoadings = {
  alarmTrendLoading: false,
  alarmMainStatisLoading: false,
  alarmTypeStatisLoading: false,
  vehicleTopsLoading: false,
}