import { forIn } from 'lodash-es';
import { PureComponent } from 'react'
import { TextProps } from './interface';
import { pointConvert, handleOptions, toPixel } from './util/comm'

const defaultOptions: Array<string> = [
  'topWhenClick',
  'bubble',
  'draggable',
  'raiseOnDrag',
  'cursor',
  'visible',
  'zIndex',
  'angle',
  'style',
  'text',
]

export default class Text extends PureComponent<TextProps> {

  map: any
  text: any

  constructor(props: TextProps) {
    super(props)
    this.map = props.__map__
    this.createText()
  }

  componentWillReceiveProps(nextProps: TextProps) {
    if (nextProps.position?.toString() !== this.props.position?.toString()) {
      this.updatePosition(nextProps)
    }
    if (nextProps.text !== this.props.text) {
      this.updateText(nextProps)
    }
  }

  componentWillUnmount() {
    this.remove()
  }

  async createText() {
    if (this.props.position) {
      const options = handleOptions(this.props, defaultOptions)
      // 批量转换
      const position = await pointConvert([this.props.position], this.props.type)
      options.position = position[0]

      if (this.props.offset) {
        options.offset = toPixel(this.props.offset, this.props.type)
      }

      if (this.props.anchor) {
        options.anchor = this.props.anchor
      } else {
        options.anchor = 'top-center'
      }

      if (this.props.type === 'amap') {
        this.text = new (window as any).AMap.Text(options)
        this.text.setMap(this.map)
      } else if (this.props.type === 'baidu') {
        this.text = new (window as any).BMap.Label(options.text, options)
        if (this.props.style) {
          this.text.setStyle({
            ...this.props.style,
            transform: 'translateX(-50%)'
          })
        }
        this.map.addOverlay(this.text)
      }

    }
  }

  async updatePosition(props: TextProps) {
    if (this.text && props.position) {
      const position = await pointConvert([props.position], props.type)
      if (props.type === 'amap') {
        this.text.setPosition(position[0])
      } else if (props.type === 'baidu') {
        this.text.setPosition(position[0])
      }
    }
  }

  updateText(props: TextProps) {
    if (this.text) {
      if (props.type === 'amap') {
        this.text.setText(props.text)
      } else if (props.type === 'baidu') {
        this.text.setContent(props.text)
      }
    }
  }

  remove() {
    if (this.text) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.text)
      } else if (this.props.type === 'amap') {
        this.map.remove(this.text)
      }
      this.text = null
    }
  }

  render() {
    return null
  }
}
