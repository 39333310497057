/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-06 11:02:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 09:27:41
 */
import { useEffect } from 'react'
import { Tools } from './interface'

/** 更新所有props的传值 */
const useUpdateProps = ({ props: { treeData, searchValue }, setState }: Tools) => {
  useEffect(() => setState({ treeData }), [treeData, setState])
  useEffect(() => setState({ searchValue }), [searchValue, setState])
}

export default useUpdateProps
