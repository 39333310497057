/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2021-01-06 11:11:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:01:04
 */
import React, { PureComponent, CSSProperties } from 'react'
import Map from 'ol/Map'
import { MapOptions } from 'ol/PluggableMap';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import MapEvent from 'ol/MapEvent';
import BMap, { BaiduXYH } from './layer/baidu';
import Amap, { AmapXYZ } from './layer/amap';
import { MapProps, Position } from './interface';
import MapContext from './map-context';
import { toLngLat, toMapLayerLngLat } from './util/comm'
import { isEqual } from 'lodash-es';
import { Zoom, ZoomSlider } from 'ol/control';
import classnames from 'classnames';
import "ol/ol.css";
import './index.less';

const wrapperStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative'
}

export enum MapType {
  /** 百度 */
  baidu = 'baidu',
  /** 高德 */
  amap = 'amap'
}

export interface MapState {
  /** 地图实例 */
  map: Map | null
  type: string
}

export default class index extends PureComponent<MapProps, MapState> {

  state: MapState = {
    map: null,
    type: MapType.amap
  }

  mapRef: Element | null = null
  /** 缓存zoom，用于切换地图类型时 */
  cacheZoom: number | undefined = undefined
  /** 缓存center，用于切换地图类型时 */
  cacheCenter: Position | undefined = undefined

  /** 创建地图实例 */
  getInstance(isCache?: boolean): Map | null {

    if (!this.mapRef) {
      return null
    }

    let layers = this.createLayers()

    return new Map({
      target: this.mapRef! as HTMLElement,
      view: this.createView(isCache),
      controls: [new Zoom(), new ZoomSlider()],
      layers
    })
  }

  createView(isCache?: boolean) {
    const { center, type, zoom, minZoom, maxZoom } = this.props

    let _center = isCache && this.cacheCenter ? this.cacheCenter : center || [0, 0]
    let _zoom = isCache && this.cacheZoom ? this.cacheZoom : zoom || 8
    return new View({
      zoom: _zoom,
      minZoom: minZoom || 4,
      maxZoom: maxZoom || 18,
      enableRotation: true,
      constrainRotation: true,
      center: toLngLat(_center, type),
      // TODO EPSG:3857
      projection: type,
    })
  }

  createLayers() {
    const { type } = this.props
    let layers: MapOptions["layers"] = [new TileLayer({ source: AmapXYZ })]

    switch (type) {
      case MapType.amap:
        layers = [new TileLayer({ source: AmapXYZ })]
        break;
      case MapType.baidu:
        layers = [new TileLayer({ source: BaiduXYH })]
        break;
      default:
        break;
    }
    return layers
  }

  changeView() {
    const { map } = this.state
    const layers = this.createLayers()
    const view = this.createView(true)
    if (map) {
      // const oldLayers = map.getLayers()
      // console.log(oldLayers);
      map.addLayer(layers[0])
      map.setView(view)
    }
  }

  /** state 设置实例回调函数 */
  setMapCallback() {
    if (this.state.map !== null) {
      if (this.props.onInstanceCreated) {
        this.props.onInstanceCreated(this.state.map)
      }
    }
  }

  /** 设置地图实例 */
  createMap(isCache?: boolean) {
    const { type } = this.props

    const map = this.getInstance(isCache);
    // 设置地图后事件
    (window as any).olMapMoveed = this.moveListener.bind(this)
    map?.on('moveend', (window as any).olMapMoveed)

    this.setState(function setMap() {
      return {
        map,
        type: type
      }
    }, this.setMapCallback)
  }

  moveListener(e: MapEvent) {
    // 获取视图实例
    const view = e.map.getView()
    // 获取中心点位和缩放等级并且进行缓存
    const center = view.getCenter()
    this.cacheZoom = view.getZoom()
    this.cacheCenter = toMapLayerLngLat(center!, this.props.type)
    if (this.props.onMoveend) {
      this.props.onMoveend(e.map)
    }
  }

  /** 删除地图事件 */
  removeEvent() {
    if (this.state.map) {
      this.state.map.removeEventListener('moveend', (window as any).olMapMoveed)
    }
  }

  /** 添加地图事件 */
  addEvent() {

  }

  componentDidMount(): void {
    setTimeout(() => {
      this.createMap()
    }, 100)
  }

  componentDidUpdate(prevProps: MapProps): void {
    if (prevProps.type !== this.props.type) {

      this.mapRef = null
      this.removeEvent()
      this.setState({
        map: null
      })
      setTimeout(() => {
        this.createMap(true)
      }, 10)
    }
    if (this.props.center && !isEqual(prevProps.center, this.props.center)) {
      if (this.state.map) {
        this.state.map.getView().setCenter(toLngLat(this.props.center, this.state.type))
      }
    }

    if (this.props.updateSize !== prevProps.updateSize) {
      this.updateSize()
    }
  }

  componentWillUnmount(): void {
    if (this.state.map !== null) {
      this.removeEvent()
      if (this.props.onRemoved) {
        this.props.onRemoved()
      }
    }
  }

  updateSize() {
    if (this.mapRef && this.state.map) {
      this.state.map.updateSize()
    }
  }

  getRef(ref: HTMLDivElement | null): void {
    this.mapRef = ref
  }

  render() {

    return (
      <div
        style={ wrapperStyle }
        className={ classnames('tx-ol-map', this.props.className) }
      >
        <div
          ref={ this.getRef.bind(this) }
          style={ wrapperStyle }
          key={ this.props.type }
        >
          <MapContext.Provider value={ {
            map: this.state.map,
            type: this.props.type
          } } >
            { this.state.map !== null ? this.props.children : <></> }
          </MapContext.Provider>
        </div>
      </div>
    )
  }
}
