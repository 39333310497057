/**
 * @description drawer中的tabs，只封装样式
 */
import React from 'react'
import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import classnames from 'classnames'
import './index.less'

const { TabPane } = Tabs

const DrawerTabs = (props: TabsProps) => {
  const { className } = props
  return <Tabs {...props} className={classnames('tx-drawer-tabs', className)} />
}

DrawerTabs.TabPane = TabPane

export default DrawerTabs
