import React, { useState } from 'react'
import { findRegionalCar } from 'server/web/gps'
import { Table } from 'components';
import { ColumnsType } from 'components/table/interface'
import { webAPIResponse } from 'server/web/index.globals';
import { Button } from 'antd';
import { useIntl } from 'react-intl'
import { VehicleInfo } from '../../rightCommand'

interface RegionVehicleProps {
  points: string
}

function RegionVehicleModal(props: RegionVehicleProps) {

  const { formatMessage: f } = useIntl()
  const [visible, setVisible] = useState<boolean>(false)
  const [record, setRecord] = useState<any>()

  const columns: ColumnsType<webAPIResponse.findRegionalCar> = [
    {
      intlTitle: 'tx010001',
      description: '车牌号',
      dataIndex: 'plateNo',
      width: 120
    },
    {
      intlTitle: 'tx010011',
      description: '车牌颜色',
      dataIndex: 'plateColor',
      width: 120
    },
    {
      intlTitle: 'tx010002',
      description: '车队',
      dataIndex: 'depName',
      width: 140
    },
    {
      intlTitle: 'tx060001',
      description: 'SIM卡号',
      dataIndex: 'simNo',
      width: 120
    },
    {
      intlTitle: 'tx000110',
      description: '位置',
      dataIndex: 'location',
      width: 200
    },
    {
      intlTitle: 'tx060004',
      description: 'GPS定位时间',
      dataIndex: 'sendTime',
      width: 160
    },
    {
      intlTitle: 'tx010004',
      description: '速度',
      dataIndex: 'velocity',
      width: 100
    },
    {
      intlTitle: 'tx010102',
      description: '上线',
      dataIndex: 'onlineDesc',
      width: 120
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      description: '操作',
      width: 70,
      fixed: 'right',
      render: (_text, record) => {
        // console.log(record)
        return <Button
          type='link'
          onClick={ () => {
            setVisible(true)
            setRecord(record)
          } }>{ f({ id: 'tx000010', description: '查看' }) }</Button>
      }
    },
  ]
  return (
    <div style={ { height: 500 } }>

      {
        record ?
          <VehicleInfo
            record={ record }
            visible={ visible }
            onCancel={ () => {
              setVisible(false)
            } }
            afterClose={ () => {
              setRecord(null)
            } }
          /> : null
      }
      <Table
        tableName='regionVehicleModalTable'
        buttonGroup={ false }
        
        showPaginationRefresh={ true }
        axios={ {
          query: findRegionalCar,
          queryExtraParam: {
            points: props.points
          }
        } }
        columns={ columns }
      />
    </div>
  )
}

export default RegionVehicleModal
