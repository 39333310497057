/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-09-01 10:11:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-03 16:29:05
 */
import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse } from './index.globals'

const MODULE = '/api/vehicleTree'

/** 企业  */
export interface CompanyDtos {
  departmentDtos: string
  id: string
  name: string
  type: string
}

/** 车组 */
export interface DepartmentDto {
  childs?: []
  /** 所属企业id */
  companyId: number
  /** 车组名称 */
  depName: string
  /** 上级车组,0为顶级车组 */
  parentId: number
  id: string
  /**
  * 0 企业; 1 车组; 2 车辆;
  */
  type: string
  /** 本级车组所拥有车辆 */
  vehicleDtos?: VehicleDto[]
}

/** 车辆 */
export interface VehicleDto {
  /** 车组id */
  depId: number
  /** 是否报警 */
  isAlarm: boolean
  /** GPS设备在线状态, false代表不在线 */
  online: boolean
  /** false:停车 ，true行驶 */
  isRun: boolean
  /** 车牌号 */
  plateNo: string
  /** 运行状态 */
  runStatus: string
  /**
  * 0 企业; 1 车组; 2 车辆;
  */
  type: string
  simNo: string
  /** 车辆id */
  vehicleId: number
  /** 音视频通道,通道1-10，逗号分隔 */
  videoChannel: string
  /** 音视频通道描述,通道1-10，逗号分隔 */
  videoChannelDesc: string
  /** 音视频通道数量 */
  videoChannelNum: number
}

/** 摄像头 */
interface CameraDto {
  cameraId: string
  companyId: string
  cameraName: string
  cameraUser: string
  cameraPassword: string
  uid: string
}

/** 摄像头树-Flate版 */
interface CameraTreeFlate {
  /** 车辆 */
  cameraDtos: CameraDto[]
  /** 企业 */
  companyDtos: CompanyDtos[]
}

/** 车辆树 */
export const getVehicleTree = (data: webAPIRequest.VehicleTree) => axios.post<webAPIResponse.VehicleTree[]>(`${api}${MODULE}/tree`, data)

/** 车辆树 */
export const getVehicleTreeFlate = (data: webAPIRequest.VehicleTree) => axios.post<webAPIResponse.VehicleTreeFlate>(`${api}${MODULE}/treeFlate`, data)

/** 摄像头树 */
export const getCameraTreeFlate = (data: webAPIRequest.VehicleTree) => axios.post<CameraTreeFlate>(`${api}/camera/getCameraTree`, data)

/** 获取摄像头直播地址 */
export const getBroadCastUrl = (data: {cameraId: string}) => axios.post<string>(`${api}/camera/getBroadCastUrl`, data)
