/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-27 18:57:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-11 16:12:26
 */
import { useState, useEffect } from 'react';
import { getBasicCodes, BasicCodesType } from 'server/web/basicCode'
import { QueryState, UseAxiosHook } from './interface';

type Padload = (keyof BasicCodesType)[]
type Result = BasicCodesType

const useBasicCodes: UseAxiosHook<Padload, Result> = (payload, callback) => {
  const [param, setParam] = useState(payload)
  const [state, setState] = useState<QueryState<Result>>({
    data: undefined,
    loading: false,
    success: false
  });

  useEffect(() => {
    const queryBasicCodes = async () => {
      setState((state) => ({ ...state, loading: true, success: false }))
      const res = await getBasicCodes({ types: param })
      setState((state) => ({ ...state, loading: false }))
      if (res) {
        const { data } = res
        setState(state => ({
          ...state, data, success: true
        }))
        callback && callback(data)
      } else {
        setState(state => ({
          ...state, success: false
        }))
      }
    }
    queryBasicCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  return [state, setParam]
}

export default useBasicCodes