import React, { useState, useEffect } from 'react'
import { List, Radio, Tooltip, Button } from 'antd'
import { Modal } from 'components'
import { useIntl } from 'react-intl'
import { ModalProps as TxModalProps } from 'components/modal/interface'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { delIssueContent } from 'server/web/dispatch'
import { webAPIResponse } from 'server/web/index.globals'

const { Item } = List
const { Group } = Radio

interface ModalProps extends Omit<TxModalProps, 'onOk'> {
  onOk?: (value: string) => any
  record?: webAPIResponse.AlarmPage
  result?: webAPIResponse.AlarmInfo
  dataSource?: webAPIResponse.IssueContent[]
  query: () => Promise<void>
  loading: boolean
}

const CommonText = (props: ModalProps) => {
  const { onOk, record, result, dataSource, query, loading, ...extra } = props
  const [radioValue, setRadioValue] = useState('')
  const [deleteId, setDeleteId] = useState<string>()
  const { formatMessage: f } = useIntl()

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target
    setRadioValue(String(value))
  }

  const onDelete = async (id: string) => {
    setDeleteId(id)
    try {
      const res = await delIssueContent({ id })
      res && res.data && query()
    } finally {
      setDeleteId(undefined)
    }
  }

  useEffect(() => {
    query()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      {...extra}
      onOk={() => onOk && onOk(radioValue)}
      className="commonText-modal"
      forceRender
    >
      <Group onChange={onChange}>
        <List
          size="small"
          loading={loading}
          dataSource={dataSource}
          renderItem={item => (
            <Item
              actions={[
                <Button
                  type="link"
                  danger
                  key="delete"
                  loading={deleteId === item.id}
                  onClick={() => onDelete(item.id)}
                >
                  {f({ id: 'tx000004', description: '删除' })}
                </Button>,
              ]}
              key={item.id}
            >
              <div className="span">
                <Radio value={item.issueContent} />
                <Tooltip title={item.issueContent} placement="topLeft">
                  <span>{item.issueContent}</span>
                </Tooltip>
              </div>
            </Item>
          )}
        />
      </Group>
    </Modal>
  )
}

export default CommonText
