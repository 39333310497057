import { useState, useCallback } from 'react'
import { isFunction } from 'lodash-es';

function useSetState<S extends object>(
  initalState: S | (() => S)
): [S, (state: Partial<S> | ((state: S) => Partial<S>)) => void] {
  const [_state, _setState] = useState<S>(initalState)

  const setState = useCallback((state: Partial<S> | ((state: S) => Partial<S>)) => {
    _setState((prev: S) => {
      let nextState = state

      if (isFunction(state)) {
        nextState = state(prev)
      }

      return { ...prev, ...nextState }
    })
  }, [_setState])

  return [_state, setState]
}

export default useSetState