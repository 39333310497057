import { PureComponent } from 'react'
import lodash from 'lodash-es'
import { isInArea, pointConvert, toIcon, toLngLat } from './util/comm'

/**
 * @enum 0 无状态
 * @enum 1 播放
 * @enum 2 暂停
 * @enum 3 停止
 * @enum 4 恢复
 */
type ToolCode = 0 | 1 | 2 | 3 | 4

export interface PathProps {
  name: string
  /** 速度
   * @default 120
   */
  speed?: number
  markerIcon?: string
  path?: Array<[number, number]>
  movePoint?: number
  onMove?: (value: number) => void
  onStop?: (value: number) => void
  onPause?: (value: number) => void
  /** 轨迹操作
   * @enum 0 无状态
   * @enum 1 播放
   * @enum 2 暂停
   * @enum 3 停止
   * @enum 4 恢复
   * @default 0
   */
  toolCode?: number
  [key: string]: any
}

export default class index extends PureComponent<PathProps> {

  constructor(props: PathProps) {
    super(props)

    this.map = props.__map__
    this.pathSimplifier = null
    this.path = props.path || []
    this.AMap = (window as any).AMap

    this.defaultSpeed = 120
    this.toolCode = 0

    if (props.type === 'amap' && (window as any).AMapUI) {
      (window as any).AMapUI.loadUI(['misc/PathSimplifier'], (e: any) => {

        if (e && e.supportCanvas) {
          this.selfPathSimplifier = e
          this.initPath()
        } else {
          console.log('不支持画布');
        }
      })
      if (this.props.onRef) {
        this.props.onRef(this)
      }
    } else if (props.type === 'baidu') {
      require('./util/baidu/lushu')
      this.initPath()
    }
  }

  map: any
  AMap: any
  navg: any
  pathSimplifier: any
  selfPathSimplifier: any
  path: Array<[number, number]> | []
  /** 默认播放速度 */
  defaultSpeed: number
  /** 操作code */
  toolCode: ToolCode

  componentWillUnmount() {
    if (this.navg && this.navg.off) {
      this.navg.off('move', this.onMove)
      this.navg.off('stop', this.onStop)
      this.navg.off('pause', this.onPause)
    }
    this.remove()
  }

  UNSAFE_componentWillReceiveProps(nextProps: PathProps) {
    if (nextProps.path && !lodash.isEqual(nextProps.path, this.props.path)) {
      this.updatePath(nextProps.path)
    }
    if (nextProps.toolCode !== this.props.toolCode) {
      switch (nextProps.toolCode) {
        case 1:
          this.start()
          break;
        case 2:
          this.pause()
          break;
        case 3:
          this.stop()
          break;
        case 4:
          this.resume()
          break;
      }
    }
    if (nextProps.speed !== this.props.speed) {
      this.updateSpeed(nextProps.speed)
    }
    if (nextProps.movePoint !== undefined && nextProps.movePoint !== this.props.movePoint) {
      this.moveToPoint(nextProps.movePoint)
    }
  }

  /** 初始化线路 */
  initPath() {


    if (this.props.type === 'amap') {
      this.pathSimplifier = new this.selfPathSimplifier({
        zIndex: this.props.zIndex || 100,
        map: this.map,
        getPath: (pathData: any) => {
          return pathData.path
        },
        getHoverTitle: () => false,
        renderOptions: {
          renderAllPointsIfNumberBelow: -1, //绘制路线节点，如不需要可设置为-1
          pathLineStyle: {
            strokeStyle: '#307CEC'
          }
        }
      })
      this.createAMapHistoryPath()
    } else if (this.props.type === 'baidu') {
      this.createBMapHistoryPath()
    }

  }

  async createAMapHistoryPath() {
    if (!this.path.length) return
    this.cleanPath()

    const paths = await pointConvert(this.path, this.props.type)

    this.pathSimplifier.setData([{ name: this.props.name || '线路', path: paths }])

    const markerIcon = this.props.markerIcon || 'https://webapi.amap.com/ui/1.0/ui/misc/PathSimplifier/examples/imgs/car.png'

    const navg = this.pathSimplifier.createPathNavigator(0, {
      speed: this.props.speed || this.defaultSpeed,
      pathNavigatorStyle: {
        width: 16,
        height: 32,
        //使用图片
        content: this.selfPathSimplifier.Render.Canvas.getImageContent(markerIcon),
        strokeStyle: null,
        fillStyle: null,
        pathLinePassedStyle: {
          strokeStyle: '#ff002d'
        }
      },

    })

    //保存巡航器的位置
    const cursor = navg.getCursor().clone()
    const status = navg.getNaviStatus()

    if (cursor.idx >= 0) {
      navg.moveToPoint(cursor.idx, cursor.tail);
    }

    if (status !== 'stop') {
      navg.start();
    }

    navg.on('move', this.onMove.bind(this))

    navg.on('stop', this.onStop.bind(this));

    navg.on('pause', this.onPause.bind(this));

    this.navg = navg

    this.pathSimplifier.setFitView(0)
    this.map.zoomOut()
  }

  async createBMapHistoryPath() {
    if (!this.path.length) return

    const paths = await pointConvert(this.path, this.props.type)

    const iconStr = this.props.markerIcon || 'https://webapi.amap.com/ui/1.0/ui/misc/PathSimplifier/examples/imgs/car.png';

    this.navg = new BMapLib.LuShu(
      this.map,
      paths,
      {
        landmarkPois: [],
        icon: toIcon({
          image: iconStr,
          imageOffset: [0, 0],
          imageSize: [22, 34],
          size: [25, 34],
        }, this.props.type),
        speed: this.props.speed || this.defaultSpeed,
        autoView: true,
        enableRotation: true,
        onMove: this.onMove.bind(this)
      });
  }

  /**清除轨迹 */
  cleanPath() {
    if (this.props.type === 'amap') {
      this.pathSimplifier.setData([{ name: '', path: [] }])
      this.pathSimplifier.clearPathNavigators()
      this.pathSimplifier.render()
    } else {
      if (this.navg) {
        this.navg.clean()
      }
    }
  }

  /** 开始 */
  start() {
    if (this.navg) {
      this.navg.start()
    }
  }

  /** 停止 */
  stop() {
    if (this.navg && this.navg.stop) {
      this.navg.stop()
      this.navg.moveToPoint(0, 0)
      this.updateSpeed(this.props.speed || this.defaultSpeed)
    }
  }

  /** 暂停 */
  pause() {
    if (this.navg && this.navg.pause) {
      this.navg.pause()
    }
  }

  /** 恢复 */
  resume() {
    if (this.props.type === 'baidu') {
      this.start()
    } else {
      if (this.navg) {
        this.navg.resume()
      }
    }
  }

  /** 设置速度 */
  updateSpeed(value?: number) {
    if (this.navg && value) {
      this.navg.setSpeed(value)
    }
  }

  /**
   * 更新轨迹数据
   * @param data 
   */
  updatePath(data: Array<[number, number]>) {
    if (this.pathSimplifier && data) {
      this.path = data
      this.cleanPath()
      this.initPath()
    }
    if (this.navg && this.props.type === 'baidu' && data) {
      this.path = data
      this.cleanPath()
      this.initPath()

    }
  }

  /** 移动到指定节点 */
  async moveToPoint(point: number) {

    if (this.navg) {
      this.navg.moveToPoint(point, 1)
      if (this.props.type === 'amap') {
        const paths = await pointConvert([this.path[point]], this.props.type)
        this.map.setCenter(paths[0])
      }
    }
  }

  /**
   * 移动监听
   */
  onMove(item: any) {
    if (this.navg && this.props.type === 'amap') {
      const index = this.navg.getCursor()
      const position = this.navg.getPosition()
      // 获取当前地图范围
      const bounds = this.map.getBounds()
      const southWest = bounds.getSouthWest() // 西南角坐标
      const northEast = bounds.getNorthEast() // 东北角坐标
      const lng1 = southWest.getLng()
      const lat1 = southWest.getLat()
      const lng2 = northEast.getLng()
      const lat2 = northEast.getLat()
      // 判断车辆是否超出地图范围
      if (!isInArea([position.getLng(), position.getLat()], [lng1, lat1], [lng2, lat2])) {
        // 设置地图中心点位为当前车辆位置
        this.map.setCenter(position)
      }
      if (this.props.onMove) {
        this.props.onMove(index)
      }
    } else if (this.props.type === 'baidu') {
      if (this.props.onMove) {
        this.props.onMove(item)
      }
    }
  }

  /**
   * 停止监听
   */
  onStop() {
    if (this.navg) {
      const index = this.navg.getCursor()
      if (this.props.onStop) {
        this.props.onStop(index)
      }
    }
  }

  /**
   * 暂停监听
   */
  onPause() {
    if (this.navg) {
      const index = this.navg.getCursor()
      if (this.props.onPause) {
        this.props.onPause(index)
      }
    }
  }

  remove() {
    if (this.pathSimplifier) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.pathSimplifier)
      } else if (this.props.type === 'amap') {
        this.pathSimplifier.clearPathNavigators()
        this.navg = null
        this.pathSimplifier.setData([])
      }
      this.pathSimplifier = null
    }
    if (this.navg) {
      this.navg.clean()
    }
  }

  render() {
    return null
  }
}
