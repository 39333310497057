/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-03 14:11:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-09 10:36:01
 */
import api from '.';
import axios from '../axios';

const MODULE = '/oauth'

export const login = <T>(payload: T) => axios({ url: `${api}${MODULE}/token`, data: payload, method: 'post' })