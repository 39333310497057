import React, { useState, useCallback } from 'react'
import { Table, Drawer, Info, SelectCode } from 'components'
import { ColumnsType } from 'components/table/interface'
import { InfoItems } from 'components/info'
import { Button, message } from 'antd'
import { useIntl } from 'react-intl'
import {
  getAlarmSupervisePage,
  getAlarmSuperviseInfo,
  updateAlarmSupervise,
} from 'server/web/supervise'
import { webAPIResponse } from 'server/web/index.globals'
import { positionStory, tableStore } from 'store';

interface PoliceDataTableProps { }

function PoliceDataTable(_props: PoliceDataTableProps) {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false)
  const [info, setInfo] = useState<any>({})
  const [handleValue, setHandleVale] = useState<string>('')
  const { formatMessage: f } = useIntl()

  const [columns] = useState<ColumnsType<webAPIResponse.alarmSupervise>>([
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 120,
    },
    {
      intlTitle: 'tx010002',
      dataIndex: 'depName',
      description: '车组',
      width: 160,
    },
    {
      intlTitle: 'tx010003',
      dataIndex: 'plateColor',
      description: '颜色',
      width: 100,
    },
    {
      intlTitle: 'tx020001',
      dataIndex: 'warnSrcName',
      description: '报警来源',
      width: 120,
    },
    {
      intlTitle: 'tx020002',
      dataIndex: 'warnTypeName',
      description: '报警类型',
      width: 120,
    },
    {
      intlTitle: 'tx030002',
      dataIndex: 'resultName',
      description: '处理状态',
      width: 120,
    },
    {
      intlTitle: 'tx040001',
      dataIndex: 'supervisionEndtime',
      description: '督办截止时间',
      width: 160,
    },
    {
      intlTitle: 'tx040002',
      dataIndex: 'supervisionLevelName',
      description: '督办级别',
      width: 140,
    },
    {
      intlTitle: 'tx040007',
      dataIndex: 'supervisor',
      description: '督办人',
      width: 140,
    },
    {
      intlTitle: 'tx050001',
      dataIndex: 'supervisorEmail',
      description: '电子邮件',
      width: 140,
    },
    {
      intlTitle: 'tx050002',
      dataIndex: 'supervisorTel',
      description: '联系电话',
      width: 140,
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      fixed: 'right',
      width: 100,
      description: '操作',
      render: (_value: any, record: any) => {
        return (
          <Button
            type="link"
            style={ { color: '#307CEC' } }
            onClick={ () => {
              infoClick(record)
            } }
          >
            {
              record.result === 0 || record.result === 3 ?
                f({ id: 'tx030001', description: '处理' }) :
                f({ id: 'tx000010', description: '查看' })
            }
          </Button>
        )
      },
    },
  ])

  const [infoItems] = useState<InfoItems[]>([
    {
      children: [
        { dataIndex: 'plateNo', intlTitle: 'tx010001', description: '车牌号' },
        { dataIndex: 'plateColor', intlTitle: 'tx010003', description: '颜色' },
        { dataIndex: 'supervicsionId', intlTitle: 'tx040006', description: '报警督办ID' },
        { dataIndex: 'warnSrcName', intlTitle: 'tx020001', description: '报警来源' },
        { dataIndex: 'warnTime', intlTitle: 'tx020003', description: '报警时间' },
        { dataIndex: 'supervisionEndtime', intlTitle: 'tx040001', description: '督办截止时间' },
        { dataIndex: 'supervisionLevelName', intlTitle: 'tx040002', description: '督办级别' },
        { dataIndex: 'supervisor', intlTitle: 'tx040007', description: '督办人' },
        { dataIndex: 'supervisorEmail', intlTitle: 'tx050001', description: '电子邮件' },
        { dataIndex: 'supervisorTel', intlTitle: 'tx050002', description: '联系电话' },
        {
          dataIndex: 'undefined',
          intlTitle: 'tx020037',
          description: '报警处理结果',
          render: (_value: any, record: any) => {
            return record.result === 0 || record.result === 3 ?
              <SelectCode
                code='DealStatus809'
                style={ { width: '100%' } }
                onSelect={ (value: string) => {
                  setHandleVale(value)
                } }
                onClear={ () => {
                  setHandleVale('')
                } }
              /> : record.resultName
          }
        },
      ],
    },
  ])

  /**
   * 查看报警消息
   * @param record
   */
  const infoClick = (record: any) => {
    setDrawerVisible(true)
    getAlarmSuperviseInfo({ id: record.id }).then(data => {
      if (data) {
        setInfo(data.data)
      }
    })
  }

  /** 处理报警结果 */
  const handleData = useCallback(() => {
    if ((info.result !== 0 && info.result !== 3) || handleValue === '') {
      setDrawerVisible(false)
    } else {
      setDrawerLoading(true)
      updateAlarmSupervise({
        id: info.id,
        result: handleValue,
      }).then(data => {
        setDrawerLoading(false)
        if (data) {
          setHandleVale('')
          setDrawerVisible(false)
          tableStore.queryTable('alarmSupervise')
          message.success(f({ id: 'tx030007', description: '处理成功' }))
        }
      })
    }
  }, [handleValue, info])

  return (
    <div className="tx-position-table police-table">
      <Drawer
        title={ f({ id: 'tx040008', description: '报警督办消息' }) }
        visible={ drawerVisible }
        loading={ drawerLoading }
        onOk={ handleData }
        getContainer={ document.querySelector('.tx-position') as HTMLElement }
        onClose={ () => {
          setDrawerVisible(false)
          setDrawerLoading(false)
        } }
        cancelVisible={ false }
        width={ 320 }
        bodyStyle={ {
          paddingTop: 12,
        } }
      >
        <Info titleWidth={ 98 } items={ infoItems } data={ info } />
      </Drawer>
      <div className="police-table-content">
        <Table
          tableName="alarmSupervise"

          columns={ columns }
          size="small"
          buttonGroup={ false }
          pollingTime={ positionStory.tablePollTime }
          axios={ {
            query: getAlarmSupervisePage,
          } }
        />
      </div>
    </div>
  )
}

export default PoliceDataTable
