/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-11-13 13:55:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-25 16:47:41
 */
import React, { memo } from 'react'
import { Button } from 'antd'
import { useIntl } from 'react-intl'
import { PlusOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons'
import { ExtraButtonGroupType } from '../interface'
import Import from './import'

interface ExtraButtonGroupProps {
  extraButtonValues: ExtraButtonGroupType
  add: Function
  editBatch: Function
  downloadTemplate: Function
  imports?: Function
  tableName: string
}

const ExtraButtonGroup = (props: ExtraButtonGroupProps) => {
  const {
    extraButtonValues: { showAdd, showEditBatch, showImport, showDownloadTemplate, customize },
    add,
    editBatch,
    imports,
    downloadTemplate,
    tableName,
  } = props
  const { formatMessage: f } = useIntl()

  return (
    <div className="extraButtonGroup">
      {customize?.beforeAdd}
      {showAdd && (
        <Button onClick={() => add()} icon={<PlusOutlined />}>
          {f({ id: 'tx000003', description: '新增' })}
        </Button>
      )}
      {customize?.afterAdd}
      {showEditBatch && (
        <Button onClick={() => editBatch()} icon={<FormOutlined />}>
          {f({ id: 'tx000021', description: '批量修改' })}
        </Button>
      )}
      {customize?.afterEditBatch}
      {showImport && <Import imports={imports} tableName={tableName} />}
      {customize?.afterImport}
      {showDownloadTemplate && (
        <Button onClick={() => downloadTemplate()} icon={<DownloadOutlined />}>
          {f({ id: 'tx000023', description: '模板下载' })}
        </Button>
      )}
      {customize?.afterDownloadTemplate}
    </div>
  )
}

export default memo(ExtraButtonGroup)
