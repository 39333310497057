/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-12-03 09:52:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-14 18:13:52
 */
import { observable, action } from 'mobx'
import { userAlarmSetting } from 'server/web/userAlarmSetting'
import { getLocal, setLocal } from 'util/comm'
import broadcastStory from './monitor/broadcast'
import positionStory from './monitor/position'


class UserSettingStore {
  @observable baseSetting: userAlarmSetting = {
    streamType: '0',
    videoDataType: '0'
  }

  /** 更新值
   *@param isUpdate 是否为更新, true则不更新positionStory中地图相关信息
   */
  @action
  updateSetting = (data: userAlarmSetting) => {
    this.baseSetting = data
    if (data.mapType) {
      positionStory.updateAllMapType(data.mapType)
      positionStory.updateMapType(data.mapType)
    }
    if (data.initZoomLevel) {
      positionStory.updateAllMapZoom(Number(data.initZoomLevel))
      positionStory.updateMapZoom(Number(data.initZoomLevel))
    }
    if (data.initLng && data.initLat) {
      positionStory.updateMapCenterValue(
        data.initLng ? Number(data.initLng) : 109.250399,
        data.initLat ? Number(data.initLat) : 34.344602
      )
      positionStory.updateAllMapCenterValue(
        data.initLng ? Number(data.initLng) : 109.250399,
        data.initLat ? Number(data.initLat) : 34.344602
      )
    }
    if (data.videoChannelNum) {
      broadcastStory.updateChannelsNumber(data.videoChannelNum ? Number(data.videoChannelNum) : 9)
    }
    if (data.maxAudioListeningTime) {
      broadcastStory.updateAutoCLoseTime(data.maxAudioListeningTime ? Number(data.maxAudioListeningTime) : 5)
    }
    // 关闭Modal
    if (!data.realTimeAlarmPopupEnabled) {
      positionStory.deleteTips(1)
    }
    if (!data.alarmVideoPopupEnabled) {
      positionStory.deleteTips(2)
    }
    if (!data.dangerVehiclePopupEnabled) {
      positionStory.deleteTips(3)
    }
    if (!data.upsCheckPopupEnabled) {
      positionStory.deleteTips(4)
    }

  }
}

const userSettingStore: UserSettingStore = new UserSettingStore()

export { UserSettingStore }
export default userSettingStore