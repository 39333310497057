/*
 * @Description: 车辆相关
 * @Author: shenkaiyao
 * @Date: 2020-08-25 16:42:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-17 15:59:51
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse } from './index.globals'

const MODULE = '/api/gps'

/** 历史GPS查询 */
export const getGpsHistoryPage = (payload: webAPIRequest.GetGpsPage) => axios.post<TableResponseType<webAPIResponse.GetGpsPage>>(`${api}${MODULE}/getGpsListByPage`, payload)

/** 导出历史GPS */
export const exportGpsHistoryPage = (payload: webAPIRequest.GetGpsPage) => axios.post<TableResponseType<any>>(`${api}${MODULE}/exportGpsinfoList`, payload)

/** 获取车辆分页数据 */
export const getVehiclesInfoPage = (payload: webAPIRequest.vehiclesInfo) => axios.post<webAPIResponse.vehiclesInfo[]>(`${api}${MODULE}/getGpsByVehicleIds`, payload)

/** 获取车辆定位信息弹窗 */
export const getVehiclesInfo = (payload: webAPIRequest.InfoWindow) => axios.post<webAPIResponse.vehiclesInfo>(`${api}${MODULE}/getGps`, payload)

/** 区域查车 */
export const findRegionalCar = (payload: webAPIRequest.findRegionalCar) => axios.post<TableResponseType<webAPIResponse.findRegionalCar>>(`${api}${MODULE}/findRegionalCar`, payload)