import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse,CompanyId } from './index.globals'

const MODULE = '/company/config'

/** 企业设置 根据ID获取企业参数 */
export const getCompany = (payload:CompanyId) => axios.post<webAPIResponse.platform>(`${api}${MODULE}/get`, payload)
/** 企业设置 保存企业设置参数 */
export const saveCompany = (payload:webAPIRequest.platform) => axios.post<boolean>(`${api}${MODULE}/save`, payload)
