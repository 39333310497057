/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-08-05 19:38:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-05 19:48:35
 */
export default [{
  id: '0',
  plateNo: '鲁RQ2598',
  simNo: '000000001916',
  fleetName: '销售测试',
  alarmSourceName: '高级驾驶辅助系统报警',
  alarmTypeName: '驾驶员异常报警',
  driverName: '张三',
  accessoryNum: '0',
  alarmTime: '2020-03-05',
  level: '0',
  location: '北京市西城区龙湖天街3栋7-5',
  carNoColor: '黄',
  carSpeed: '80',
  frontCarSpeed: '70',
  frontCarDistance: '36',
  fatigueDegree: '0',
  vehicleStatusName: '正常',
  alarmBz: '0',
  deviateType: '0',
  roadRecognitionMark: '0',
  roadRecognitionData: '0',
  tirePressureAlarmDetail: '0',
  longitude: '115.49',
  latitude: '35.69'
}]
