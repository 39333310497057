import React, { useEffect, useState, useRef } from 'react'
import isOrderAgain from 'util/isOrderAgain'
import { getCommandStatus, rollCall } from 'server/web/sendCommand'
import { useInterval } from 'react-use'
import { positionStory } from 'store';
import { useIntl } from 'react-intl'
import { useCallback } from 'react';

function RollCall() {

  const { formatMessage: f } = useIntl()

  const [statusTimer, setStatusTimer] = useState(false)
  const [commandId, setCommandId] = useState('')
  const [statusName, setStatusName] = useState('')

  const statusTime = useRef(0)

  useInterval(() => {
    statusTime.current += 1
    if (statusTime.current < 60) {
      queryCommandStatus()
    } else {
      setStatusName(f({ id: 'tx08_1', description: '请求超时' }))
      statusTime.current = 0
      setStatusTimer(false)
    }
  }, statusTimer ? 1000 : null)

  useEffect(() => {
    query()
    return () => {
      setStatusTimer(false)
    }
  }, [])

  /** 获取指令状态 */
  const queryCommandStatus = useCallback(async () => {
    const res = await getCommandStatus({ commandId })
    if (res) {
      const { status, statusName } = res.data
      if (!isOrderAgain(status)) {
        setStatusTimer(false)
        statusTime.current = 0
        if (status === 'Success') {
          positionStory.hideCallModal()
        }
      }
      setStatusName(statusName)
    }
  }, [commandId])

  const query = () => {
    if (positionStory.realTimeVehicleInfo) {
      rollCall({ vehicleId: positionStory.realTimeVehicleInfo.vehicleId }).then(result => {
        if (result) {
          setCommandId(result.data)
          setStatusTimer(true)
        }
      })
    }
  }

  return (
    <span> { statusName || f({ id: 'tx000227', description: '等待发送' }) } </span>
  )
}

export default RollCall
