import React, { memo, useEffect } from 'react'
import { Video } from 'components'
import { sendVideoControl } from 'server/video'
import moment from 'moment'
import { useIntl } from 'react-intl'

interface PlaybackVideoProps {
  simNo: string
  /** 通道号 */
  passageway: number
  /** 直播地址 */
  url: string
  /** 车牌号 */
  vehicleNo: string
  startTime: number
  endTime: number
  hasVideo: boolean
  hasAudio: boolean
  onDestroy: Function
}

function PlaybackVideo(props: PlaybackVideoProps) {
  const { formatMessage: f } = useIntl()
  const { simNo, passageway, url, vehicleNo, endTime, startTime, hasAudio, hasVideo, onDestroy } = props

  useEffect(() => {
    if (!url && !!simNo) {
      order(2, 0)
    }
  }, [url, simNo])
  /**
 *
 * @param controlType 回放控制： 0:开始回放; 1:暂停回放; 2:结束回放; 3:快进回放; 4:关键帧快退回放; 5:拖动回放; 6:关键帧播放
 * @param playBackTime 拖动回放位置,YY-MM-DD-HH-MM-SS，回放控制为5时，此字段有效
 * @param times 快进或快退方式，回放控制为3和4时，此字段内容有效，否则置0  0：无效；1：1倍；2：2倍；3：4倍；4：8倍；5：16倍
 */
  const order = async (controlType: number = 0, times: number = 0, playBackTime: string = '') => {
    const stopResult = await sendVideoControl({
      channelId: passageway,
      controlType: controlType,
      playBackTime: playBackTime,
      simNo: simNo,
      times: times,
    })
    return stopResult
  }

  // 处理视频播放倍速
  const handleTimes = (value: number) => {
    switch (value) {
      case 2:
        return 2
      case 4:
        return 3
      case 8:
        return 4
      case 16:
        return 5
      default:
        return 1
    }
  }

  /** 快退 */
  const goBack = (value: number) => {
    if (url) {
      order(4, handleTimes(value))
    }
  }

  /** 快进 */
  const fastForward = (value: number) => {
    if (url) {
      moment(handleTimes(value))
      order(3, handleTimes(value))
    }
  }

  /** 拖拽进度条 */
  const playbackTimeChange = (value: number) => {
    if (url) {
      order(5, 0, moment(startTime + value * 1000).format('YYYY-MM-DD HH:mm:ss'))
    }
  }

  /** 播放 */
  const onPlay = () => {
    if (url) {
      order(0, 0)
    }
  }

  /** 暂停 */
  const onStop = () => {
    if (url) {
      order(1, 0)
    }
  }
  /** 暂停 */
  const onPause = () => {
    if (url) {
      order(1, 0)
    }
  }

  /** 停止 */
  const _onDestroy = () => {
    if (url) {
      // order(2, 0)
      onDestroy()
    }
  }

  /** 播放完成 */
  const _onEnded = () => {
    order(2, 0)
  }
  return (
    <Video
      sim={ simNo }
      vehicleNo={ vehicleNo }
      hasVideo={ hasVideo }
      hasAudio={ hasAudio }
      playbackTime={ (endTime - startTime) / 1000 }
      onGoBack={ goBack }
      onFastForward={ fastForward }
      playbackTimeChange={ playbackTimeChange }
      onStop={ onStop }
      onPlay={ onPlay }
      onEnded={ _onEnded }
      onPause={ onPause }
      onDestroy={ _onDestroy }
      destroyClick={ _onDestroy }
      url={ url }
      passagewayIntl={ f({ id: 'tx070005', description: '通道' }) }
      passageway={ passageway }
      type="playback"
      mediaType='flv'
    />
  )
}

export default memo(PlaybackVideo)
