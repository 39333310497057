/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-03 19:58:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-13 15:18:03
 */
import { message } from 'antd';
import { getLangMessage } from 'util/comm';

/**
 * 根据传入的code统一处理失败的提示信息
 * @param code 传入的code
 */
const handleCode = (code: string | number, showMessage: boolean = true) => {
  if (showMessage) {
    const msg = getLangMessage({ id: `tx08${String(code)}`, defaultMessage: getLangMessage({ id: 'tx081024' }) })
    message.warning(msg)
  }

  // 未经授权，重新登录
  if (Number(code) === 401) {
    console.log('window.location :>> ', window.location);
    window.location.replace('/')
  }
}

export default handleCode