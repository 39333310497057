import React, { useState, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Info } from 'components'
import { InfoItems } from 'components/info';
import { observer } from 'mobx-react'
import './inforWindowContent.less'
import { webAPIResponse } from 'server/web/index.globals';
import { userStore } from 'store'
import { toJS } from 'mobx';
import Lnglats from 'util/lnglats'

interface inforWindowContentProps {
  id?: string
  onClose?: () => void
  info: webAPIResponse.vehiclesInfo | null
  /** 是否展示操作栏
   * @default true
   */
  showTool?: boolean
}

function InforWindowContent(props: inforWindowContentProps) {
  const { info } = props
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    setVisible(!userStore.isConcrete)
  }, [userStore.isConcrete])

  useEffect(() => {
    setVisible(props.showTool === false ? false : true)
  }, [props.showTool])

  const [item] = useState<InfoItems[]>([{
    children: [
      { dataIndex: 'driverName', intlTitle: 'tx110004', description: '驾驶员', render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'depName', intlTitle: 'tx010002', description: '车组', render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'simNo', intlTitle: 'tx060001', description: 'SIM卡号', render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'velocity', intlTitle: 'tx010004', description: '速度', render: (value: any) => <span>{ value } km/h</span> },
      { dataIndex: 'directionName', intlTitle: 'tx010006', description: '方向', render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'altitude', intlTitle: 'tx060009', description: '海拔', render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'mileage', intlTitle: 'tx060005', description: '里程', render: (value: any) => <span>{ value } km</span> },
      { dataIndex: 'localTime', intlTitle: 'tx000102', description: '时间', row: 2, render: (value: any) => <span>{ value }</span> },
      // { dataIndex: 'location', intlTitle: 'tx000106', description: '地址', row: 3, render: (value: any) => <span>{ value }</span> },
      {
        dataIndex: '_', intlTitle: 'tx000106', description: '地址', row: 3,
        render: (_value: any, record: any) => {
          const records = toJS(record)
          const recordStr = JSON.stringify(records)
          return <Lnglats lnglat={recordStr && recordStr != '{}' ? [records?.longitude, records?.latitude] : [0.0, 0.0]} />
        }
      },
      { dataIndex: 'statusName', intlTitle: 'tx010101', description: '状态', row: 3, render: (value: any) => <span>{ value }</span> },
      { dataIndex: 'alarmStatusName', intlTitle: 'tx020027', description: '报警', row: 3, render: (value: any) => <span style={ { color: '#f5222d' } }>{ value }</span> },
    ]
  }])

  const close = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <div className='tx-infowindow'>
      <div className='infowindow-header'>
        <div className='header-title'>
          { info ? info.plateNo : '渝C110110' }
          <span>
            { info ? info.plateColorName || info.plateColor : '' }
          </span>
        </div>
        <div>
          <CloseOutlined onClick={ close } />
        </div>
      </div>
      <div className='infowindow-content'>
        <Info row={ 3 } items={ item } data={ info || {} } />
      </div>
    </div>
  )
}

export default observer(InforWindowContent)
