/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-12 14:24:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-07 13:49:38
 */
import AlarmHandling from './drawer';

/** 报警状态 */
const alarmStatus = {
  /** 未处理 */
  unprocessed: '0',
  /** 监听 */
  listen: '1',
  /** 拍照 */
  takePicturn: '2',
  /** 文本下发 */
  sendText: '3',
  /** 报警解除 */
  clear: '4',
}

export { alarmStatus }
export default AlarmHandling