/*
 * @Description: 实时视频
 * @Author: 谢永红
 * @Date: 2020-10-16 09:57:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-29 17:24:40
 */
import {action, observable} from 'mobx'
import {videoItem, videoType} from 'components/video/interface'
import {webAPIResponse} from 'server/web/index.globals'
import {message} from 'antd'
import {closeComAllVideo, getComVideoAjax} from 'server/video'
import {getBroadCastUrl} from 'server/web/vehicleTree'
import {getLangMessage} from 'util/comm'
import {cloneDeep} from 'lodash-es'
import {userSettingStore} from 'store'


export interface vehicleInfo {
  plateNo: string
  simNo: string
  passway: Array<number>
  videoChannelDesc: Array<string>
  vehicleId?: string
}

interface LngLat {
  lng: number
  lat: number
}

class Broadcast {
  /** 视频数组 */
  @observable videoList: Array<videoItem> = []
  /** 当前通道数 */
  @observable channelsNumber: number = 9
  /** 车辆详情-用来展示infoWindow */
  @observable vehicleInfo: webAPIResponse.vehiclesInfo | null = null
  /** 是否显示infoWindow */
  @observable infowindowVisible: boolean = false
  /** mapCenter */
  @observable mapCenter?: LngLat = undefined
  /** 自动关闭时间 */
  @observable autoCloseTime: number = 5

  /** 双击请求视频 */
  @action
  doubleClickGetVideo(data: Omit<vehicleInfo, 'videoChannelDesc'>) {
    if (data.passway && data.passway.length) {
      // 该车辆通道
      const cloneChannels = cloneDeep(data.passway)
      const channels = cloneChannels.length >= this.channelsNumber ? cloneChannels.splice(0, this.channelsNumber) : cloneChannels
      this.getVideoListAjax(data.simNo, channels, data.plateNo)
    } else {
      message.warning(getLangMessage({id: 'tx000218', description: '该车辆无视频通道'}))
    }
  }

  /** videoMonitor中双击摄像头根据摄像头id播放单个视频 */
  async addThirdPartyVideoToVideoListByCameraId(cameraId: string, cameraName: string) {
    console.log(this.videoList)
    const result = await getBroadCastUrl({cameraId})
    if (result && result?.data) {
      this.videoList = [{
        type: videoType.thirdPartyVideo,
        url: result.data,
        cameraId: cameraId,
        cameraName: cameraName,
        passageway: 0,
        sim: '',
        vehicleNo: '',
        sessionId: ''
      }]
    }
  }

  /**
   * 双击请求视频前关闭之前的视频
   * 这个方法不再需要了，因为在不是每次换车都全部关闭视频，视频能混到一起放的时候，
   * 根据这个store的videoList来关已经没有意义了。
   * */
  closeVideo() {
    if (this.videoList.length) {
      const noNullList = this.videoList.filter(item => !!item && item.url)
      if (noNullList.length) {
        const closeVideo: any = {}
        noNullList.forEach(item => {
          if (item?.sessionId! in closeVideo) {
            closeVideo[item?.sessionId!].push(item?.passageway)
          } else {
            closeVideo[item?.sessionId!] = [item?.passageway]
          }
        })
        closeComAllVideo(closeVideo)
        this.cleanVideoList()
      }
    }
  }

  /** 获取车辆直播视频地址 */
  @action
  async getVideoListAjax(simNo: string, channelNums: number[], plateNo: string) {
    const result = await getComVideoAjax({
      simNo: simNo,
      streamType: userSettingStore.baseSetting.streamType!,
      mediaType: userSettingStore.baseSetting.videoDataType!,
      channelNums: channelNums
    })

    if (result) {
      this.videoList = result.data.videoList.map((item) => {
        return {
          sim: simNo,
          url: item.url.includes("?")
            ? item.url + "&t=" + new Date().valueOf()
            : item.url + "?t=" + new Date().valueOf(),
          passageway: item.channelId,
          vehicleNo: plateNo,
          sessionId: result.data.sessionId,
          type: videoType.txVideo
        }
      })
      console.log(this.videoList)
    }
  }

  /** 清除视频 */
  @action
  cleanVideoList() {
    this.videoList = []
  }

  /** 更新通道号 */
  @action
  updateChannelsNumber(value: number) {
    this.channelsNumber = value
  }

  /** 更新车辆详情 */
  @action
  updateVehicleInfo(data: webAPIResponse.vehiclesInfo | null) {
    this.vehicleInfo = data

    this.infowindowVisible = !!(data && data.longitude && data.latitude)
    this.updateMapCenter(data)
  }

  /** 更新infoWindow显示 */
  @action
  updateInfoWindowVisible(status: boolean) {
    this.infowindowVisible = status
  }

  /** 更新地图中心 */
  @action
  updateMapCenter(data: webAPIResponse.vehiclesInfo | null) {
    this.mapCenter = data && data.longitude ? {
      lng: data.longitude,
      lat: data.latitude
    } : undefined
  }

  /** 更新视频自动关闭时间 */
  @action
  updateAutoCLoseTime(time: number) {
    this.autoCloseTime = time
  }
}

const broadcastStory: Broadcast = new Broadcast()

export {Broadcast}
export default broadcastStory
