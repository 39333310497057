import React, { memo } from 'react'
import { InfoTabsProps } from './interface'
import { DrawerTabs as Tabs, Info } from 'components'
import { isEqual } from 'lodash-es'

const { TabPane } = Tabs

const InfoTabs = (props: InfoTabsProps) => {
  const { data, loading, tabsProps, config } = props
  return (
    <Tabs {...tabsProps}>
      {config?.map(item => {
        const { title, key, infoItems, infoProps, style, beforeInfo, afterInfo, ...extra } = item
        return (
          <TabPane style={{ height: 'inherit', ...style }} tab={title} key={key} {...extra}>
            {beforeInfo && beforeInfo}
            <Info loading={loading} items={infoItems} data={data} {...infoProps} />
            {afterInfo && afterInfo}
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default memo(InfoTabs, isEqual)
