import React, { useEffect } from 'react'
import useSetState from 'util/useSetState'
import { Layout, Tabs } from 'antd'
import { Sider } from 'components'
import { RoutePropsConfig } from 'router/config'
import { useIntl } from 'react-intl'
import { useLocation, Link, useHistory } from 'react-router-dom'
import SelfRoute from 'router'
import './index.less'

const { TabPane } = Tabs

interface MyState {
  routes: RoutePropsConfig[]
  selectKey: string
}

function Index(props: any) {
  const { formatMessage: f } = useIntl()
  const location = useLocation()
  const history = useHistory()

  const [state, setState] = useSetState<MyState>({
    routes: [],
    selectKey: '',
  })

  useEffect(() => {
    const path = location.pathname
    const pathName = path.split('/')
    const fName = pathName[2]
    if (pathName && fName === props.name) {
      const cName = pathName.pop()
      addRoute(cName!)
    }
  }, [location])

  /**
   * 新增tabs
   * @param name
   */
  const addRoute = (name: string) => {
    // 是否打开过tabs
    const isTabsRoute = state.routes.find((route: RoutePropsConfig) => route.name === name)

    if (isTabsRoute) {
      // 切换到该tabs
      changeTabs(name)
    } else {
      // 新增
      const children = handChildrenRoute(props.children)
      const route = children.find((route: any) => route.uiRoute === name)
      if (route) {
        setState({
          routes: [...state.routes, route],
          selectKey: route.name,
        })
      }
    }
  }

  /**
   * 切换tabs
   * @param targetKey
   */
  const changeTabs = (targetKey: string) => {
    setState({
      selectKey: targetKey,
    })
  }

  /**
   * 删除tabs
   * @param targetKey
   */
  const onEdit = (targetKey: any) => {
    // 删除tabs
    const arr = state.routes.filter((route: RoutePropsConfig) => route.name !== targetKey)
    setState({
      routes: arr,
    })

    if (targetKey === state.selectKey) {
      // 获取当前tabs的下标
      const index = state.routes.findIndex((route: RoutePropsConfig) => route.name === targetKey)
      // 是否是下标0开始
      const changeKey = index > 0 ? arr[index - 1].path : arr[0].path
      if (changeKey) {
        history.push(changeKey)
      }
      // changeTabs(changeKey)
    }
  }

  /** 处理子路由页面 */
  const handChildrenRoute = (data: any[]) => {
    if (!data) return []
    let RouteArr: any[] = []
    data.forEach(item => {
      if (item.children) {
        RouteArr = [...RouteArr, ...item.children]
      } else {
        RouteArr = [...RouteArr, item]
      }
    })
    return RouteArr
  }
  return (
    <Layout className="view">
      <Sider className="view-menu" />
      <Layout className="view-content tx-content">
        <div className="view-tabs">
          <Tabs
            hideAdd
            type="editable-card"
            // onChange={ changeTabs }
            activeKey={state.selectKey}
            onEdit={onEdit}
            className="view-tabs-content"
            size="small"
          >
            {state.routes.map((route: any) => {
              return (
                <TabPane
                  key={route.uiRoute}
                  tab={
                    <span className="tabs-item-title">
                      <Link to={route.path}>{f({ id: route.intlTitle })}</Link>
                    </span>
                  }
                  closable={state.routes.length !== 1}
                ></TabPane>
              )
            })}
          </Tabs>
          {props.children ? SelfRoute(handChildrenRoute(props.children)) : null}
        </div>
      </Layout>
    </Layout>
  )
}

export default React.memo(Index)
