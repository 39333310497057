/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-08-17 16:30:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-17 17:31:00
 */
import { includes, without } from 'lodash-es'

interface actionsProps {
  type: string,
  tips: Array<number>
  key: number
}

interface MyState {
  tips: Array<number>
}

const reducer = (state: MyState, action: actionsProps) => {
  const { type, key } = action
  switch (type) {
    case 'delete_tips':
      return { ...state, tips: without(state.tips, key) }
    case 'add_tips':
      let arr = state.tips
      if (includes(state.tips, key)) {
        arr = without(state.tips, key)
      } else {
        arr.push(key)
      }
      return { ...state, tips: [...arr] }
    default:
      return state
  }
}

export default reducer