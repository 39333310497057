import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

function Index() {
  const history = useHistory()

  return (
    <div style={ { height: '100vh' } }>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={ <Button type="primary" onClick={ () => {
          history.push('/index/home')
        } }>Back Home</Button> }
      />
    </div>
  )
}

export default Index
