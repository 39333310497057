/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-11-18 16:12:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-24 15:35:35
 */
import { AuthorizationType } from 'components/auth/interface';
import { LocationState } from './interface';
import { useLocation } from 'react-router-dom';
import { isArray, isString, isUndefined } from 'lodash-es';

function useAuthorization(): AuthorizationType[]
function useAuthorization(str: AuthorizationType): boolean
function useAuthorization(arr: AuthorizationType[]): boolean[]

function useAuthorization(props?: AuthorizationType | AuthorizationType[]) {
  const { state: { authorization } } = useLocation<LocationState>()
  if (!isUndefined(props)) {
    if (isString(props)) {
      return authorization.includes(props)
    }
    if (isArray(props)) {
      return props.map(item => authorization.includes(item))
    }
    throw new Error('权限处理错误，请检测传入参数是否有误')
  } else {
    return authorization
  }
}

export default useAuthorization;
