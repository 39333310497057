/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-12 10:15:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 16:04:38
 */
import { isString, isUndefined } from 'lodash-es';
import { useCallback } from 'react';
import { Props, Tools } from './interface';
import classnames from 'classnames';

const useHandleRowClassName = ({ props: { rowClassName, rowSelectedKey }, state: { selectedRow } }: Tools) => {
  return useCallback<Exclude<Props['rowClassName'], string | undefined>>(
    (record, index, indent) => {
      let className = index % 2 === 0 ? 'ant-table-odd-row' : 'ant-table-even-row'
      if (!isUndefined(selectedRow)) {
        className = classnames(
          className,
          { 'ant-table-row-selected': rowSelectedKey ? selectedRow === record[rowSelectedKey] : selectedRow === index }
        )
      }
      const propsRowClassName = !isUndefined(rowClassName) ? isString(rowClassName) ? rowClassName : rowClassName(record, index, indent) : undefined
      return classnames(className, propsRowClassName)
    },
    [rowClassName, rowSelectedKey, selectedRow]
  )
}

export default useHandleRowClassName