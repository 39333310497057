import { PureComponent } from 'react'
import { PolyLineProps } from './interface';
import { pointConvert, handleOptions, getPolyline, setView } from './util/comm'

const defaultOptions: Array<string> = [
  'bubble',
  'cursor',
  'geodesic',
  'isOutline',
  'borderWeight',
  'outlineColor',
  'path',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'strokeStyle',
  'strokeDasharray',
  'lineJoin',
  'lineCap',
  'extData',
  'showDir',
  'zIndex'
]

export default class Polyline extends PureComponent<PolyLineProps> {

  map: any
  polyline: any
  polyEditor: any

  constructor(props: PolyLineProps) {
    super(props)
    this.map = props.__map__
    this.createPolyLine()
  }

  componentWillReceiveProps(nextProps: PolyLineProps) {
    if (nextProps.isEdit !== this.props.isEdit) {
      this.changeEdit(nextProps.isEdit)
    }
  }

  componentWillUnmount() {
    this.remove()
  }

  async createPolyLine() {
    if (this.props.path && this.props.path.length) {
      const options = handleOptions(this.props, defaultOptions)
      // 批量转换
      const paths = await pointConvert(options.path, this.props.type)

      options.path = paths
      options.map = this.map

      this.polyline = getPolyline(this.props.type, options)

      if (this.props.type === 'baidu') {
        this.map.addOverlay(this.polyline)
      }

      if (this.props.isView) {
        setView(this.props.type, this.map, this.polyline)
      }

      // this.polyEditor = new (window as any).AMap.PolyEditor(this.map, this.polyline)


      // this.polyEditor.on('addnode', (e: any) => {
      //   if (this.props.onAdjust) {
      //     this.props.onAdjust(e)
      //   }
      // })

      // this.polyEditor.on('adjust', (e: any) => {
      //   if (this.props.onAddnode) {
      //     this.props.onAddnode(e)
      //   }
      // })

      // this.polyEditor.on('removenode', (e: any) => {
      //   if (this.props.onRemovenode) {
      //     this.props.onRemovenode(e)
      //   }
      // })

      // this.polyEditor.on('end', (type: any, e: any) => {
      //   if (this.props.onEnd) {
      //     this.props.onEnd(type, e)
      //   }
      // })

      // if (this.props.isEdit) {
      //   this.changeEdit(true)
      // }
    }
  }

  changeEdit(status?: boolean) {
    if (this.polyEditor) {
      if (status) {
        this.polyEditor.open()
      } else {
        this.polyEditor.close()
      }
    }
  }

  remove() {
    if (this.polyline) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.polyline)
      } else if (this.props.type === 'amap') {
        this.map.remove(this.polyline)
      }
      this.polyline = null
    }
  }

  render() {
    return null
  }
}
