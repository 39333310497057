import React, { useContext, useEffect, useCallback } from 'react'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import enUS from 'antd/es/locale/en_US'
import { Locale } from 'antd/es/locale-provider'
import useSetState from 'util/useSetState'
import { MobXProviderContext, observer } from 'mobx-react'
import { languageType } from 'locale'
import moment from 'moment'
import { changeProjectName } from 'util/comm'
import 'moment/locale/zh-cn'

interface MyState {
  locale: Locale
  lang: string
}

// eslint-disable-next-line no-template-curly-in-string
const typeTemplate = (str: string) => '${label}不是一个有效的' + str
const validateMessages = {
  types: {
    string: typeTemplate('字符'),
    number: typeTemplate('数字'),
    date: typeTemplate('日期'),
  },
}

const IntlRC = observer((props: any) => {
  useEffect(() => {
    document.title = changeProjectName().loginName
  }, [])

  const {
    intlStore: { lang, intl },
  } = useContext(MobXProviderContext)

  const [state, setState] = useSetState<MyState>({
    locale: lang === languageType.zh ? zhCN : enUS,
    lang,
  })

  /**
   * 更新语言
   * @param value
   */
  const updateLocale = useCallback(
    (value: string) => {
      let defaultLocale: any = zhCN
      switch (value) {
        case languageType.en:
          moment.locale('en')
          defaultLocale = enUS
          break
        default:
          moment.locale('zh-cn')
          break
      }
      setState({
        lang: value,
        locale: defaultLocale,
      })
    },
    [setState],
  )

  useEffect(() => {
    updateLocale(lang)
  }, [lang, updateLocale])

  const locale = {
    [languageType.zh]: 'zh',
    [languageType.en]: 'en',
  }

  return (
    <ConfigProvider
      locale={state.locale}
      form={lang === languageType.zh ? { validateMessages } : undefined}
    >
      <IntlProvider locale={locale[state.lang]} messages={intl}>
        {props.children}
      </IntlProvider>
    </ConfigProvider>
  )
})

export default IntlRC
