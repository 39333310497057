import React from 'react'
import { Echarts } from 'components';
import { monitorStore } from 'store';
import { useObserver, observer } from 'mobx-react'
import echarts from 'echarts/lib/echarts'
import { toJS } from 'mobx'

function RealSpeedDataCharts() {

  return useObserver(() =>
    <div style={ { width: '100%', height: '100%' } }>
      <Echarts
        grid={ {
          left: '3%',
          right: '2%',
          top: '5%',
          bottom: '17%',
          containLabel: true
        } }
        tooltip={ {
          trigger: 'axis'
        } }
        dataZoom={ [
          { startValue: monitorStore.realSpeedData ? toJS(monitorStore.realSpeedData.xAxis)[0] : '' },
          { type: 'inside' }
        ] }
        xAxis={ {
          data: monitorStore.realSpeedData ? toJS(monitorStore.realSpeedData.xAxis) || [] : [],
          axisTick: {
            show: false
          }
        } }
        yAxis={ {
          axisTick: {
            show: false
          }
        } }

        series={ [
          {
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: `rgba(36, 184, 43,1)` },
                  { offset: 1, color: `rgba(36, 184, 43,0)` }
                ]
              )
            },
            lineStyle: {
              color: 'rgb(36, 184, 43, .8)'
            },
            data: monitorStore.realSpeedData ? toJS(monitorStore.realSpeedData.data) || [] : []
          }
        ] as any[] }
        color={ ['rgba(36, 184, 43,1)'] }
      />
    </div>
  )

}

export default observer(RealSpeedDataCharts)
