import React, { useCallback, forwardRef, ForwardRefRenderFunction, memo } from 'react'
import { Spin, TreeSelect as AntdTreeSelect } from 'antd'
import { useUpdateProps, useHandleHighLightSearch, useHandleAxios, useDefaultValue } from './hook'
import { TreeSelectProps, TreeSelectRef, StateType } from './interface'
import { Tools } from './hook/interface'
import { useSetState } from 'react-use'
import { isUndefined } from 'lodash-es'
import './index.less'

const TreeSelect: ForwardRefRenderFunction<TreeSelectRef, TreeSelectProps> = (_props, ref) => {
  /** 统一处理处理默认值 */
  const props = useDefaultValue(_props)

  const {
    treeData: propsTreeData,
    loading: propsLoading,
    searchValue: propsSearchValue,
    query,
    payload,
    handleResult,
    queryTime,
    onSearch,
    onFocus,
    ...extra
  } = props

  const [state, setState] = useSetState<StateType>({
    treeData: propsTreeData,
    searchTreeData: undefined,
    searchValue: propsSearchValue,
    loading: false,
  })

  const tools: Tools = { props, state, setState }

  const { treeData, searchTreeData, searchValue, loading } = state

  const finallyLoading = propsLoading || loading
  const finallyTreeData = searchTreeData || treeData

  /** 封装组件内部的请求，并抛出请求接口 */
  const queryData = useHandleAxios(tools)

  /** 封装组件的高亮搜索功能 */
  const treeNodeFilterProp = useHandleHighLightSearch(tools)

  const handleSearch = useCallback<NonNullable<TreeSelectProps['onSearch']>>(
    value => {
      onSearch && onSearch(value)
      isUndefined(propsSearchValue) && setState({ searchValue: value })
    },
    [onSearch, propsSearchValue, setState],
  )

  const handleFocus = useCallback<NonNullable<TreeSelectProps['onFocus']>>(
    e => {
      onFocus && onFocus(e)
      isUndefined(treeData) && queryTime === 'onFocus' && queryData()
    },
    [onFocus, queryData, queryTime, treeData],
  )

  const dropdownRender = useCallback<NonNullable<TreeSelectProps['dropdownRender']>>(
    menu => <Spin spinning={finallyLoading || false}>{menu}</Spin>,
    [finallyLoading],
  )

  /** 统一处理：当props改变时更新state的值 */
  useUpdateProps(tools)

  return (
    <AntdTreeSelect
      ref={ref}
      {...extra}
      treeData={finallyTreeData}
      loading={finallyLoading}
      searchValue={searchValue}
      onSearch={handleSearch}
      onFocus={handleFocus}
      treeNodeFilterProp={treeNodeFilterProp}
      dropdownRender={dropdownRender}
    />
  )
}

export default memo(forwardRef(TreeSelect))
