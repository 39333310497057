import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import HForm, { MyFormProps as HFormProps, MyFormItemProps as HMyFormItemProps } from './baseForm'
import { useIntl } from 'react-intl'

export interface MyFormItemProps extends HMyFormItemProps {
  intlLable?: string
  /** 描述 */
  description?: string
}

export interface FormProps extends Omit<HFormProps<any>, 'items'> {
  items: MyFormItemProps[]
}

function Form(props: FormProps, ref: any) {
  const { items, ...extra } = props
  const { formatMessage: f } = useIntl()
  const form: any = useRef()

  useImperativeHandle(ref, () => form.current)
  return (
    <HForm
      ref={ form }
      items={ items.map(item => {
        const { label, intlLable } = item
        if (!label && intlLable) {
          item.label = f({ id: intlLable })
          /** 防止intlLable属性传递到底层dom报错 */
          delete item.intlLable
        }
        return item
      }) }
      { ...extra }
    />
  )
}

export default forwardRef(Form)
