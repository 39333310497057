import React, { useEffect, useState, useCallback } from 'react'
import { OlMap, OlVector, OlMarker, OlPathSimplifier, OlInfoWindow } from 'components'
import PlayTool from './playTool'
import { toJS } from 'mobx';
import { monitorStore, positionStory, trajectoryStory } from 'store';
import { observer } from 'mobx-react';
import useSetState from 'util/useSetState'
import PoliceWindowContent from '../components/trainfowindowPolice';
import InforWindowContent from '../components/traInfowindow';
import { FeatureProps } from 'components/ol/vector';
import { PathToolType } from 'components/ol/pathSimplifier';

const defalutSpeed: number = 3000000

interface MyState {
  speed: number
  progress: number
}

function Tmap() {

  const [state, setState] = useSetState<MyState>({
    speed: defalutSpeed,
    progress: 0
  })

  useEffect(() => {
    pathStop()
    setState({
      speed: defalutSpeed,
      progress: 0
    })
  }, [monitorStore.trajectPlayPositionData])

  const pathPause = () => {
    trajectoryStory.updateToolCode(PathToolType.pause)
  }

  const pathPlay = useCallback(() => {
    if (trajectoryStory.toolCode === PathToolType.pause) {
      trajectoryStory.updateInfoWindowVisible(false)
      trajectoryStory.updateToolCode(PathToolType.recovery)
    } else {
      trajectoryStory.updateToolCode(PathToolType.start)
    }
  }, [trajectoryStory.toolCode])

  const pathStop = () => {
    trajectoryStory.updateToolCode(PathToolType.stop)
  }

  const pathChange = (value: number) => {
    setState({ speed: defalutSpeed * value })
  }

  const pathMove = useCallback((value: any) => {
    if (trajectoryStory.toolCode === PathToolType.start
      || trajectoryStory.toolCode === PathToolType.recovery) {
      trajectoryStory.updateInfoWindowVisible(false)
    }
    const idx: number = value.idx
    const maxLength: number = monitorStore.trajectPlayPositionData.length
    const oldProgressNumber = idx * (100 / maxLength)
    // 获取总进度
    let progress = idx === maxLength - 1 && value.tail === 0 ? 100 : Number((oldProgressNumber + ((100 / maxLength) * value.tail)).toFixed(2))

    if (idx === 0 && value.tail === 0) {
      progress = 0
    }

    trajectoryStory.updateIdx(idx)
    setState({
      progress: progress
    })
  }, [monitorStore.trajectPlayPositionData])

  return (
    <div className='tx-trajectory-map'>
      {
        monitorStore.trajectPlayPositionData.length ?
          <PlayTool
            onPause={ pathPause }
            onPlay={ pathPlay }
            onStop={ pathStop }
            onSpeedChange={ pathChange }
            speed={ state.progress }
          /> : null
      }
      <OlMap
        type={ positionStory.mapType }
        updateSize={ monitorStore.tabsKey }
      >
        {/* 轨迹分段 */ }
        {
          monitorStore.trajectPositionData.length && !monitorStore.trajectPlayPositionData.length ?
            <OlVector
              features={
                monitorStore.trajectPositionData.map((item): FeatureProps => {
                  return {
                    type: 'route',
                    bounds: item.data,
                  }
                })
              }
              strokeStyle={ {
                color: '#389e0d',
                lineDash: [0, 0]
              } }
              showMarker={ false }
              showLabel={ false }
              view={ true }
            />
            : null
        }

        {
          trajectoryStory.policeMarkers.map(item => <OlMarker
            key={ item.id }
            position={ [item.longitude, item.latitude] }
            offset={ [2, -10] }
            icon={ {
              size: [28, 42],
            } }
            onClick={ () => {
              trajectoryStory.updateInfoWindowType(2)
              trajectoryStory.updateInfoWindow({
                ...trajectoryStory.vehicleInfo,
                ...item
              })
              trajectoryStory.updateInfoWindowVisible(true)
            } }
          />)
        }
        {
          monitorStore.trajectPlayPositionData.length ?
            <OlPathSimplifier
              speed={ state.speed }
              path={ toJS(monitorStore.trajectPlayPositionData) }
              onMove={ pathMove }
              tool={ trajectoryStory.toolCode }
              movePoint={ trajectoryStory.jumpIdx }
              zIndex={ 50 }
              markerIcon={ {
                anchor: [0.5, 0.5]
              } }
              startIcon={ {
                src: '//amap.com/assets/img/poi-marker.png',
                offset: [10, 0],
                size: [60, 75],
                scale: 0.5
              } }
              endIcon={ {
                src: '//amap.com/assets/img/poi-marker.png',
                offset: [190, 0],
                size: [60, 75],
                scale: 0.5
              } }
            /> : null
        }
        <OlInfoWindow
          visible={ trajectoryStory.infoWindowVisible }
          position={ trajectoryStory.infoWindow ?
            [trajectoryStory.infoWindow.longitude, trajectoryStory.infoWindow.latitude]
            : undefined
          }
          offset={ [0, -21] }
          autoMove={ false }
        >
          {
            trajectoryStory.infoWindowType === 2 ?
              <PoliceWindowContent
                info={ trajectoryStory.infoWindow }
                showTool={ false }
                onClose={ () => {

                  trajectoryStory.updateInfoWindowVisible(false)

                } }
              /> :
              <InforWindowContent
                info={ trajectoryStory.infoWindow }
                showTool={ false }
                onClose={ () => {

                  trajectoryStory.updateInfoWindowVisible(false)
                } }
              />
          }
        </OlInfoWindow>
        {/* {
          trajectoryStory.infoWindowVisible ?
            : null
        } */}
      </OlMap>
    </div >
  )
}

export default React.memo(observer(Tmap))
