/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-11 17:47:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 18:59:25
 */
import { isUndefined } from 'lodash-es';
import { Props, Tools } from './interface';
import { VList } from 'virtuallist-antd'

const useCheckVirtual = ({ props: { virtualScroll, scroll, dataSource, components } }: Tools): Props['components'] => {
  if (!isUndefined(components)) return components
  /** 是否报错 */
  const isError = virtualScroll === true && (isUndefined(scroll) || isUndefined(scroll.y))

  if (isError) throw new Error('开启table虚拟滚动时，scroll.y为必传值')

  const isVirtualAble = !!(!isError && virtualScroll && dataSource && dataSource.length > 0)

  return isVirtualAble ? VList({ height: scroll!.y! }) : undefined
}

export default useCheckVirtual