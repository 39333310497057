/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-22 16:16:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 11:45:29
 */
import api from './index'
import axios from '../axios'
import { webAPIResponse } from './index.globals'
import {TableResponseType} from 'components/table/interface'

const MODULE = '/api/gps/statistic'

/** 历史GPS查询 */
export const getGpsStatistic = () => axios.post<webAPIResponse.gpsStatisticCount>(`${api}${MODULE}/count`, {})

/** 获取轨迹完整率统计 */
export const getGpsLoss = (payload: any) => axios.post<TableResponseType<webAPIResponse.getTrackIntegrity>>(`${api}${MODULE}/gpsloss`, payload)

/** 获取轨迹合格率统计 */
export const getGpsPassRate = (payload: any) => axios.post<TableResponseType<webAPIResponse.getAcceptability>>(`${api}${MODULE}/gpsPassRate`, payload)
