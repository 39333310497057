import React, { useState } from 'react'
import { Select } from 'antd'
import { inputTips } from 'server/amap'
import { useIntl } from 'react-intl'
import './index.less'
import { debounce } from 'lodash-es';

interface TipProps {
  className?: string
  onChange?: (posotion: string) => void
  [key: string]: any
}

const Index = (props: TipProps) => {

  const { onChange, ...extra } = props

  const { formatMessage: f } = useIntl()
  const [options, setOptions] = useState<Array<any>>([])

  const onSearch = async (value: string) => {
    if (value) {
      const result = await inputTips({
        key: '783a3822fc658f381698d0a6dd7f7779',
        keywords: value,
      })
      if (result && result.tips) {
        // 获取有经纬度的值
        const hasPosition = result.tips.filter(item => item.location && item.location.length)
        setOptions(hasPosition)
      }
    }
  }

  const onSelect = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  // console.log(options);
  return (
    <Select
      showSearch
      virtual={ false }
      placeholder={ f({ id: 'tx000220' }) }
      onSearch={ debounce(onSearch, 500) }
      onSelect={ onSelect }
      filterOption={ false }
      { ...extra }
    >
      {
        options.map((item, index: number) => {
          return <Select.Option value={ item.location } key={ index } >{ item.name }</Select.Option>
        })
      }
    </Select>
  )
}


export default Index