/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-11 17:03:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-11 17:09:22
 */
import { RequiredKeys } from 'util/interface';
import { TableProps } from '../interface';

type Keys = 'showSerialNumberCol' | 'titleOfSerialNumberCol'

const useDefaultValue = (props: TableProps<any>): RequiredKeys<TableProps<any>, Keys> => {

  const {
    showSerialNumberCol = true,
    titleOfSerialNumberCol = '序号',
    ...extra
  } = props

  return { showSerialNumberCol, titleOfSerialNumberCol, ...extra }

}

export default useDefaultValue