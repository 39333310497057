/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-09-04 09:59:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 16:40:02
 */
import { FC } from 'react'
import { observable, action } from 'mobx';
import { ModalProps } from 'view/monitor/rightCommand/interface'
import { webAPIResponse } from 'server/web/index.globals';
import { getVehiclesInfo } from 'server/web/gps'
import positionStory from './position'
import broadcastStory from './broadcast'
import trajectoryStory from './trajectory';

interface trajectPositionDataProps {
  id: string
  data: Array<number[]>
}
interface vehicleInfo {
  plateNo: string
  simNo: string
  passway: Array<number>
  videoChannelDesc?: Array<string>
  vehicleId?: string
  begTime?: string
  endTime?: string
}

export interface TableDataProps {
  /** 报警类型 */
  alarmTypeName: string
  /** 车牌号 */
  plateNo: string
  /** 车组名称 */
  depName: string
  /** textColor */
  textColor: string
}

class Monitor {
  /** 实时报警信息气泡数据  */
  @observable realTimeModal: TableDataProps[] = []
  /** 危险车辆提醒 */
  @observable dangerousVehiclesModal: any[] = []
  /** 上级查岗 */
  @observable superiorModal: any[] = []
  /** 历史轨迹-缓存播放数据 */
  @observable trajectPositionData: trajectPositionDataProps[] = []
  /** 历史轨迹-单个播放数据 */
  @observable trajectPlayPositionData: Array<number[]> | [] = []
  /** 当前选择车辆 */
  @observable vehicleInfo: vehicleInfo = {} as any
  /** 轨迹回放实时速度 */
  @observable realSpeedData: any = { xAxis: [], data: [] }
  /** 用于切换标签关闭视频 */
  @observable closeVideoNumber: number = 0

  /** 视频回放车辆详情 - 用于infoWindow */
  @observable playbackVehicleInfo: webAPIResponse.vehiclesInfo | null = null

  /** 右键指令Modal */
  @observable rightModal: FC<ModalProps> | null = null
  /** 右键指令Modal Visible */
  @observable rightModalVisible: boolean = false
  /** 右键指令信息 */
  @observable rightModalRecord: any = {}

  /** 最后一个视频位置 */
  lastVideoIndex = 0

  /** 更新实时报警信息数据 */
  @action
  updateRealTimeModal = (data: TableDataProps) => {
    const MAX = 20
    if (this.realTimeModal.length >= MAX) {
      this.realTimeModal.pop()
    }
    this.realTimeModal.unshift(data)

  }

  /** 清除实时报警信息数据 */
  @action
  cleanRealTimeModal = () => {
    this.realTimeModal = []
  }

  /** 更新危险车辆提醒数据 */
  @action
  updateDangerousVehicleModal = (data: webAPIResponse.vehiclescoresDanger) => {
    const MAX = 20
    if (this.dangerousVehiclesModal.length >= MAX) {
      this.dangerousVehiclesModal.pop()
    }
    this.dangerousVehiclesModal.unshift(data)

  }

  /** 更新危险车辆提醒数据 */
  @action
  updateDangerousVehicleModallist = (data: webAPIResponse.vehiclescoresDanger[]) => {
    this.dangerousVehiclesModal = data.splice(0, 20)
  }

  /** 更新上级查岗数据 */
  @action
  updateSuperiorModal = (data: any) => {
    const MAX = 20
    if (this.superiorModal.length >= MAX) {
      this.superiorModal.pop()
    }
    this.superiorModal.unshift(data)

  }

  /** 更新回放位置数据 */
  @action
  updateTrajectPositionData = (data: any) => {
    this.trajectPositionData = data
  }

  /** 清空回放位置数据 */
  @action
  cleanTrajectPositionData() {
    this.trajectPositionData = []
    this.trajectPlayPositionData = []
    trajectoryStory.updateMarker([])
    trajectoryStory.cleanPath()
    console.log('cleanTrajectPositionData')
    trajectoryStory.updateInfoWindowVisible(false)
  }

  /** 单个添加回放位置数据 */
  @action
  addTrajectPositionData = (data: any) => {
    this.trajectPositionData.push(data)
  }

  /** 更新当前选择车辆信息 */
  @action
  updateVechileInfo = (data: vehicleInfo) => {
    this.vehicleInfo = data
  }

  /**更新历史回放-实时速度数据 */
  @action
  updateRealSpeedData = (data: webAPIResponse.trackSpeed[]) => {
    this.realSpeedData = {
      xAxis: data.map((item: webAPIResponse.trackSpeed) => item.sendTime),
      data: data.map((item: webAPIResponse.trackSpeed) => item.velocity)
    }
  }
  /**清除历史回放-实时速度数据 */
  @action
  cleanRealSpeedData = () => {
    this.realSpeedData = {
      xAxis: [],
      data: []
    }
  }

  /** 查找单个回放位置数据 */
  @action
  findTrajectPlayPositionData = (id: string) => {
    const _ = this.trajectPositionData.find((item: any) => item.id === id)
    this.trajectPlayPositionData = _ ? _.data : []
  }

  /** 更新单个回放位置数据 */
  @action
  updateTrajectPlayPositionData(data: Array<[number, number]>) {
    this.trajectPlayPositionData = data
  }

  /** 切换关闭视频 */
  @action
  updateCloseVideoNumber = () => {
    this.closeVideoNumber = new Date().valueOf()
  }

  /** 获取单个车辆详情 */
  @action
  getVehiclesInfoAjax = async (id: string, tabsKey: string, show: boolean = false) => {
    if (id) {
      const result = await getVehiclesInfo({ vehicleId: id })
      if (result && result.data) {
        if (result.data.latitude && result.data.longitude) {
          if (tabsKey === '1') {
            positionStory.updateRealTimeVehicleInfo(result.data, show)
            positionStory.updateMapCenter(result.data)
          } else if (tabsKey === '3') {
            broadcastStory.updateVehicleInfo(result.data)
          } else if (tabsKey === '2') {
            trajectoryStory.updateVehicleInfo(result.data)
          } else {
            this.updatePlaybackVehicleInfo(result.data)
          }
        }
      }
    }
  }

  /** 更新视频回放车辆详情 - infowindow */
  @action
  updatePlaybackVehicleInfo = (data: webAPIResponse.vehiclesInfo | null) => {
    this.playbackVehicleInfo = data
  }

  /** 更新右键指令Modal */
  @action
  updateRightModal(modal: FC<ModalProps> | null) {
    this.rightModal = modal
  }
  /** 显示右键指令modal */
  @action
  showRightModal() {
    this.rightModalVisible = true
  }
  /** 隐藏右键指令modal */
  @action
  hideRightModal() {
    this.rightModalVisible = false
  }
  /** 更新右键指令信息 */
  @action
  updateRightModalInfo(record: any) {
    this.rightModalRecord = record
  }
}

const monitorStore: Monitor = new Monitor()

export { Monitor }
export default monitorStore
