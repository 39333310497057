/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-03 14:23:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-29 16:21:20
 */
// import baseUrl from '../baseUrl';
import proxy from '../proxy';

const URL = proxy + '/auth'

export default URL