/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:19:19
 */
import React, { FC, useContext } from 'react'
import { Badge, Tooltip } from 'antd'
import classnames from 'classnames';
import { useIntl } from 'react-intl'
import { useObserver, observer } from 'mobx-react';
import { positionStory, userSettingStore } from 'store';

const TipBtns: FC<any> = React.memo(observer(() => {


  const { formatMessage: f } = useIntl()

  const tipBtnClick = (value: number) => {
    positionStory.updateTips(value)
  }

  const checkSelect = (key: number, keys: Array<number>) => {
    return keys && keys.some((item: number) => item === key)
  }

  return useObserver(() =>
    <div className={ classnames('position-tip-btns') } >
      {
        userSettingStore.baseSetting.realTimeAlarmPopupEnabled ?
          <Tooltip placement="left" title={ f({ id: 'tx020038', description: '实时报警信息' }) }>
            <div className={ classnames('tip-btn', 'realtime-btn', { 'active': checkSelect(1, positionStory.tipsArr) }) } onClick={ () => { tipBtnClick(1) } }></div>
          </Tooltip>
          : null
      }
      {
        userSettingStore.baseSetting.alarmVideoPopupEnabled ?
          <Tooltip placement="left" title={ f({ id: 'tx020013', description: '报警视频' }) }>
            <div className={ classnames('tip-btn', 'abnormal-btn', { 'active': checkSelect(2, positionStory.tipsArr) }) } onClick={ () => { tipBtnClick(2) } }></div>
          </Tooltip>
          : null
      }
      {
        userSettingStore.baseSetting.dangerVehiclePopupEnabled ?
          <Tooltip placement="left" title={ f({ id: 'tx000136', description: '危险车辆提醒' }) }>
            <div className={ classnames('tip-btn', 'vehicle-btn', { 'active': checkSelect(3, positionStory.tipsArr) }) } onClick={ () => { tipBtnClick(3) } }></div>
          </Tooltip>
          : null
      }
      {
        userSettingStore.baseSetting.upsCheckPopupEnabled ?
          <Tooltip placement="left" title={ f({ id: 'tx000135', description: '上级查岗' }) }>
            <div className={ classnames('tip-btn', 'superior-btn', { 'active': checkSelect(4, positionStory.tipsArr) }) } onClick={ () => { tipBtnClick(4) } }></div>
          </Tooltip>
          : null
      }
    </div>
  )
}), () => true)

export default TipBtns
