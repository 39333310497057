import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Input, Button, Form as AntdForm } from 'antd'
import { SelectCode, Form } from 'components'
import { MyFormItemProps } from 'components/form'
import { webAPIResponse, webAPIRequest } from 'server/web/index.globals'
import { sendListen, getCommandStatus } from 'server/web/sendCommand'
import { pleaseEnter as e, pleaseSelect as s } from 'util/comm'
import isOrderAgain from 'util/isOrderAgain'

interface MonitorType {
  record?: any
  result?: webAPIResponse.AlarmInfo
}

const Monitor = (props: MonitorType) => {
  const { record, result } = props
  const { formatMessage: f } = useIntl()
  const [form] = AntdForm.useForm()
  const formRef = useRef(form)
  const [statusName, setStatusName] = useState('')
  const [loading, setLoading] = useState(false)
  const [clear, setClear] = useState<Function>()
  const [isAlive, setIsAlive] = useState({ value: true })

  useEffect(() => () => clear && clear(), [clear])

  useEffect(
    () => () =>
      setIsAlive(data => {
        data.value = false
        return data
      }),
    [],
  )

  const getStatus = (commandId: string) => {
    /** 是否清空定时器(命令执行成功或者页面关闭) */
    let flag = false
    /** 请求次数 */
    let number = 0
    /** 轮询规则，次数：时间 */
    const rules: { [key: number]: number } = {
      0: 1000,
      5: 2000,
      10: 3000,
    }
    let timer: NodeJS.Timeout | undefined = undefined
    let cTimer: NodeJS.Timeout | undefined = undefined

    const clearTimer = () => {
      setStatusName('')
      setLoading(false)
      flag = true
      timer && clearInterval(timer)
      cTimer && clearTimeout(cTimer)
      setClear(undefined)
    }

    const query = async () => {
      number++
      if (typeof rules[number] === 'number') {
        timer && clearInterval(timer)
        timer = setInterval(query, rules[number])
      }
      const res = await getCommandStatus({ commandId })
      if (res) {
        // 如果定时器已清空，则return，什么也不做
        if (flag) return
        const { status, statusName } = res.data
        setStatusName(statusName)
        if (!isOrderAgain(status)) {
          clearTimer()
          setStatusName(statusName)
        }
      }
    }

    query()
    timer = setInterval(query, 1000)
    cTimer = setTimeout(() => {
      clearTimer()
      setStatusName(f({ id: 'tx08_1', description: '请求超时' }))
    }, 30000)

    return clearTimer
  }

  const send = async () => {
    const formData = await formRef.current.validateFields()
    const payload: webAPIRequest.SendListen = {
      alarmIds: record?.id || result?.id,
      vehicleId: result?.vehicleId || record?.vehicleId,
      ...formData,
    }
    setLoading(true)
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    const res = await sendListen(payload)
    if (res && isAlive.value) {
      const commandId = res.data
      const cl = getStatus(commandId)
      setClear(() => cl)
    } else {
      setLoading(false)
      setStatusName('')
    }
  }

  const formItems: MyFormItemProps[] = [
    {
      name: 'listenType',
      rules: [
        {
          required: true,
          message: s({ id: 'tx120003' }),
        },
      ],
      dom: <SelectCode placeholder={ s({ id: 'tx120003' }) } code="ListenTerminal" />,
      label: f({ id: 'tx120003', description: '监听类型' }),
    },
    {
      name: 'phoneNo',
      rules: [
        {
          required: true,
          message: e({ id: 'tx120004' }),
        },
      ],
      dom: <Input placeholder={ e({ id: 'tx120004' }) } />,
      label: f({ id: 'tx120004', description: '监听电话' }),
    },
  ]

  return (
    <div className="monitor">
      <div className="title">{ f({ id: 'tx120002', description: '监听信息' }) }</div>
      <div className="monitorInfo">
        <Form ref={ formRef } items={ formItems } row={ 2 } gutter={ 16 } />
        <Button size="small" type="primary" onClick={ send } loading={ loading }>
          { f({ id: 'tx020020', description: '发送命令' }) }
        </Button>
        { statusName && <span style={ { marginLeft: '0.12rem' } }>{ statusName }</span> }
      </div>
    </div>
  )
}

export default Monitor
