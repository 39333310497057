import React, {useEffect, useState, useMemo} from 'react'
import {Layout, Menu, Dropdown, Badge} from 'antd'
import {DownOutlined, SettingOutlined, MenuFoldOutlined, BellOutlined} from '@ant-design/icons'
import {useHistory, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {menuStore, platformParamsStore, userStore} from 'store'
import {useObserver} from 'mobx-react'
import classnames from 'classnames'
import ChangePassword from './changePassword'
import AlarmSetting from './alarmSetting'
import UserCenter from './userCenter'
import './index.less'
import RenewList from "./renewList"
import {changeProjectName, createDownLoadUrl} from 'util/comm'
import {getExpiredServiceNum} from 'server/web/renew'

const {Header} = Layout
const {Item} = Menu

const hiddenMenuRouter = ['home', 'monitor']

function Index() {
  let location = useLocation()
  const [visible, setVisible] = useState(false)
  const [userCenter, setUserCenter] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false)
  const [renewVisible, setRenewVisible] = useState(false)
  const [selectKeys, setSelectKeys] = useState<string[]>([''])
  const [renewBadge, setRenewBadge] = useState<number>(0)
  const {formatMessage: f} = useIntl()
  const history = useHistory()

  useEffect(() => {
    const pathname: string = location.pathname
    const pathNames = pathname.split('/')

    // 控制左侧收起按钮
    if (hiddenMenuRouter.some((name: string) => name === pathname)) {
      menuStore.updateHiddenMenuIcon()
    } else {
      menuStore.updateShowMenuIcon()
    }
    if (pathNames) {
      setSelectKeys([pathNames[2]])
    }
  }, [location])

  useEffect(() => {
    async function getRenewBadge() {
      const res = await getExpiredServiceNum()
      if (res) {
        setRenewBadge(res.data)
      }
    }
    getRenewBadge()
  }, [])

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item>
          <span onClick={() => setUserCenter(true)}>
            {f({id: 'tx000051', description: '用户中心'})}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={() => setVisible(true)}>
            {f({id: 'tx000027', description: '修改密码'})}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={signOut}>{f({id: 'tx000028', description: '退出登录'})}</span>
        </Menu.Item>
      </Menu>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  function signOut() {
    window.location.href = '/login'
  }

  return useObserver(() => (
    <Header className="tx-header">
      <div className="logo">
        {/*这里注掉事是因为createDownLoadUrl这个function已经不能用了，后端接口没了或者改掉了*/}
        {/*<img*/}
        {/*  src={*/}
        {/*    platformParamsStore.params.systemLogo ?*/}
        {/*      createDownLoadUrl(platformParamsStore.params.systemLogo) : "/images/header/logo.png"*/}
        {/*  }*/}
        {/*  alt=""/>*/}
        <img src={"/images/header/logo.png"} alt=""/>
        <span className="logo-title">
          {
            changeProjectName(platformParamsStore.params.systemTitle).logoName
          }
        </span>
        <div
          className={classnames('menu-click-icon', {
            active: menuStore.status,
            hidden: menuStore.isHidden,
          })}
          onClick={() => {
            menuStore.updateStatus()
          }}
        >
          <MenuFoldOutlined/>
        </div>
      </div>
      <div className="header-right">
        {menuStore.menu && menuStore.menu.length > 0 ? (
          <Menu theme="dark" mode="horizontal" selectedKeys={selectKeys}>
            {menuStore.menu.map((menu: any) => {
              return (
                <Item key={menu.name}>
                  {menu.isTabs ? (
                    <a className="tx-header-menu-link" href={menu.redirect} target="_blank">
                      <div className="header-item">
                        <img src={`/images/header/${menu.icon}.png`} alt=""/>
                        {menu.intlTitle ? f({id: menu.intlTitle}) : ''}
                      </div>
                    </a>
                  ) : (
                    <span className="tx-header-menu-link" onClick={() => {

                      if (!window.location.href.includes(menu.path)) {
                        history.push(menu.path)
                      }
                    }}>
                        <div className="header-item">
                          <img src={`/images/header/${menu.icon}.png`} alt=""/>
                          {menu.intlTitle ? f({id: menu.intlTitle}) : ''}
                        </div>
                      </span>
                  )}
                </Item>
              )
            })}
          </Menu>
        ) : null}

        <div className="user">
          <span>
            <Badge count={renewBadge} size="small" offset={[-10, 0]}>
              <BellOutlined className="header-setting" onClick={() => setRenewVisible(true)}/>
            </Badge>
          </span>
          <SettingOutlined className="header-setting" onClick={() => setSettingVisible(true)}/>

          {/* <span>
            <Badge count={1}>
              
            </Badge>
          </span> */}

          <Dropdown overlay={menu}>
            <div className="header-user-menu">
              <span className="header-username ellipsis">
                {userStore.userInfo?.name || 'username'}
              </span>
              <DownOutlined/>
            </div>
          </Dropdown>

          <RenewList visible={renewVisible} setVisible={setRenewVisible}/>
          <UserCenter visible={userCenter} setVisible={setUserCenter}/>
          <ChangePassword visible={visible} setVisible={setVisible}/>
          <AlarmSetting visible={settingVisible} setVisible={setSettingVisible}/>
        </div>
      </div>
    </Header>
  ))
}

export default Index
