/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-28 15:01:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-29 14:04:25
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse, DriverId, Id, VehicleId } from './index.globals'

const MODULE = '/driver'

export interface VehicleDriver extends webAPIResponse.DriverPage {
  /** 是否是主驾驶 1主驾驶 0不是 */
  mainDriver: number
}

/** 获取司机分页筛选数据 */
export const getDriverPage = (payload: webAPIRequest.DriverPage) => axios.post<TableResponseType<webAPIResponse.DriverPage>>(`${api}${MODULE}/assembly`, payload)

/** 管理中心 信息管理 司机信息 分页查询 */
export const queryDriverPage = (payload: webAPIRequest.QueryDriverPage) => axios.post<TableResponseType<webAPIResponse.QueryDriverPage>>(`${api}${MODULE}/findDriverByPage`, payload)

/** 管理中心 信息管理 司机信息 根据司机id查看司机信息 */
export const queryDriverInfo = (payload: DriverId) => axios.post<webAPIResponse.QueryDriverPage>(`${api}${MODULE}/findDriverById`, payload)

/** 管理中心 信息管理 司机信息 新增 */
export const addDriver = (payload: webAPIRequest.AddDriver) => axios.post<boolean>(`${api}${MODULE}/addOrUpdateDriver`, payload)

/** 管理中心 信息管理 司机信息 修改 */
export const updateDriver = (payload: webAPIRequest.UpdateDriver) => axios.post<boolean>(`${api}${MODULE}/addOrUpdateDriver`, payload)

/** 管理中心 信息管理 司机信息 假删除 */
export const deleteDriver = (payload: DriverId) => axios.post<boolean>(`${api}${MODULE}/delete`, payload)

/** 司机信息 导出 */
export const exportDriver = (payload: webAPIRequest.QueryDriverPage) => axios.post<any>(`${api}${MODULE}/exportDriver`, payload)

/** 管理中心 信息管理 司机信息 获得司机图片 */
export const getDriverImg = (payload: DriverId) => axios.post<webAPIResponse.DriverImg[]>(`${api}${MODULE}/getDriverImg`, payload)

/** 管理中心 信息管理 司机信息 上传司机图片 */
export const uploadDriverImg = (payload: webAPIRequest.UploadDriverImg) => axios.post<boolean>(`${api}${MODULE}/uploadDriverImg`, payload)

/** 管理中心 信息管理 司机信息 删除司机图片 */
export const deleteDriverImg = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/delDriverImg`, payload)

/** 根据车辆id查司机 */
export const findDriverByVehicleId = (payload: VehicleId) => axios.post<VehicleDriver[]>(`${api}${MODULE}/findDriverByVehicleId`, payload)

/** 车辆中司机的实时数据 */
export const getDriverRealTimeInfo = (payload: VehicleId) => axios.post<webAPIResponse.DriverRealTimeInfo>(`${api}${MODULE}/real/time/info`, payload)