import React, { FC, useState, useEffect } from 'react'
import { Modal, Form } from 'components'
import { InputNumber, Form as AntdForm } from 'antd'
import { ModalProps } from '../interface'
import { useIntl } from 'react-intl'
import { sendLocationTracking, LocationTrackingType } from 'server/web/sendCommand'
import { useAxios, usePollingCommandStatus } from 'util/hook'
import { MyFormItemProps } from 'components/form'
import { handleLayout, pleaseEnter as e } from 'util/comm'

const LocationTracking: FC<ModalProps> = props => {
  const { record, ...extra } = props
  const { formatMessage: f } = useIntl()
  const [form] = AntdForm.useForm()
  const [statusName, setStatusName] = useState('')
  const [sendLoading, setSendLoading] = useState(false)

  const [, sendCommand] = useAxios({
    axios: sendLocationTracking,
    defaultStart: false,
  })

  const [{ data: commandStatus }, pollingCommandStatus] = usePollingCommandStatus()

  useEffect(() => {
    setStatusName(commandStatus?.statusName || '')
  }, [commandStatus])

  const config: MyFormItemProps[] = [
    {
      name: 'interval',
      intlLable: 'tx370001',
      description: '位置上报时间间隔(秒)',
      dom: (
        <InputNumber precision={0} placeholder={e({ id: 'tx370001' })} style={{ width: '70%' }} />
      ),
      rules: [{ required: true }],
      initialValue: 10,
    },
    {
      name: 'timeSpan',
      intlLable: 'tx370002',
      description: '跟踪有效期(秒)',
      dom: (
        <InputNumber precision={0} placeholder={e({ id: 'tx370002' })} style={{ width: '70%' }} />
      ),
      rules: [{ required: true }],
      initialValue: 120,
    },
  ]

  const handleOk = async () => {
    const formData = (await form.validateFields()) as LocationTrackingType
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    setSendLoading(true)
    try {
      const commandId = await sendCommand({
        payload: { ...formData, vehicleId: record!.vehicleId },
      })
      await pollingCommandStatus(commandId)
    } finally {
      setSendLoading(false)
    }
  }

  return (
    <Modal
      {...extra}
      title={f({ id: 'tx000014', description: '位置追踪' })}
      width={700}
      beforeConfirm={<span>{statusName}</span>}
      okText={f({ id: 'tx000012', description: '发送' })}
      confirmLoading={sendLoading}
      onOk={handleOk}
    >
      <Form items={config} form={form} {...handleLayout(3)} />
    </Modal>
  )
}

export default LocationTracking
