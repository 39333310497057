import React, { FC, memo } from 'react'
import { Modal, Button, Space } from 'antd'
import { ModalProps } from './interface'
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import './index.less'

const TX_Modal: FC<ModalProps> = props => {
  const {
    className,
    footer,
    title,
    intlTitle,
    confirmLoading,
    confirmVisible = true,
    cancelVisible = true,
    onOk,
    okText,
    cancelText,
    onCancel,
    beforeConfirm,
    ...extra
  } = props
  const { formatMessage: f } = useIntl()
  return (
    <Modal
      footer={
        footer === false || footer === null ? (
          false
        ) : (
          <Space size="large">
            {beforeConfirm}
            {confirmVisible && (
              <Button type="primary" onClick={onOk} loading={confirmLoading}>
                {okText || f({ id: 'tx000001', description: '确定' })}
              </Button>
            )}
            {cancelVisible && (
              <Button className={confirmVisible ? 'cancel' : undefined} onClick={onCancel}>
                {cancelText || f({ id: 'tx000002', description: '取消' })}
              </Button>
            )}
          </Space>
        )
      }
      title={title || (intlTitle ? f({ id: intlTitle }) : 'title')}
      className={classnames(
        {
          'tx-modal': true,
        },
        className,
      )}
      onCancel={onCancel}
      {...extra}
    />
  )
}

export default memo(TX_Modal)
