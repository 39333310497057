import React from 'react'
import { Table as AntdTable } from 'antd'
import {
  useDefaultValue,
  useUpdateProps,
  useCheckVirtual,
  useHandleColumns,
  useHandleRowClassName,
  useHandleOnRowEvents,
} from './hook'
import { TableProps, StateType } from './interface'
import { Tools } from './hook/interface'
import { useSetState } from 'react-use'
import classnames from 'classnames'
import './tablePro.less'

const Table = <T extends {}>(_props: TableProps<T>) => {
  const props = useDefaultValue(_props)

  const { className, bordered, showSerialNumberCol, titleOfSerialNumberCol, ...extra } = props

  const [state, setState] = useSetState<StateType>({ selectedRow: undefined })

  const tools: Tools = { props, state, setState }

  /**
   * 统一处理columns：
   * 1：序号列
   * 2：超过宽度自动省略和Tooltip提示
   */
  const columns = useHandleColumns(tools)

  /** 处理table的斑马线以及选中行的className */
  const rowClassName = useHandleRowClassName(tools)

  /**
   * 处理onRow事件中的点击行的选中态
   * @depend 依赖于useHandleRowClassName处理的className
   */
  const onRow = useHandleOnRowEvents(tools)

  /** 是否能开启虚拟滚动 */
  const components = useCheckVirtual(tools)

  useUpdateProps(tools)

  return (
    <AntdTable<T>
      {...extra}
      className={classnames(className, 'tx-table', { 'tx-table-base-no-bordered': !bordered })}
      bordered={bordered}
      columns={columns}
      rowClassName={rowClassName}
      components={components}
      onRow={onRow}
    />
  )
}

export default Table
