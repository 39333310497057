import React, {useState} from 'react'
import {Form as AntdForm, message, Radio} from "antd";
import {Form, Modal} from "components";
import {getLangMessage as g} from "util/comm";
import {MyFormItemProps} from "components/form";
import {useIntl} from "react-intl";
import {addOrReduceService, addOrReduceServiceRequest} from 'server/web/renew'
import {tableStore} from "../../store";

const RenewListAdd = (props: any) => {
  const {visible, setVisible, vehicles} = props
  const [loading, setLoading] = useState(false)
  const {formatMessage: f} = useIntl()

  const [costCoin, setCostCoin] = useState(0)

  const [form] = AntdForm.useForm()

  const formItems: MyFormItemProps[] = [
    {
      dom: <div>{vehicles[0]?.companyName || ''}</div>,
      label: g({id: 'tx18050617', description: '续时企业'}),
    },
    {
      label: g({id: 'tx18050626', description: '续时车辆数'}),
      dom: <div>{vehicles.length}</div>
    },
    {
      name: 'updateNum',
      label: g({id: 'tx1805068', description: '续时时长'}),
      dom: (
        <Radio.Group onChange={(e) => {
          const vehiclesNum = vehicles.length
          setCostCoin(e.target.value * vehiclesNum)
        }}>
          <Radio value={3}>3个月</Radio>
          <Radio value={6}>6个月</Radio>
          <Radio value={12}>1年</Radio>
          <Radio value={36}>3年</Radio>
          <Radio value={60}>5年</Radio>
        </Radio.Group>
      ),
    },
    {
      name: 'changeType',
      label: g({id: 'tx18050627', description: '支付方式'}),
      dom: (
        <Radio.Group>
          <Radio value={1}>续时币支付</Radio>
          <Radio disabled value={3}>微信支付</Radio>
          <Radio disabled value={2}>支付宝支付</Radio>
        </Radio.Group>
      ),
    },
    {
      label: g({id: 'tx18050628', description: '共需支付'}),
      dom: <div>{costCoin}续时币</div>
    },
  ]

  const handleCancel = () => {
    setVisible(false)
    setCostCoin(0)
    form.resetFields()
  }

  const handleOk = async () => {
    let formData = await form.validateFields()
    setLoading(true)

    formData = Object.assign(formData, {
      companyId: vehicles[0].companyId,
      vehicleIds: vehicles.map((d: any) => d.vehicleId),
      updateUnit: 'month'
    })
    // 表单后处理 end
    const res = await addOrReduceService(formData as addOrReduceServiceRequest)
    setLoading(false)
    if (res) {
      switch (res.code) {
        case 10116:
          message.info(f({id: 'tx18050630', description: '账户余额不足'}))
          break
        case 10117:
          message.info(f({id: 'tx18050631', description: '不能给本企业充值'}))
          break
        default:
          handleCancel()
          message.success(f({id: 'tx000024', description: '操作成功'}))
      }
    }
  }

  return (
    <Modal
      title={f({id: 'tx18050621', description: '选择续时时间'})}
      visible={visible}
      width={560}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      centered={true}
    >
      <Form form={form} items={formItems} labelCol={{span: 5}} wrapperCol={{span: 16}}/>
    </Modal>
  )
}

export default RenewListAdd