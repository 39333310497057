import { mapConfig } from './interface';


const AMAP_Config: mapConfig = {
    path: 'webapi.amap.com/maps',
    v: '1.4.15',
    key: 'b6dd11d75342de8f53a8f6e288f3a689',
    callback: 'amap_init'
}

const BMAP_Config: mapConfig = {
    path: 'api.map.baidu.com/api',
    v: '2.0',
    key: 'Suy9jHNBmWqHv1C9RP5qSS1p00LKQMBg',
    callback: 'bmap_init'
}

interface ApiConfig extends Omit<mapConfig, 'callback'> {
    type: string
    amapUI?: boolean
    callbackName?: string
}

const AMapTool = ['AMap.PolyEditor', 'AMap.MouseTool', 'AMap.ToolBar']

export default class APILoding {
    constructor(options: ApiConfig) {
        const { type, amapUI, v, key, ...extra } = options
        this.type = type
        this.amapUI = amapUI

        if (type === 'baidu') {

            this.config = { ...BMAP_Config, ...extra }
        } else if (type === 'amap') {

            this.config = { ...AMAP_Config, ...extra }
        }

        if (v) {
            this.config.v = v
        }

        if (key) {
            this.config.key = key
        }

        this.amapuiInited = false
        this.protocol = window.location.protocol
        if (this.protocol.indexOf(':') === -1) {
            this.protocol += ':'
        }
    }

    type: string
    config: mapConfig
    amapUI?: boolean
    protocol: string
    amapuiInited: boolean

    getScriptSrc(cfg: mapConfig) {
        if (this.type === 'baidu') {
            return `${this.protocol}//${cfg.path}?v=${cfg.v}&ak=${cfg.key}&callback=${cfg.callback}`
        }
        return `${this.protocol}//${cfg.path}?v=${cfg.v}&key=${cfg.key}&callback=${cfg.callback}&plugin=${AMapTool.toString()}`
    }

    buildScriptTag(src: string): HTMLScriptElement {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.defer = true
        script.src = src
        script.id = this.type + '_API_SCRIPT'
        return script
    }

    getAmapuiPromise() {
        if ((window as any).AMapUI) {
            return Promise.resolve()
        }
        const script = this.buildScriptTag(`${this.protocol}//webapi.amap.com/ui/1.0/main-async.js`)
        const p = new Promise(resolve => {
            script.onload = () => {
                resolve(true)
            }
        })
        document.body.appendChild(script)
        return p
    }

    getMainPromise(): Promise<any> {


        if ((this.type === 'amap' && (window as any).AMap) ||
            (this.type === 'baidu' && (window as any).BMap)) {

            return Promise.resolve()
        }

        const script = this.buildScriptTag(this.getScriptSrc(this.config))

        document.body.appendChild(script)

        return new Promise(resolve => {
            (window as any)[this.config.callback] = () => {
                resolve(true)
                delete (window as any)[this.config.callback]
            }
        })
    }

    load() {
        if (typeof window === 'undefined') {
            return null
        }

        let amapuiPromise: Promise<any> | null = null
        let mainPromise: Promise<any> = this.getMainPromise()

        if (this.amapUI && this.type === 'amap') {
            amapuiPromise = amapuiPromise || this.getAmapuiPromise()
        }

        return new Promise(resolve => {
            mainPromise.then(() => {
                if (!!amapuiPromise) {
                    amapuiPromise.then(() => {
                        if ((window as any).initAMapUI && !this.amapuiInited) {
                            (window as any).initAMapUI()
                            this.amapuiInited = true
                        }
                        resolve(true)
                    })
                } else {
                    resolve(true)
                }
            }).catch((err: any) => {
                console.log(err)
            })
        })
    }
}