/**
 * 车辆续时、续时币相关接口
 */

import axios from "../axios";
import api from "./index";
import {CompanyId, PageProps, TimeLimit} from './index.globals'
import {TableResponseType} from 'components/table/interface';

const renewCoinMODULE = '/api/companyAccount'
const renewNotificationMODULE = '/vehicleService'

export interface rechargeRequest extends CompanyId {
  /** 充值金额 */
  amount: number
}

interface getRenewalCoinLogRequest extends PageProps, TimeLimit, CompanyId {
  /** 操作人（用户id集合）*/
  userIds?: string[]
}

interface getRenewLogRequest extends TimeLimit, PageProps {
  /** 车牌号 */
  plateNos?: string
  /** 操作人（用户id集合）*/
  userIds?: string[]
  /** 续时方式 */
  changeType?: string
}

export interface addOrReduceServiceRequest extends CompanyId {
  /** 调整方式,0:系统调整 1：续时币支付 2：支付宝支付 3：微信支付 */
  changeType: number
  /** 调整方式为系统调整时自定义服务到期日 */
  endTime?: string
  /** 支付单号 */
  orderNo?: string
  /** 退款单号 */
  refundNo?: string
  /** 正数：增加服务天数/月数/年数；负数：减少服务天数/月数/年数 */
  updateNum: number
  /**  增加减少服务的时间单位。day:天;month:月;year：年   */
  updateUnit: string
  /** 车辆id */
  vehicleIds: string[]
}

interface getExpiredServicePageRequest extends PageProps {
  /** 过期时间 */
  expireDate: string
  /** 车辆id */
  vehicleIds: string[]
  /** 这里接口用错了。应该是单选用CompanyId，但是后台名字用了Ids，接收的却不是数组 */
  CompanyIds: string
}

export interface VehicleRenewRecord extends CompanyId {
  companyName: string
  depName: string
  endTime: string
  id: string
  installTime: string
  plateNo: string
  remainTime: number
  simNo: string
  startTime: string
  status: number
  statusDesc: string
  vehicleId: string
}

// 续时币管理
/** 续时币充值 */
export const doRecharge = (payload: rechargeRequest) => axios.post(`${api}${renewCoinMODULE}/recharge/company/account`, payload)

/** 查询企业剩余续时币 */
export const getRenewalCoin = (payload: CompanyId) => axios.post(`${api}${renewCoinMODULE}/query/current/renewalCoin`, payload)

/** 查询企业续时币记录 */
export const getRenewalCoinLog = (payload: getRenewalCoinLogRequest) => axios.post(`${api}${renewCoinMODULE}/query/company/account/record`, payload)

/** 续时币记录导出 */
export const exportRenewalCoinLog = (payload: getRenewalCoinLogRequest) => axios.post(`${api}${renewCoinMODULE}/export/company/account/record`, payload)
// 续时币管理end

// header车辆到期提醒、管理中心-车辆续时
/** 查询header上图标的角标 */
export const getExpiredServiceNum = () => axios.post(`${api}${renewNotificationMODULE}/getExpiredServiceNum`, {})

/** 分页查询车辆服务列表 */
export const getExpiredServicePage = (payload: getExpiredServicePageRequest) => axios.post<TableResponseType<VehicleRenewRecord>>(`${api}${renewNotificationMODULE}/page`, payload)

/** 弹窗中的续时操作 */
export const addOrReduceService = (payload: addOrReduceServiceRequest) => axios.post(`${api}${renewNotificationMODULE}/update`, payload)
// header车辆到期提醒、管理中心-车辆续时 end

// 管理中心-车辆续时记录
export const getRenewLog = (payload: getRenewLogRequest) => axios.post(`${api}${renewNotificationMODULE}/detail/page`, payload)
// 管理中心-车辆续时记录 end