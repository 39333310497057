/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-12-09 10:30:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-29 16:12:30
 */
import React from 'react'
import { Image, Typography } from 'antd'
import { InfoItems } from 'components/info'
import { useIntl } from 'react-intl'
import defaultFail from 'components/image/defaultFail'

const { Text } = Typography

const useDriverInfo = (success?: boolean) => {
  const { formatMessage: f } = useIntl()

  const defaultConfig: InfoItems[] = [
    {
      children: [
        {
          dataIndex: '_',
          row: 2,
          render: () => (
            <div style={{ textAlign: 'center', marginTop: 80 }}>
              <Image width={100} src={defaultFail} />
              <div style={{ marginTop: 8 }}>
                <Text>{f({ id: 'tx370005', description: '未识别当前驾驶员' })}</Text>
              </div>
            </div>
          ),
        },
      ],
    },
  ]

  const config: InfoItems[] = [
    {
      children: [
        {
          dataIndex: ['driverData', 'driverName'],
          intlTitle: 'tx110001',
          description: '司机姓名',
        },
        {
          dataIndex: ['driverData', 'companyName'],
          intlTitle: 'tx130001',
          description: '所属企业',
        },
        {
          dataIndex: ['driverData', 'identityCard'],
          intlTitle: 'tx110005',
          description: '身份证号',
        },
        {
          dataIndex: ['driverData', 'telephone'],
          intlTitle: 'tx050002',
          description: '联系电话',
        },
        {
          dataIndex: ['driverData', 'driverLicence'],
          intlTitle: 'tx110006',
          description: '从业资格证',
        },
        {
          dataIndex: ['driverData', 'icNumber'],
          intlTitle: 'tx110011',
          description: 'IC卡号',
        },
        {
          dataIndex: ['driverData', 'certificationDate'],
          intlTitle: 'tx110013',
          description: '发证日期',
        },
        {
          dataIndex: ['driverData', 'examineYear'],
          intlTitle: 'tx110012',
          description: '年审日期',
        },
        {
          dataIndex: ['driverData', 'invalidDate'],
          intlTitle: 'tx110014',
          description: '过期日期',
        },
        // {
        //   dataIndex: ['vehicleData', 'mainDriver'],
        //   intlTitle: 'tx110022',
        //   description: '主驾',
        //   render: value =>
        //     !isNil(value) && (
        //       <>{f({ id: value === 1 ? 'tx000185' : 'tx000186', description: '是/否' })}</>
        //     ),
        // },
      ],
    },
  ]

  return success ? config : defaultConfig
}

export default useDriverInfo
