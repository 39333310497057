import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './index.less';

import App from './App'
import Login from 'view/login'
import NotFound from 'view/404'
import TxLargeScreen from 'view/largeScreen/tx'
import ConcreteLargeScreen from 'view/largeScreen/concrete'

// 对外第三方页面
import Broadcast from 'view/foreignPage/broadcast'
import Playback from 'view/foreignPage/playback'
import Trajectory from 'view/foreignPage/trajectory'
import Position from 'view/foreignPage/position'
import DevOlMap from 'view/devOlMap'

//对外第三方页面==新 使用webRTC播放
import RTCBroadcast from 'view/foreignPage/broadcastRTC'
import RTCPlayback from "view/foreignPage/playbackRTC"

function Page() {
  return (
    // <Suspense fallback={ <Spin className="spin-loading-class" ></Spin> }>
    <Router>
      <Switch>
        <Route exact path="/" render={ () => <Redirect to="/login" push /> } />
        <Route path="/login" component={ Login } />
        <Route path="/index" component={ App } />
        <Route path="/404" component={ NotFound } />
        <Route path="/largeScreen" component={ TxLargeScreen } />
        <Route path="/concreteLargeScreen" component={ ConcreteLargeScreen } />
        <Route path="/foreignPage/broadcast" component={ Broadcast } />
        <Route path="/foreignPage/rtcbroadcast" component={ RTCBroadcast } />
        <Route path="/foreignPage/playback" component={ Playback } />
        <Route path="/foreignPage/rtcplayback" component={ RTCPlayback } />
        <Route path="/foreignPage/trajectory" component={ Trajectory } />
        <Route path="/foreignPage/position" component={ Position } />
        <Route path="/devMap" component={ DevOlMap } />
        <Route component={ NotFound } />
      </Switch>
    </Router>
    // </Suspense>
  )
}

export default Page
