/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-06 14:19:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 16:25:42
 */
import React, { useEffect, useMemo } from 'react'
import { Props, State, Tools } from './interface'
import { isUndefined } from 'lodash-es'

/**
 * 处理高亮搜索功能
 * @returns {string} 处理后的treeNodeFilterProp值
 * @description 开启高亮搜索后，原先的title会从string转换为Dom元素，Dom无法像string一样做搜索处理，所以新增一个辅助字段专门做搜索，需要将返回值传进TreeSelect组件的treeNodeFilterProp属性里
 */
const useHandleHighLightSearch = ({
  props: { treeNodeFilterProp, showSearch },
  state: { treeData, searchValue, searchTreeData },
  setState,
}: Tools) => {
  /** 是否开启高亮搜索功能功能 */
  const isLightAble = !!(
    showSearch &&
    searchValue &&
    treeData &&
    treeData.length > 0 &&
    (treeNodeFilterProp === 'title' || treeNodeFilterProp === 'label')
  )

  const _treeNodeFilterProp = useMemo(
    () =>
      isLightAble && !isUndefined(searchTreeData)
        ? `__${treeNodeFilterProp}__`
        : treeNodeFilterProp,
    [isLightAble, searchTreeData, treeNodeFilterProp],
  )

  useEffect(() => {
    const loop = (treeData: Props['treeData'], searchValue: string): State['searchTreeData'] =>
      treeData?.map(item => {
        const { children, ...extra } = item
        const index = String(item[treeNodeFilterProp]).indexOf(searchValue)
        const beforeStr = String(item[treeNodeFilterProp]).substr(0, index)
        const afterStr = String(item[treeNodeFilterProp]).substr(index + searchValue.length)
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="tx-tree-select-search-value-site">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item[treeNodeFilterProp]}</span>
          )
        return {
          ...extra,
          [treeNodeFilterProp]: title,
          [_treeNodeFilterProp]: item[treeNodeFilterProp],
          children: loop(children, searchValue),
        }
      })
    setState({ searchTreeData: isLightAble ? loop(treeData, searchValue!) : undefined })
  }, [_treeNodeFilterProp, isLightAble, searchValue, setState, treeData, treeNodeFilterProp])

  return _treeNodeFilterProp
}

export default useHandleHighLightSearch
