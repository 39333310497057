import { PureComponent } from 'react'
import { MarkerProps, pointType } from './interface'
import { toIcon, pointConvert, toLabel, toPixel } from './util/comm'

class Marker extends PureComponent<MarkerProps> {
    constructor(props: MarkerProps) {
        super(props)
        if (typeof window !== 'undefined') {
            if (props.__map__) {
                this.map = props.__map__
                setTimeout(() => {
                    this.createMarker(props)
                }, 15)
            }
        }
    }

    map: any
    marker: any
    type: string

    componentWillUnmount() {
        this.removeMarker()
    }

    componentWillReceiveProps(nextProps: MarkerProps) {

        if (this.map) {
            this.refreshMarkerLayout(nextProps)
        }

        this.updateAngle(nextProps)
        this.move(nextProps.position)

    }

    async createMarker(props: MarkerProps) {

        const config: any = {}
        // 处理不同地图，不同icon对象
        if (props.icon) {
            config.icon = toIcon(props.icon, props.type)
        }
        if (props.offset) {
            let _offset = props.offset
            if (props.type === 'baidu') {
                _offset = [_offset[0] + 11, _offset[1] + 18]
            }
            config.offset = toPixel(_offset, props.type)
        }
        if (props.angle) {
            config.autoRotation = true
            config.rotation = props.angle
        }
        if (props.zIndex) {
            config.zIndex = props.zIndex
        }

        const point = await pointConvert([props.position], props.type)
        if (props.type === 'baidu') {
            this.marker = new (window as any).BMap.Marker(point[0], config)
            this.map.addOverlay(this.marker)

        } else if (props.type === 'amap') {
            config.position = point[0]
            this.marker = new (window as any).AMap.Marker(config)
            this.map.add(this.marker)
        }


        this.setLabel(props)

        this.updateAngle(props)

        if (props.onClick) {
            if (props.type === 'amap') {
                this.marker.on('click', () => {
                    console.log('marker click')
                    props.onClick && props.onClick(point[0])
                })
            } else if (props.type === 'baidu') {
                this.marker.addEventListener('click', () => {
                    props.onClick && props.onClick(point[0])
                })
            }
        }

        if (props.isView) {
            if (props.type === 'amap') {
                this.map.setFitView([this.marker])
            }
        }
    }

    setLabel(props: MarkerProps) {
        const { type, label } = props
        if (label && this.marker) {
            this.marker.setLabel(toLabel(label, type))
        }

    }

    setIcon(props: MarkerProps) {
        const { type, icon } = props
        if (icon && this.marker) {
            this.marker.setIcon(toIcon(icon, type))
        }
    }

    async move(position: pointType) {
        if (this.marker && this.marker.setPosition) {
            if (this.props.type === 'amap' && position[0] && position[1]) {
                const point = await pointConvert([position], this.props.type)
                if (this.marker) {
                    this.marker.setPosition(point[0])
                }
            } else if (this.props.type === 'baidu' && position[0] && position[1]) {
                const point = await pointConvert([position], this.props.type)
                if (this.marker) {
                    this.marker.setPosition(point[0])
                }

            }
        }
    }

    updateAngle(props: MarkerProps) {
        if (this.marker && props.angle) {
            if (props.type === 'amap') {
                this.marker.setAngle(props.angle)
            }
            if (props.type === 'baidu') {
                this.marker.setRotation(props.angle)
            }
        }
    }

    refreshMarkerLayout(props: MarkerProps) {
        this.setLabel(props)
        this.setIcon(props)
    }

    removeMarker() {
        if (this.marker) {
            if (this.props.type === 'baidu') {
                this.map.removeOverlay(this.marker)
            } else if (this.props.type === 'amap') {
                this.map.remove(this.marker)
            }
            this.marker = null
        }
    }

    render() {
        return null
    }
}

export default Marker