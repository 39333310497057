import React, { FC, useState, useEffect } from 'react'
import { Modal, Form, SelectCode } from 'components'
import { Button, Form as AntdForm, Space } from 'antd'
import { ModalProps } from '../interface'
import { useIntl } from 'react-intl'
import { sendLocationTracking, LocationTrackingType } from 'server/web/sendCommand'
import { useAxios, useBasicCodes, usePollingCommandStatus } from 'util/hook'
import { MyFormItemProps } from 'components/form'
import { handleLayout, pleaseSelect as s } from 'util/comm'

const AudioMonitoring: FC<ModalProps> = props => {
  const { ...extra } = props
  const { formatMessage: f } = useIntl()
  const [form] = AntdForm.useForm()
  const [{ data: basicCodes }] = useBasicCodes(['VideoChannel'])

  const config: MyFormItemProps[] = [
    {
      name: 'channel',
      intlLable: 'tx070005',
      description: '通道',
      rules: [{ required: true }],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.VideoChannel}
          placeholder={s({ id: 'tx070005' })}
        />
      ),
    },
    {
      label: ' ',
      dom: (
        <Space>
          <Button size="small" type="primary">
            {f({ id: 'tx370003', description: '开始监听' })}
          </Button>
          <Button size="small">{f({ id: 'tx370004', description: '停止监听' })}</Button>
        </Space>
      ),
    },
  ]

  return (
    <Modal
      {...extra}
      title={f({ id: 'tx000015', description: '音频监听' })}
      width={600}
      // beforeConfirm={<span>{statusName}</span>}
      okText={f({ id: 'tx000012', description: '发送' })}
      // confirmLoading={sendLoading}
      // onOk={handleOk}
      confirmVisible={false}
    >
      <Form items={config} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} />
    </Modal>
  )
}

export default AudioMonitoring
