/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-17 11:09:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 14:38:10
 */
type ProxyType = '/api' | '/test'

/** 测试环境 */
let proxy: ProxyType = '/test'


if (process.env.NODE_ENV !== 'development') {
  proxy = '/api'
}

let BASEURL = ''

// 2021-11-25 注掉，不需要前端来定义baseurl了
// if (proxy === '/api') {
//   BASEURL = process.env.REACT_APP_BASEURL!
// }

export { BASEURL }
export default proxy
