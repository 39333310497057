import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/es/select'

interface MySelectProps<T> extends SelectProps<T> {
  data: itemProps[]
  /** 默认id */
  idKey?: string
  /** 默认title字段 */
  titleKey?: string
}

interface itemProps {
  /** 是否禁用 */
  disabled?: boolean
  /** title */
  title: string
  /** value */
  value: string | number
  render?: (value: any) => JSX.Element | string | number
  className?: string
}

function Index<T extends SelectValue = SelectValue>(props: MySelectProps<T>) {
  const { data, ...extra } = props
  const [options, setOptions] = useState<JSX.Element[]>([])

  useEffect(() => {
    setOptions(
      data.map((option: itemProps) => (
        <Select.Option key={option.value} {...option}>
          {option.title}
        </Select.Option>
      )),
    )
  }, [data])

  return <Select {...extra}>{options}</Select>
}

export default Index
