import React, { FC, useMemo, useCallback } from 'react'
import { Cascader, Spin } from 'antd'
import { CascaderProps } from './interface'
import { queryAreaTree } from 'server/web/basicCode'
import { arrayToTree } from 'util/comm'
import { useAxios } from 'util/hook'

/** 详情见antd的Cascader组件 */
const AreaCascader: FC<Partial<CascaderProps>> = props => {
  const { showSearch, options, loading, autoRequest = true, ...extra } = props
  const [{ data: areaTree, loading: arealoading }] = useAxios({
    axios: queryAreaTree,
    handleResult: value => arrayToTree(value, 'id', 'parent'),
    defaultStart: autoRequest,
  })

  const fieldNames = useMemo(() => ({ label: 'name', value: 'code' }), [])

  const dropdownRender = useCallback<NonNullable<CascaderProps['displayRender']>>(
    menus => <Spin spinning={ loading || arealoading || false }>{ menus }</Spin>,
    [arealoading, loading],
  )
  console.log(extra);
  return (
    <Cascader
      { ...extra }
      showSearch={ showSearch || true }
      options={ options || areaTree }
      fieldNames={ (options || areaTree) && fieldNames }
      dropdownRender={ dropdownRender }
    />
  )
}

export default AreaCascader
