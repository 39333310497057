/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-10-14 11:31:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-22 19:21:38
 */


/** 指令需要轮询的状态 */
const pendingList = ['New', 'Processing', 'Received']

/** 指令停止轮询的状态 */
const stopList = ['Failed', 'Offline', 'Success', 'Invalid', 'NotSupport', 'Uploaded', 'TimeoutFailed']

/**
 * 指令下发，是否继续轮询
 * @param {string} status 
 */
const isOrderAgain = (status: string): boolean => {
  if (!(pendingList.includes(status) || stopList.includes(status))) {
    throw new Error('该指令前端未记录')
  }
  return !stopList.some(item => item === status)
}



export default isOrderAgain