/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-11-13 15:18:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-14 11:19:20
 */
import { useEffect, useCallback } from 'react';
import { getBasicCodes, BasicCodesType } from 'server/web/basicCode'
import { Callback, UseSpecificAxios } from './interface';
import useAxios from './useAxios';
import useSetState from 'util/useSetState';
import { webAPIRequest } from 'server/web/index.globals'
import { Obj } from 'util/interface';

type Payload = webAPIRequest.BasicCodes['types']
type Result = BasicCodesType

const useBasicCodes: UseSpecificAxios<Payload, Result> = (payload, callback) => {
  const [config, _setConfig] = useSetState({ payload, callback })
  const [state, setState] = useAxios({
    axios: getBasicCodes,
    payload: { types: payload },
    onSuccess: callback,
    defaultStart: false
  })

  const setConfig = useCallback((payload?: Payload, callback?: Callback<Result>) => {
    const obj: Obj = {}
    if (payload) obj.payload = payload
    if (callback) obj.callback = callback
    _setConfig(obj)
  }, [_setConfig])

  useEffect(() => {
    const { payload, callback } = config
    setState({ payload: { types: payload }, onSuccess: callback })
  }, [config, setState])

  return [state, setConfig]
}

export default useBasicCodes;
