import React, { FC, useState, useEffect } from 'react'
import { Checkbox, Form as AntdForm, Input, Space, message } from 'antd'
import { Modal, Form } from 'components'
import { MyFormItemProps } from 'components/form'
import { Button } from 'antd'
import { ModalProps } from '../interface'
import { useIntl } from 'react-intl'
import { addIssueContent } from 'server/web/dispatch'
import { sendText } from 'server/web/sendCommand'
import { useAxios, useBasicCodes, usePollingCommandStatus } from 'util/hook'
import { webAPIResponse } from 'server/web/index.globals'
import { basicCodesToOption, pleaseSelect as s, pleaseEnter as e } from 'util/comm'
import CommonTextModal from './commonTextModal'

const { Group } = Checkbox
const { TextArea } = Input
type IssueContent = webAPIResponse.IssueContent

const Dispatch: FC<ModalProps> = props => {
  const { record, ...extra } = props
  const { formatMessage: f } = useIntl()
  const [form] = AntdForm.useForm()
  const [{ data: basicCodes }] = useBasicCodes(['Description'])
  const [statusName, setStatusName] = useState('')
  const [visible, setVisible] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)

  const [, querySendText] = useAxios({
    axios: sendText,
    defaultStart: false,
  })

  const [{ data: commandStatus }, pollingCommandStatus] = usePollingCommandStatus()

  useEffect(() => {
    setStatusName(commandStatus?.statusName || '')
  }, [commandStatus])

  const [{ loading: addLoading }, addContent] = useAxios({
    axios: addIssueContent,
    defaultStart: false,
  })

  const handleOk = async () => {
    const formData = await form.validateFields()
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    setSendLoading(true)
    try {
      const commandId = await querySendText({
        payload: {
          ...formData,
          vehicleId: record?.vehicleId,
        },
      })
      await pollingCommandStatus(commandId)
    } finally {
      setSendLoading(false)
    }
  }

  const handleCommonTextModalCancel = () => setVisible(false)

  const handleCommonTextModalOk = (record?: IssueContent) => {
    record && form.setFieldsValue({ textContent: record.issueContent })
    handleCommonTextModalCancel()
  }

  const handleAddContent = async () => {
    const { textContent } = await form.validateFields(['textContent'])
    await addContent({ payload: { textContent } })
    message.success(f({ id: 'tx000024', description: '操作成功' }))
  }

  const config: MyFormItemProps[] = [
    {
      name: 'displayOptions',
      intlLable: 'tx1900001',
      description: '文本信息',
      dom: <Group options={basicCodes?.Description?.map(basicCodesToOption)} />,
      rules: [
        {
          required: true,
          message: s({ id: 'tx1900001' }),
        },
      ],
    },
    {
      name: 'textContent',
      intlLable: 'tx020016',
      description: '下发内容',
      dom: <TextArea autoSize={{ minRows: 6 }} />,
      wrapperCol: {
        span: 16,
      },
      rules: [
        {
          required: true,
          message: e({ id: 'tx020016' }),
        },
      ],
    },
    {
      label: ' ',
      dom: (
        <Space>
          <Button size="small" type="primary" onClick={() => setVisible(true)}>
            {f({ id: 'tx020018', description: '常用文本' })}
          </Button>
          <Button size="small" type="primary" ghost loading={addLoading} onClick={handleAddContent}>
            {f({ id: 'tx020019', description: '加为常用' })}
          </Button>
          <CommonTextModal
            visible={visible}
            onOk={handleCommonTextModalOk}
            onCancel={handleCommonTextModalCancel}
          />
        </Space>
      ),
    },
  ]

  return (
    <Modal
      {...extra}
      title={f({ id: 'tx000017', description: '调度下发' })}
      width={750}
      beforeConfirm={<span>{statusName}</span>}
      okText={f({ id: 'tx000012', description: '发送' })}
      confirmLoading={sendLoading}
      onOk={handleOk}
    >
      <Form items={config} form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} />
    </Modal>
  )
}

export default Dispatch
