import React, { memo } from 'react'
import { Popconfirm } from 'antd'
import { isEqual } from 'lodash-es'
import { PopconfirmProps } from 'antd/lib/popconfirm'
import classnames from 'classnames'
import './index.less'

const TX_Popconfirm = (props: PopconfirmProps) => (
  <Popconfirm {...props} overlayClassName={classnames('tx-popconfirm', props.overlayClassName)} />
)

export default memo(TX_Popconfirm, isEqual)
