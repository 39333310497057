/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2021-01-11 09:57:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:00:18
 */
import React from 'react'
import BaseMap from './index';
import { MapProps } from './interface'
import { positionStory } from 'store'
import { observer } from 'mobx-react'

function Map(props: MapProps) {
  const { center, zoom, type, ...extra } = props
  return (<BaseMap
    center={ center ? center : positionStory.mapAllCenter }
    zoom={ zoom ? zoom : positionStory.mapAllZoom }
    type={ type ? type : positionStory.mapAllType }
    { ...extra }
  />)
}

export default observer(Map)
