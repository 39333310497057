/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-03 17:34:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 11:45:14
 */
import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse, Id } from './index.globals';

const MODULE = '/dispatch'

/** 添加常用文本 */
export const addIssueContent = (payload: webAPIRequest.AddIssueContent) => axios.post<boolean>(`${api}${MODULE}/message/addIssueContent`, payload)

/** 查询常用文本 */
export const getIssueContent = () => axios.post<webAPIResponse.IssueContent[]>(`${api}${MODULE}/message/getIssueContent`)

/** 删除常用文本 */
export const delIssueContent = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/message/delIssueContent`, payload)
