/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-11 17:22:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 16:05:16
 */
import { useEffect } from 'react';
import { Tools } from './interface';

/** 更新所有props的传值 */
const useUpdateProps = ({ props: { rowSelectedValue }, setState }: Tools) => {
  useEffect(() => setState({ selectedRow: rowSelectedValue }), [setState, rowSelectedValue])
}

export default useUpdateProps