/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-08 11:34:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-30 16:18:48
 */
import { observable, action } from 'mobx';
import { isFunction } from 'lodash-es'
import { FormInstance } from 'antd/lib/form';
import Polling from 'view/managementCenter/rule/instructions/Polling';
import { Obj } from 'util/interface';


export interface DataSourceOption {
  [key: string]: any[]
}

class Instructions {
  @observable selectNode: { key: string, title: string } | null = null
  /** 命令ID */
  @observable iKey: string = ''
  // @observable dataSource: any[] = []
  @observable dataSourceOption: DataSourceOption = {}
  /** form对象 */
  @observable formRef?: FormInstance = undefined
  /** 更新单个指令设置方法 */
  @observable ajax: Function | undefined = undefined
  /** 更新单个指令获取方法 */
  @observable getAjax: Function | undefined = undefined
  /** 单个指令获取方法的参数 */
  @observable getAjaxPayload: Obj | undefined = undefined
  /** 表单处理参数方法 */
  @observable handleValues: Function | undefined = undefined
  @observable loading: boolean = false
  /** 轮询对象数组 */
  @observable pollingList: Polling[] = []
  /** 全选状态 */
  @observable allChecked: boolean = false
  /** 指令id和selectNode与获取/设置类型的映射关系 */
  commandIdsMapping: Obj<string> = {}

  /** 更新选择指令 */
  @action
  updateSelect = (key: string, title: string) => {
    this.selectNode = {
      key,
      title
    }
  }

  /** 更新下发成功后返回指令 */
  @action
  updateKey = (key: string) => {
    this.iKey = key
  }

  /** 获取Form ref */
  @action
  updateFormRef = (ref: any) => {
    // console.log(ref)
    this.formRef = ref
  }

  /** 获取表单值 */
  @action
  getFormData = async () => {
    if (this.formRef) {
      const formData = await this.formRef.validateFields()
      let datas: any = formData
      if (this.handleValues && isFunction(this.handleValues)) {
        datas = this.handleValues(formData)
      }
      return datas
    }
  }

  @action
  updateLoading = (bool: boolean) => {
    this.loading = bool
  }

  @action
  updateDataSource = (data: any[] | ((props: any[]) => any), key?: string) => {
    if (key) {
      let dataSource: any = undefined
      if (typeof data === 'function') {
        dataSource = data(this.dataSourceOption[key])
      } else {
        dataSource = data
      }
      // this.dataSourceOption[key] = dataSource
      this.dataSourceOption = {
        ...this.dataSourceOption,
        [key]: dataSource
      }
    }
  }

  @action
  updatePollingList = (list: Polling[]) => {
    this.pollingList = list
  }

  /** 更新单个指令设置方法 */
  @action
  updateAjax = (fn: Function) => {
    this.ajax = fn
  }
  /** 更新单个指令获取方法 */
  @action
  updateGetAjax = (fn?: Function) => {
    this.getAjax = fn
  }
  @action
  updateGetAjaxPayload = (obj?: Obj) => {
    this.getAjaxPayload = obj
  }
  /** 更新单个指令，表单处理参数方法 */
  @action
  updateFormHandleValues = (fn?: Function) => {
    // console.log(fn)
    this.handleValues = fn
  }

  /** 设置表单值 */
  @action
  setFormData(data: Object) {
    if (this.formRef) {
      this.formRef.setFieldsValue(data)
    }
  }

  /** 更新全选状态 */
  updateAllChecked(checked: boolean) {
    this.allChecked = checked
  }

  handleCommandIdsMapping = (ids: string[], key: string) => {
    for (const value of ids) {
      this.commandIdsMapping[value] = key
    }
  }

}


const instructionsStore: Instructions = new Instructions()

export { Instructions }
export default instructionsStore