import React from 'react'
import BaseMap from './index';
import { MapProps } from './interface'
import { positionStory } from 'store'
import { observer } from 'mobx-react'

function Map(props: MapProps) {
  const { center, zoom, type, ...extra } = props

  return (<BaseMap
    center={ center ? center : {
      lng: positionStory.mapAllCenter![0],
      lat: positionStory.mapAllCenter![1],
    }
    }
    zoom={ zoom ? zoom : positionStory.mapAllZoom }
    type={ type ? type : positionStory.mapAllType }
    { ...extra }
  />
  )
}

export default observer(Map)
