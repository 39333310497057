import { cloneDeep } from 'lodash-es';
import { PureComponent } from 'react'
import { RectangleProps } from './interface';
import { pointConvert, handleOptions, toBounds, getRectangle, setView } from './util/comm'

const defaultOptions: Array<string> = [
  'bounds',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'fillColor',
  'fillOpacity',
  'strokeStyle',
  'extData',
  'zIndex'
]

export default class Rectangle extends PureComponent<RectangleProps> {

  map: any
  rectangle: any

  constructor(props: RectangleProps) {
    super(props)
    this.map = props.__map__
    this.createRectangle()
  }

  componentWillReceiveProps(_nextProps: RectangleProps) {

  }

  componentWillUnmount() {
    this.remove()
  }

  async createRectangle() {
    if (this.props.bounds) {
      const options = handleOptions(this.props, defaultOptions)
      // 批量转换
      const bounds = await pointConvert(cloneDeep(this.props.bounds), this.props.type)
      options.path = options.bounds = toBounds(bounds, this.props.type)
      options.map = this.map
      this.rectangle = getRectangle(this.props.type, options)

      if (this.props.type === 'baidu') {
        this.map.addOverlay(this.rectangle)
      }

      if (this.props.isView) {
        setView(this.props.type, this.map, this.rectangle)
      }
    }
  }

  remove() {
    if (this.rectangle) {
      if (this.props.type === 'baidu') {
        this.map.removeOverlay(this.rectangle)
      } else if (this.props.type === 'amap') {
        this.map.remove(this.rectangle)
      }
      this.rectangle = null
    }
  }

  render() {
    return null
  }
}
