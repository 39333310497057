/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-30 15:31:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 11:27:47
 */
import { getLangMessage as g } from './comm'
import { Rule } from 'antd/lib/form'
import { isString, isUndefined } from 'lodash-es'
import moment from 'moment'

/** 电话号码的验证 */
export const PhoneVali: Rule = {
  validator: (_rule, value, callback) => {
    const r = /^1[3-9][0-9]{9}$/
    const r2 = /^(?:0[1-9][0-9]{1,2}-)?[2-8][0-9]{6,7}$/
    if (!value || r.test(value) || r2.test(value)) {
      callback()
    } else {
      callback(g({ id: 'tx1600004', description: '请输入正确的电话号码' }))
    }
  },
}

/** 终端电话号码的验证：至少6位数字，当为6位数字时，首位不为0 */
export const TerminalPhoneVali: Rule = {
  validator: (_rule, value, callback) => {
    const r1 = /^\d{7,20}$/
    const r2 = /^[1-9][0-9]{5}$/
    if (!value || r1.test(value) || r2.test(value)) {
      callback()
    } else {
      callback(g({ id: 'tx1600004', description: '请输入正确的电话号码' }))
    }
  },
}

/** 数字的验证 */
export const NumberVali: Rule = {
  validator: (_rule, value, callback) => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^(\-|\+)?\d+(\.\d+)?$/
    if (!value || reg.test(value)) {
      callback()
    } else {
      callback(g({ id: 'tx1600007', description: '请输入有效的数字' }))
    }
  },
}

/** 整数验证 */
export const IntegerVali: Rule = {
  validator: (_rule, value, callback) => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^\d+$/
    if (!value || reg.test(value)) {
      callback()
    } else {
      callback(g({ id: 'tx1600007', description: '请输入有效的数字' }))
    }
  },
}

/** 身份证号的验证 */
export const IdCardVali: Rule = {
  validator: (_rule, value, callback) => {
    const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
    if (!value || reg.test(value)) {
      callback()
    } else {
      callback(g({ id: 'tx1600006', description: '请输入正确的身份证号' }))
    }
  },
}

/**
 * 最大值的验证
 * @param length
 * @param type
 */
export const MaxLength = (length: number, type: 'string' | 'number' = 'string'): Rule => {
  let _id = type === 'string' ? 'tx000206' : 'tx000222'
  return {
    max: length,
    transform: type === 'string' ? String : Number,
    type: type,
    message: g({ id: _id }, { value: length }),
  }
}

/**
 * 最小值的验证
 * @param length
 * @param type
 */
export const MinLength = (length: number, type: 'string' | 'number' = 'string'): Rule => {
  let _id = type === 'string' ? 'tx000205' : 'tx000223'
  return { min: length, type: type, message: g({ id: _id }, { value: length }) }
}

/**
 * 经度验证
 * @param value
 */
export const isLon = (): Rule => {
  // eslint-disable-next-line no-useless-escape
  const regExpress = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
  return {
    pattern: regExpress,
    message:
      g({ id: 'tx000239' }, { value: 0, value1: 180 }) +
      ',' +
      g({ id: 'tx000240' }, { value: 0, value1: 6 }),
  }
}

/**
 * 维度校验
 * @param value
 */
export const isLat = (): Rule => {
  // eslint-disable-next-line no-useless-escape
  const regExpress = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
  return {
    pattern: regExpress,
    message:
      g({ id: 'tx000239' }, { value: 0, value1: 90 }) +
      ',' +
      g({ id: 'tx000240' }, { value: 0, value1: 6 }),
  }
}

/**
 * 在min - max 之间
 * @param min 默认0
 * @param max 默认10
 */
export const BetweenValue = (min: number = 0, max: number = 10): Rule => {
  return {
    validator: async (_rule: any, value: string | number, callback: any) => {
      try {
        const _value = isString(value) ? Number(value) : value
        if (_value >= min && _value <= max) {
          return Promise.resolve()
        }
        return Promise.reject(g({ id: 'tx000239' }, { value: min, value1: max }))
      } catch (error) {
        callback(error)
      }
    },
  }
}

/**
 * @param 表单中的字段，根据有没有','或者'，'来判断是单个还是多个
 * 查询单个时间范围限制为两个月，查询多个时间范围限制为七天
 */
export const FormFieldLimitByTimeInterval = (FormField: string): Rule => ({ getFieldsValue }) => ({
  validator: (_rule, value: string[], callback) => {
    const name = getFieldsValue([FormField])[FormField]
    const isMultiple = name?.includes(',') || name?.includes('，')
    const start = moment(value[0])
    const end = moment(value[1])
    const diff = end.diff(start, isMultiple ? 'days' : 'months')
    const isSafe = (isMultiple && diff < 7) || (!isMultiple && diff < 2)
    if (!isUndefined(name) && isSafe) {
      callback()
    } else {
      callback(
        g({
          id: 'tx1600052',
          description: '查询单个时间范围限制为两个月，查询多个时间范围限制为七天',
        }),
      )
    }
  },
})
