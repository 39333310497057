/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-01 11:38:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 16:03:45
 */
import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse } from './index.globals'
import { TableResponseType } from 'components/table/interface';

const MODULE = '/tracklist'

const TESTMODULE = '/api/testDriver'
export interface TrackListsDto {
  /** 开始时间 */
  begTime: string
  /** 结束时间 */
  endTime: string
  /** ID */
  id: string
  /** 里程 */
  mileage: string
  /** 时速 */
  speedPerHour: string
  /** 时长 */
  timeLength: string
}


export interface TestDrivePage {
    // 车牌号
    id: string;
    // 车牌号
    plateNo: string;
    // 车辆id
    vehicleId: number;
    // 企业id
    companyId: number;
    // 开始时间
    startTime: string;
    // 结束时间
    endTime: string;
    // 时长，单位秒
    duration: number;
    // 开始纬度
    startLat: number;
    // 开始经度
    startLng: number;
    // 结束纬度
    endLat: number;
    // 结束经度
    endLng: number;
    // 里程差
    mileageDiff: number;
    // 状态码表值
    status: number;
}

/** 轨迹回放-时间分段查询 */
export const getTrackSeg = (data: webAPIRequest.TrackSeg) => axios.post<webAPIResponse.TrackSeg>(`${api}${MODULE}/findTrackSegBy`, data)

/** 试驾过程-时间分段查询 */
export const getTestTrackSeg = (payload: any) => axios.post<webAPIResponse.TestDrivePage>(`${api}${TESTMODULE}/getProcessList`, payload)

/** 试驾过程-试驾状态 */
export const updateTestDriverStatus = (payload: any) => axios.post<any>(`${api}${TESTMODULE}/updateStatus`, payload)

/** 轨迹回放-时间分段导出 */
export const getTrackExport = (data: webAPIRequest.TrackSeg) => axios.post<any>(`${api}${MODULE}/export`, data)

/** 轨迹回放-位置数据-导出 */
export const exportTrackListLocaBy = (data: webAPIRequest.TrackSeg) => axios.post<any>(`${api}${MODULE}/exportTrackListLocaBy`, data)

/** 轨迹回放-位置数据查询 */
export const getTrackList = (data: webAPIRequest.TrackList) => axios.post<TableResponseType<webAPIResponse.TrackList>>(`${api}${MODULE}/findTrackListLocaBy`, data)

/** 轨迹回放-坐标点数据查询全部 无分页 */
export const getTrackLocationAll = (data: Omit<webAPIRequest.TrackList, 'size' | 'current'>) => axios.post<webAPIResponse.trackLocationAll[]>(`${api}${MODULE}/findTrackListLocaAllBy`, data)

/** 轨迹回放-实时速度查询 无分页 */
export const getTrackSpeedAll = (data: Omit<webAPIRequest.TrackList, 'size' | 'current'>) => axios.post<webAPIResponse.trackSpeed[]>(`${api}${MODULE}/findTrackListSpeedBy`, data)

/** 轨迹回放-app轨迹查询 无分页 */
export const findAllTrackList = (data: Omit<webAPIRequest.TrackList, 'size' | 'current'>) => axios.post<webAPIResponse.trackLocationAll[]>(`${api}${MODULE}/app/findAllTrackList`, data)
