/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-05 19:39:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-14 11:00:34
 */
import testDate1 from './testDate1';

const obj = testDate1[0]

const dataSource: any = []

for (let i = 0; i < 700; i++) {
  const newObj = {}
  for (let key in obj) {
    if ((obj as any)[key] === '0') {
      ; (newObj as any)[key] = i
    } else {
      ; (newObj as any)[key] = (obj as any)[key]
    }
  }
  dataSource.push(newObj)
}

export default dataSource