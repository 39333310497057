
import { PureComponent } from 'react'
import { MouseToolProps } from './interface';
import { handleOptions } from './util/comm'

const defaultOptions: Array<string> = [
  'cursor',
  'bounds',
  'strokeColor',
  'strokeOpacity',
  'strokeWeight',
  'fillColor',
  'fillOpacity',
  'strokeStyle',
  'extData',
]

export default class tool extends PureComponent<MouseToolProps> {
  map: any
  mouseTool: any
  /** 矩形放大缩小 */
  rectangleZoom: any
  /** 测距 */
  distanceTool: any
  /** 鼠标绘制工具 */
  drawingManager: any
  /** 覆盖物 */
  overlay: any

  constructor(props: MouseToolProps) {
    super(props)
    this.map = props.__map__

    this.rectangleZoom = null
    this.distanceTool = null
    this.drawingManager = null
    this.overlay = null
    this.createMuseTool()
  }

  componentWillReceiveProps(nextProps: MouseToolProps) {
    if (nextProps.tool !== this.props.tool) {
      if (nextProps.isChangeClean && nextProps.tool !== 99) {
        this.close()
        this.BMapClose()
      }
      this.changeTool(nextProps.tool)
    }
  }

  componentWillUnmount() {
    this.close()
    this.BMapClose()
  }

  createMuseTool() {
    if (this.props.type === 'amap') {
      this.mouseTool = new (window as any).AMap.MouseTool(this.map)
      this.changeTool(this.props.tool)

      this.mouseTool.on('draw', (target: any) => {
        this.overlay = target.obj
        if (this.props.onDraw) {
          this.props.onDraw(target.obj)
        }
        this.removeCrosshair()
      })
    }

    if (this.props.type === 'baidu') {
      this.rectangleZoom = new (window as any).BMapLib.RectangleZoom(this.map)
      this.distanceTool = new (window as any).BMapLib.DistanceTool(this.map)

      const options = handleOptions(this.props, defaultOptions)
      this.drawingManager = new (window as any).BMapLib.DrawingManager(this.map, {
        isOpen: false,
        ...options
      })
      this.onBMapDraw()
    }
  }

  onBMapDraw() {
    if (this.props.onDraw) {
      this.drawingManager.addEventListener('circlecomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.drawingManager.addEventListener('markercomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.drawingManager.addEventListener('overlaycomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.drawingManager.addEventListener('polygoncomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.drawingManager.addEventListener('polylinecomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.drawingManager.addEventListener('rectanglecomplete', (overlay: any) => {
        this.props.onDraw!(overlay);
      });
      this.distanceTool.addEventListener('drawend', (e: any) => {
        this.props.onDraw!(e);
      })
    }
  }

  changeTool(tool: number) {
    const options = handleOptions(this.props, defaultOptions)

    if (this.mouseTool && this.props.type === 'amap') {


      switch (tool) {
        case 1: // 点
          this.mouseTool.marker(options);
          break;
        case 2:// 线
          this.mouseTool.polyline(options);
          break;
        case 3:// 多边形
          this.mouseTool.polygon(options);
          break;
        case 4:// 矩形
          this.mouseTool.rectangle(options);
          break;
        case 5:// 圆
          this.mouseTool.circle(options);
          break;
        case 6:// 测距
          this.mouseTool.rule(options);
          break;
        case 7:// 面积量测
          this.mouseTool.measureArea(options);
          break;
        case 8:// 鼠标拉框放大
          this.mouseTool.rectZoomIn(options);
          break;
        case 9:// 鼠标拉框缩小
          this.mouseTool.rectZoomOut(options);
          break;
        case 99:// 关闭不清除
          this.closeNoClean();
          break;
        default:
          this.close()
          break;
      }
      if (tool < 10 && tool > 0) {
        this.addCrosshair()
      }
    }
    if (this.props.type === 'baidu') {
      if (this.rectangleZoom) {

        if (tool === 8 || tool === 9) {
          this.rectangleZoom.updateZoomType(tool === 8 ? 0 : 1)
          this.rectangleZoom.open()
        } else {
          this.rectangleZoom.close()
        }

        if (tool === 6) {
          this.distanceTool.open()
        } else {
          this.distanceTool.close()
        }

        if ([1, 2, 3, 4, 5].includes(tool)) {
          let DrawingType = 'marker'
          if (tool === 2) {
            DrawingType = 'polyline'
          } else if (tool === 3) {
            DrawingType = 'polygon'
          } else if (tool === 4) {
            DrawingType = 'rectangle'
          } else if (tool === 5) {
            DrawingType = 'circle'
          }
          this.drawingManager.setDrawingMode(DrawingType)
          this.drawingManager.open()
        } else {
          if (tool === 99) {
            this.drawingManager.close(false)
          } else {
            this.drawingManager.close(this.props.isClean || this.props.cleanOne ? true : false)
          }
        }

        if (tool === 0 || tool === 99) {
          if (this.props.onClose) {
            this.props.onClose()
          }
        }
      }
    }
  }

  BMapClose() {
    if (this.drawingManager) {
      console.log('BMapClose', this.props.isClean || this.props.cleanOne ? true : false);
      this.drawingManager.close(this.props.isClean || this.props.cleanOne ? true : false)
    }
    if (this.rectangleZoom) {
      this.rectangleZoom.close()
    }
    if (this.distanceTool) {
      this.distanceTool.close()
    }
  }

  cleanOverLay() {
    if (this.overlay && this.props.cleanOne) {
      this.map.remove(this.overlay)
    }
  }

  close() {
    this.removeCrosshair()
    this.cleanOverLay()
    if (this.mouseTool) {
      this.mouseTool.close(this.props.isClean ? true : false)
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
  }

  closeNoClean() {
    this.removeCrosshair()
    if (this.mouseTool) {
      this.mouseTool.close(false)
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
  }

  addCrosshair() {
    const canvasDom = document.querySelectorAll('.amap-layers canvas')
    if (canvasDom.length) {
      canvasDom.forEach(item => {
        item.classList.add('map-crosshair')
      });
    }
  }

  removeCrosshair() {
    const canvasDom = document.querySelectorAll('.amap-layers canvas')
    if (canvasDom.length) {
      canvasDom.forEach(item => {
        item.classList.remove('map-crosshair')
      });
    }
  }

  render() {
    return null
  }
}
