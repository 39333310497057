import React, { useState, useEffect } from 'react'
import { Modal, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { login } from 'server/buc/login'
import VerificationSlide from './verification'
import * as store from 'store'
import { getUserMenu } from 'server/buc/menu'
import { setLocal } from 'util/comm'

interface ModelProps {
  form: FormInstance
}

const VerificationModal = (props: ModelProps) => {
  const {
    menuStore,
    intlStore: { lang },
    userStore: { updateUserInfo },
    loginStore: { updateLoginStore, showVeri, loginLoading },
  } = store
  const { form } = props
  const history = useHistory()
  const [imgIndex, setImgIndex] = useState(0)
  const { formatMessage: f } = useIntl()
  const imgArr = [
    'images/login/slideImg1.jpg',
    'images/login/slideImg2.jpg',
    'images/login/slideImg3.jpg',
    'images/login/slideImg4.jpg',
    'images/login/slideImg5.jpg',
  ]

  /**
   * 取随机数
   */
  const getRandomNumber = () => {
    const random = Math.floor(Math.random() * 1000) % imgArr.length
    setImgIndex(random)
  }

  const handleLogin = async () => {
    handleCancel()
    const formData = await form.validateFields()
    updateLoginStore('loginLoading', true)
    message.loading(f({ id: 'tx090002', description: '正在登陆中...' }), 0)
    const data = await login({
      language: lang,
      platformId: '00-01-01-01-00',
      ...formData,
    })
    updateLoginStore('loginLoading', false)
    if (data && data.code === 0) {
      message.destroy()
      updateUserInfo(data.data)
      message.success(f({ id: 'tx090003', description: '登录成功' }))

      const result = await getUserMenu({})

      if (result && result.data.length) {
        setLocal('tx_menu', result.data)
        menuStore.updateMenu(result.data)
        history.push(menuStore.jumpFirstPage())
      } else {
        message.warning(f({ id: 'tx000225', description: '请联系管理员配置权限菜单' }))
      }
    } else {
      form.resetFields()
    }
  }

  const handleCancel = () => {
    updateLoginStore('showVeri', false)
    updateLoginStore('sliderValue', 0)
  }

  const onSuccess = () => setTimeout(handleLogin, 300)

  useEffect(getRandomNumber, [])

  return (
    <Modal
      closable={false}
      wrapClassName="login-modal"
      visible={showVeri}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={!loginLoading}
      width={440}
      bodyStyle={{
        padding: '0.2rem',
      }}
    >
      <VerificationSlide showTips={false} onSuccess={onSuccess} imageUrl={imgArr[imgIndex]} />
    </Modal>
  )
}

export default observer(VerificationModal)
