/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2021-01-13 18:03:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-13 18:39:59
 */
import { isUndefined } from 'lodash-es';
import { useCallback } from 'react';
import { Props, Tools } from './interface';

const useHandleOnRowEvents = ({ props: { onRow, rowSelectedKey, rowSelectedValue }, setState }: Tools) =>
  useCallback<NonNullable<Props['onRow']>>((data, index) => {
    const onRowEvents = onRow && onRow(data, index)
    return {
      ...onRowEvents,
      onClick: (e) => {
        onRowEvents && onRowEvents.onClick && onRowEvents.onClick(e)
        isUndefined(rowSelectedValue) && setState({ selectedRow: rowSelectedKey ? data[rowSelectedKey] : index })
      }
    }
  }, [onRow, rowSelectedKey, rowSelectedValue, setState])

export default useHandleOnRowEvents