import React, { useState, useEffect, memo, forwardRef, useRef, useCallback } from 'react'
import { Select } from 'antd'
import { SelectProps } from './interface'
import { isEqual, debounce } from 'lodash-es'

/**
 * 封装内置请求的Select
 * @param props
 * @param ref
 */
const SelectQuery = (props: SelectProps, ref: React.Ref<any>) => {
  const {
    query,
    queryTime = 'onSearch',
    searchKey,
    queryParam,
    handQueryData,
    queryLimit = 0,
    onSearch,
    onInputKeyDown,
    keepValue,
    rule,
    ...extra
  } = props

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(props.options)
  const [result, setResult] = useState<any[]>()
  const keyCode = useRef<string>()

  useEffect(() => {
    if (queryTime === 'useEffect') {
      const payload = typeof queryParam === 'function' ? queryParam() : queryParam
      handleQuery(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rule) {
      const { label = 'label', value = 'value' } = rule
      setOptions(
        result?.map(item => ({
          label: item[label],
          value: item[value],
          ...item,
        })),
      )
    }
  }, [result, rule])

  const handleQuery = useCallback(
    async (payload: any) => {
      if (query && queryTime !== false) {
        setLoading(true)
        const res = await query(payload)
        setLoading(false)
        if (res) {
          if (handQueryData) {
            const handleDate = handQueryData(res.data)
            setOptions(handleDate)
          } else {
            setResult(res.data)
          }
        }
      }
    },
    [handQueryData, query, queryTime],
  )

  const queryDate = useCallback(
    async (value?: string) => {
      if (query) {
        let payload = typeof queryParam === 'function' ? queryParam(value) : queryParam
        if (queryTime === 'onSearch') {
          if (value && value.length >= queryLimit) {
            const obj = searchKey ? { [searchKey]: value } : {}
            payload = { ...obj, ...payload }
            handleQuery(payload)
          }
        } else {
          handleQuery(payload)
        }
      }
    },
    [handleQuery, query, queryLimit, queryParam, queryTime, searchKey],
  )

  const debouncedQuery = useCallback(debounce(queryDate, 500), [queryDate])

  const handleKeepValue = (value: string) => {
    const onChange: Function | undefined = props.onChange
    if (value) {
      onChange && onChange(value)
    } else if (keyCode.current === 'Backspace') {
      onChange && onChange(undefined)
    }
    keyCode.current = undefined
  }

  return (
    <Select
      ref={ref}
      allowClear
      showSearch={queryTime === 'onSearch'}
      onFocus={queryTime === 'onFocus' ? () => queryDate() : undefined}
      onInputKeyDown={e => {
        onInputKeyDown && onInputKeyDown(e)
        keyCode.current = e.key
      }}
      onSearch={value => {
        value ? debouncedQuery(value) : debouncedQuery.flush()
        if (onSearch && typeof value === 'string') {
          onSearch(value)
        }
        if (keepValue && typeof value === 'string') {
          handleKeepValue(value)
        }
      }}
      loading={loading}
      options={options}
      {...extra}
    />
  )
}

export default memo(forwardRef(SelectQuery), isEqual)
