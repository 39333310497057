import React, { useState, FC, useEffect } from 'react'
import { Timeline } from 'antd'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { TrackListsDto, getTrackSpeedAll } from 'server/web/trackList'
import { webAPIRequest } from 'server/web/index.globals'
import { isEqual } from 'lodash-es'
import { tableStore, monitorStore, trajectoryStory } from 'store';

const areEqual = (prevProps: any, nextProps: any) => {
  return isEqual(prevProps, nextProps)
}

interface TimeLineProps {
  /** 点击事件 */
  onClick?: (value: string) => void
  /** timeLine 数据 */
  items: TrackListsDto[]
  /** 是否异常 */
  isAbnormal: number
  formData: webAPIRequest.TrackSeg | undefined
}

const TimeLine: FC<TimeLineProps> = React.memo((props: TimeLineProps) => {

  const [timeCode, setTimeCode] = useState<any>('')
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    if (!props.items.length) {
      setTimeCode('')
    }
  }, [props.items])

  const onClick = (item: TrackListsDto) => {
    setTimeCode(item.id)
    monitorStore.cleanRealSpeedData()
    // 实时数据
    getTrackSpeedAll({
      begTime: item.begTime,
      endTime: item.endTime,
      isAbnormal: props.isAbnormal,
      minSpeed: props.formData?.minSpeed ? props.formData.minSpeed : '0',
      plateNo: props.formData?.plateNo ? props.formData.plateNo : '0'
    }).then(res => {
      if (res) {
        monitorStore.updateRealSpeedData(res.data)
      }
    })

    trajectoryStory.initParam({
      begTime: item.begTime,
      endTime: item.endTime,
      isAbnormal: props.isAbnormal,
      minSpeed: props.formData?.minSpeed ? props.formData.minSpeed : '0',
      plateNo: props.formData?.plateNo  ? props.formData.plateNo : '0',
    })

    const policeParams = {
      begTime: item.begTime,
      endTime: item.endTime,
      plateNo: props.formData ? props.formData.plateNo : '0'
    }

    tableStore.queryTable('trajectotyPoliceDataTable', policeParams)

    trajectoryStory.updatePoliceParmas(policeParams)

    monitorStore.findTrajectPlayPositionData(item.id)
  }

  return (
    <div className='trajectory-time-line'>
      <Timeline>
        {
          props.items.length &&
          props.items.map((item: TrackListsDto, index: number) => {
            return <Timeline.Item key={ index }>
              <div
                className={ classnames('trajectory-time-line-item', { 'item-active': timeCode === item.id }) }
                onClick={ () => {
                  onClick(item)
                } } >
                <div className='time-line-time-startTime'>
                  { f({ id: 'tx000132', description: '开始' }) }: { item.begTime }
                </div>
                <div className='time-line-time-endTime'>
                  { f({ id: 'tx000133', description: '截止' }) }: { item.endTime }
                </div>
                <div className='time-line-time-time'>
                  { f({ id: 'tx000134', description: '时长' }) }: { item.timeLength }
                </div>
                <div className='time-line-time-speed'>
                  <span>{ item.mileage }</span>
                  <span>{ item.speedPerHour }</span>
                </div>
              </div>
            </Timeline.Item>

          })
        }
      </Timeline>
    </div>
  )
}, areEqual)

export default TimeLine
